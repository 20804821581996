import { IRequest } from './types';

export type IFetchRequests = ReturnType<typeof fetchRequests>;
export const fetchRequests = () => ({
  type: 'requests/fetch' as const,
  payload: {},
});

export type IForceFetchRequests = ReturnType<typeof fetchRequests>;
export const forceFetchRequests = () => ({
  type: 'requests/force-fetch' as const,
  payload: {},
});

export type ILoadRequests = ReturnType<typeof loadRequests>;
export const loadRequests = () => ({
  type: 'requests/load' as const,
  payload: {},
});

export type IRequestsLoaded = ReturnType<typeof requestsLoaded>;
export const requestsLoaded = (requests: IRequest[]) => ({
  type: 'requests/loaded' as const,
  payload: { requests },
});

export type ILoadRequestsFailed = ReturnType<typeof loadRequestsFailed>;
export const loadRequestsFailed = () => ({
  type: 'requests/load-failed' as const,
  payload: {},
});

export type IDeleteAllRequests = ReturnType<typeof deleteAllRequests>;
export const deleteAllRequests = () => ({
  type: 'requests/delete-all' as const,
  payload: {},
});

export type IDeleteAllRequestsSuccess = ReturnType<typeof deleteAllRequestsSuccess>;
export const deleteAllRequestsSuccess = () => ({
  type: 'requests/delete-all-success' as const,
  payload: {},
});

export type IDeleteAllRequestsFailed = ReturnType<typeof deleteAllRequestsFailed>;
export const deleteAllRequestsFailed = () => ({
  type: 'requests/delete-all-failed' as const,
  payload: {},
});

export type IDeleteRequest = ReturnType<typeof deleteRequest>;
export const deleteRequest = (pipAppUserId: string, created: string) => ({
  type: 'requests/delete' as const,
  payload: { pipAppUserId, created },
});

export type IDeleteRequestSuccess = ReturnType<typeof deleteRequestSuccess>;
export const deleteRequestSuccess = (pipAppUserId: string, created: string) => ({
  type: 'requests/delete-success' as const,
  payload: { pipAppUserId, created },
});

export type IDeleteRequestFailed = ReturnType<typeof deleteRequestFailed>;
export const deleteRequestFailed = () => ({
  type: 'requests/delete-failed' as const,
  payload: {},
});
