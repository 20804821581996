import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Empty, Form } from 'antd';
import moment from 'moment';
import {
  fetchAppUserSurveySubmissions,
  fetchSurveys,
  fetchVersionSurveyData,
} from '@surveys/redux/actions';
import {
  selectAppUserSubmissionImage,
  selectAppUserSurveySubmissions,
  selectVersionSurveyData,
} from '@surveys/redux/reducers';
import { getBaseObjectTypeForSurvey } from '@surveys/redux/utils';
// import TranslatedForm from '@components/TranslatedForm';
import DetailRow from '@components/DetailRow';
import { Card } from '@cards/Card';
import './style.less';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  layout: 'vertical',
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const SurveyPreviewCard = ({ appUserId, surveyId, ...props }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [dataVersion, setDataVersion] = useState();

  const [loadingFormData] = useSelector(selectVersionSurveyData(surveyId, dataVersion));

  const [submissionLoading, userSubmission] = useSelector(
    selectAppUserSurveySubmissions(appUserId, surveyId),
  );
  const imageUrl = useSelector(selectAppUserSubmissionImage(appUserId));

  const pipObjectType = getBaseObjectTypeForSurvey(surveyId);

  useEffect(() => {
    dispatch(fetchSurveys());
    dispatch(fetchAppUserSurveySubmissions(appUserId));
  }, [dispatch, appUserId, surveyId]);

  useEffect(() => {
    const dataKey = `${pipObjectType}-data`;

    if (userSubmission) {
      const schemaVersion = userSubmission.versions[pipObjectType];
      const dataVersion = userSubmission.versions[dataKey];

      dispatch(fetchVersionSurveyData(surveyId, dataVersion, schemaVersion, appUserId));
      setDataVersion(dataVersion);
    }
  }, [appUserId, dispatch, surveyId, pipObjectType, userSubmission]);

  const loading = loadingFormData || submissionLoading;

  return (
    <Card.Half
      className="form-preview-card"
      title={t('cards:FormPreview.title')}
      loading={loading}
      {...props}
    >
      {userSubmission === undefined ? (
        <Empty description="No Submission" image={Empty.PRESENTED_IMAGE_SIMPLE} />
      ) : (
        <>
          <div className="date-submitted">
            <Form {...formItemLayout}>
              <DetailRow
                label={t('cards:FormPreview.dateSubmitted')}
                value={userSubmission && moment(userSubmission.created).format('LLL')}
              />
            </Form>
          </div>
          {/* <TranslatedForm
            namespace="forms"
            schema={formVersionData.schema}
            uiSchema={readOnlyUISchema}
            formData={formVersionData?.surveyData}
          >
            <div></div>
          </TranslatedForm> */}
          <div className="user-submission-image-container">
            <img alt="Competition Submission" src={imageUrl} />
          </div>
        </>
      )}
    </Card.Half>
  );
};

export default SurveyPreviewCard;
