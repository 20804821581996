import MessagingClient from '@liquid-state/messaging-client';
import { messagingApiRoot } from 'settings';

async function createMessagingClient(tokens) {
  try {
    const token = await tokens.get('ubiquity');

    const client = await new MessagingClient({ jwt: token }, { baseUrl: messagingApiRoot });

    return client;
  } catch (err) {
    console.error(err);
  }
}

export default createMessagingClient;
