import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router';
import { Switch, Route } from 'react-router-dom';
import { selectFirstRadioStation } from '@redux/stations/reducers';
import Details from './Details';
import EditStationWizard from './Edit';
import { fetchStations } from '@redux/stations/actions';

function RadioRedirect({ match }) {
  const [, station] = useSelector(selectFirstRadioStation);

  if (station) {
    return <Redirect to={`${match.path}/${station.id}`} />;
  }
  return null;
}

function RadioStation({ match }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchStations());
  }, [dispatch]);

  return (
    <Switch>
      {/* <Route path={`${match.path}/new`} component={NewDashboardUser} /> */}
      <Route path={`${match.path}/edit/:id`} component={EditStationWizard} />
      <Route path={`${match.path}/:id`} component={Details} />
      <Route path={`${match.path}`} component={RadioRedirect} />
    </Switch>
  );
}

export default RadioStation;
