import { combineReducers } from 'redux';
import surveys from '@surveys/redux/reducers';
import rlpHospitals from '@rlpHospitals/redux/reducers';
import documents from './documents/reducers';
import forms from './forms/reducers';
import authorisation from '../authorisation/reducer';
import registration from './registration/reducer';
import login from './login/reducer';
import passwordRecovery from './passwordRecovery/reducer';
import onboarding from './onboarding/reducer';
import appUsers from './appUsers/reducers';
import messages from './messages/reducers';
import core from './core/reducers';
import myProfile from './myProfile/reducers';
import hospitals from './hospitals/reducers';
import termsOfUse from './termsOfUse/reducers';
import dashboardUsers from './dashboardUsers/reducers';
import requests from '@requests/redux/reducers';
import stations from './stations/reducers';

export type IState = ReturnType<typeof rootReducer>;

const rootReducer = combineReducers({
  authorisation,
  appUsers,
  core,
  dashboardUsers,
  documents,
  forms,
  hospitals,
  login,
  messages,
  myProfile,
  onboarding,
  passwordRecovery,
  registration,
  requests,
  surveys,
  termsOfUse,
  stations,
  rlpHospitals,
});

export default rootReducer;
