import { put, call, getContext, select, takeEvery } from 'redux-saga/effects';
import { message } from 'antd';
import { IIDMService, IDMUser, IDMService } from '@liquid-state/idm-client';
import { organisationUrl, idmApiRoot, domain } from 'settings';
import { mapUserTypeToRoleURI } from '@constants';
import takeFirst from '../takeFirst';
import i18n from '../../i18n';
import {
  dashboardUsersLoaded,
  editDashboardUserFailed,
  editDashboardUserSuccess,
  loadDashboardUsers,
  loadDashboardUsersFailed,
  toggleDashboardUserActivationFailed,
  toggleDashboardUserActivationSuccess,
  ToggleDashboardUserActivation,
  EditDashboardUser,
  CreateDashboardUser,
  createDashboardUserSuccess,
  createDashboardUserFailed,
  ResendDashboardUserInvite,
  resendDashboardUserInviteFailed,
  resendDashboardUserInviteSuccess,
} from './actions';
import { selectDashboardUserById } from './reducers';
import { requiresPermissions } from '@authorisation/sagas';
import { Permissions } from '@authorisation/constants';
import { DashboardUser, InviteDashboardUserData } from './types';
import doCreateIDMService from '../doCreateIDMService';
import { determineUserTypeFromRole } from './utils';

export default function* dashboardUsersRoot() {
  yield takeFirst('dashboardUsers/fetch', doFetchDashboardUsers);
  yield takeEvery('dashboardUsers/toggleActivation', doToggleDashboardUserActivation);
  yield takeEvery('dashboardUsers/edit', doEditDashboardUser);
  yield takeEvery('dashboardUsers/create', doCreateDashboardUser);
  yield takeEvery('dashboardUsers/resendInvite', doResendDashboardUserInvite);
}

const extractInviteId = (url: string): string => {
  const extractInvitationIdRegex = new RegExp(
    `https:\\/\\/idm.${domain}\\/api\\/v1\\/invitations\\/([0-9]+)\\/`,
    'gi',
  );
  const result = extractInvitationIdRegex.exec(url);
  if (!result) {
    throw new Error(`Invalid invitation id provided to extractInviteId: ${url}`);
  }
  return result[1];
};

function* doFetchDashboardUsers() {
  try {
    yield put(loadDashboardUsers());

    try {
      yield requiresPermissions(Permissions.ViewDashboardUsers);

      const service = yield doCreateIDMService();
      const users = yield call(service.getAllUsers);

      const extractIdRegex = /.*\/api\/v1\/users\/([0-9]+)/i;

      const dashboardUsers = users
        .map(
          (user: IDMUser): DashboardUser => {
            const extractedId = extractIdRegex.exec(user.url);
            const id = (extractedId && extractedId[1]) || user.username;

            return {
              activated: user.is_active,
              email: user.email,
              firstName: user.profile.firstName,
              hospitalId: user.profile.hospitalId,
              id,
              invitations: user.invitations.map(url => extractInviteId(url)),
              lastName: user.profile.lastName,
              name: `${user.profile.firstName} ${user.profile.lastName}`,
              userType: determineUserTypeFromRole(user.default_role_uri),
              language: user.profile.language,
              uuid: user.username,
            };
          },
        )
        .sort((a: DashboardUser, b: DashboardUser) => Number(b.id) - Number(a.id));

      yield put(dashboardUsersLoaded(dashboardUsers));
      return;
    } catch (err) {
      console.error(err);
    }

    yield put(dashboardUsersLoaded([]));
  } catch (err) {
    console.error(err);
    yield put(loadDashboardUsersFailed());
    yield call(message.error, i18n.t('dashboardUsers:Details.loadError'));
  }
}

function* doToggleDashboardUserActivation({
  payload: { id, currentActivatedStatus, name },
}: ToggleDashboardUserActivation) {
  try {
    const service: IIDMService = yield doCreateIDMService();

    yield call(service.updateUser, id, { is_active: !currentActivatedStatus });

    yield put(toggleDashboardUserActivationSuccess(id, !currentActivatedStatus));
    yield call(
      message.success,
      i18n.t(
        `dashboardUsers:Details.${
          currentActivatedStatus ? 'deactivationSuccess' : 'activationSuccess'
        }`,
        { name },
      ),
    );
  } catch (err) {
    console.error(err);
    yield put(toggleDashboardUserActivationFailed());
    yield call(
      message.error,
      i18n.t(
        `dashboardUsers:Details.${
          currentActivatedStatus ? 'deactivationFailed' : 'activationFailed'
        }`,
      ),
    );
  }
}

function* doEditDashboardUser({ payload: { id, editedUser } }: EditDashboardUser) {
  try {
    const history = yield getContext('history');
    const service: IIDMService = yield doCreateIDMService();

    const profile = yield select(state => state.dashboardUsers.byId[id]);

    yield call(service.updateUserProfile, id, {
      hospitalId: profile.hospitalId,
      firstName: editedUser.firstName,
      lastName: editedUser.lastName,
      language: editedUser.language,
    });

    yield put(editDashboardUserSuccess(id, editedUser));
    yield call(history.goBack);
    yield call(message.success, i18n.t('dashboardUsers:Wizard.editSuccess'));
  } catch (err) {
    console.error(err);
    yield put(editDashboardUserFailed());
    yield call(message.error, i18n.t('dashboardUsers:Wizard.editFailed'));
  }
}

export function* inviteDashboardUser(userDetails: InviteDashboardUserData) {
  const service: IIDMService = yield doCreateIDMService();
  const { email, userType: role, ...profile } = userDetails;
  const idmUser = yield call(service.createUser, {
    email,
    organisation: organisationUrl,
    profile,
    role: mapUserTypeToRoleURI[role],
  });

  const idmInvitation = yield call(service.createInvitation, {
    organisation: organisationUrl,
    userId: idmUser.url,
  });

  const extractUserId = new RegExp(`${idmApiRoot}api/v1/users/([0-9]+)/`);
  const userIdResult = extractUserId.exec(idmUser.url);

  const invitedUser = {
    ...userDetails,
    activated: true,
    inviteCode: '',
    language: userDetails.language || 'en',
    invitations: [extractInviteId(idmInvitation.url)],
    id: (userIdResult && userIdResult[1]) || idmUser.username,
  };

  return invitedUser;
}

function* doCreateDashboardUser({ payload: { dashboardUser } }: CreateDashboardUser) {
  try {
    const history = yield getContext('history');
    const invitedUser: DashboardUser = yield call(inviteDashboardUser, {
      ...dashboardUser,
      name: `${dashboardUser.firstName} ${dashboardUser.lastName}`,
    });

    yield put(createDashboardUserSuccess(invitedUser));
    yield call(history.goBack);
    yield call(message.success, i18n.t('dashboardUsers:Wizard.createSuccess'));
  } catch (err) {
    console.error(err);
    if (err?.message === 'IDM Error: Failed to create IDM user' && err?.response?.json) {
      const body = yield call(err.response.json.bind(err.response));

      if (body?.username && body.username[0] === 'A user with that username already exists.') {
        yield put(createDashboardUserFailed());
        yield call(message.error, i18n.t('dashboardUsers:Wizard.userAlreadyExists'));
        return;
      }
    }
    yield put(createDashboardUserFailed());
    yield call(message.error, i18n.t('dashboardUsers:Wizard.createFailed'));
  }
}

function* doResendDashboardUserInvite({ payload: { id } }: ResendDashboardUserInvite): any {
  try {
    const service: IDMService = yield doCreateIDMService();

    const [, dashboardUser] = yield select(selectDashboardUserById(id)) as any;

    if (dashboardUser.invitations.length) {
      const invitationId = dashboardUser.invitations[dashboardUser.invitations.length - 1];
      yield call(service.deleteInvitation, invitationId);
    }

    const idmInvitation = yield call(service.createInvitation, {
      organisation: organisationUrl,
      userId: `${idmApiRoot}api/v1/users/${dashboardUser.id}/`,
    });

    yield put(resendDashboardUserInviteSuccess(id, extractInviteId(idmInvitation.url)));
  } catch (err) {
    console.error(err);
    yield put(resendDashboardUserInviteFailed());
  }
}
