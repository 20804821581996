import { combineReducers } from 'redux';
import { MESSAGE, ALERT, REMINDER } from '@utils/contentTypes';
import {
  LOAD_CANNED_MESSAGES,
  LOAD_MESSAGES,
  CANNED_MESSAGES_LOADED,
  MESSAGES_LOADED,
  CREATE_CONTENT_MESSAGE,
  CREATE_CONTENT_MESSAGE_SUCCESS,
  CREATE_CONTENT_MESSAGE_FAILED,
  UPDATE_CONTENT_MESSAGE,
  UPDATE_CONTENT_MESSAGE_SUCCESS,
  UPDATE_CONTENT_MESSAGE_FAILED,
  DELETE_CONTENT_MESSAGES,
  DELETE_CONTENT_MESSAGES_SUCCESS,
  DELETE_CONTENT_MESSAGES_FAILED,
  DELETE_SCHEDULED_MESSAGES,
  DELETE_SCHEDULED_MESSAGES_SUCCESS,
  DELETE_SCHEDULED_MESSAGES_FAILED,
  LOAD_MESSAGES_FAILED,
  IContentMessage,
  IMessage,
  CREATE_SCHEDULED_MESSAGE,
  CREATE_SCHEDULED_MESSAGE_FAILED,
  CREATE_SCHEDULED_MESSAGE_SUCCESS,
} from './types';
import {
  ILoadCannedMessages,
  ICannedMessagesLoaded,
  ICreateContentMessage,
  ICreateContentMessageSuccess,
  ICreateContentMessageFailed,
  IUpdateContentMessage,
  IUpdateContentMessageSuccess,
  IUpdateContentMessageFailed,
  IDeleteContentMessages,
  IDeleteContentMessagesSuccess,
  IDeleteContentMessagesFailed,
  IMessagesLoaded,
  ILoadMessages,
  IDeleteScheduledMessages,
  IDeleteScheduledMessagesFailed,
  IDeleteScheduledMessagesSuccess,
  ILoadMessagesFailed,
  ICreateScheduledMessage,
  ICreateScheduledMessageFailed,
  ICreateScheduledMessageSuccess,
} from './actions';
import { selectDocument } from '../documents/reducers';
import { selectForm } from '../forms/reducers';
import { IState } from '../reducer';

const loading = (
  state = false,
  action:
    | ILoadCannedMessages
    | ICannedMessagesLoaded
    | IMessagesLoaded
    | ILoadMessages
    | IDeleteScheduledMessages
    | IDeleteScheduledMessagesFailed
    | IDeleteScheduledMessagesSuccess
    | ILoadMessagesFailed
    | ICreateScheduledMessage
    | ICreateScheduledMessageFailed
    | ICreateScheduledMessageSuccess,
): boolean => {
  switch (action.type) {
    case DELETE_SCHEDULED_MESSAGES:
    case LOAD_MESSAGES:
    case LOAD_CANNED_MESSAGES:
    case CREATE_SCHEDULED_MESSAGE:
      return true;
    case CANNED_MESSAGES_LOADED:
    case MESSAGES_LOADED:
    case DELETE_SCHEDULED_MESSAGES_FAILED:
    case DELETE_SCHEDULED_MESSAGES_SUCCESS:
    case LOAD_MESSAGES_FAILED:
    case CREATE_SCHEDULED_MESSAGE_FAILED:
    case CREATE_SCHEDULED_MESSAGE_SUCCESS:
      return false;
    default:
      return state;
  }
};

const creating = (
  state: boolean = false,
  action: ICreateContentMessage | ICreateContentMessageSuccess | ICreateContentMessageFailed,
): boolean => {
  switch (action.type) {
    case CREATE_CONTENT_MESSAGE:
      return true;
    case CREATE_CONTENT_MESSAGE_SUCCESS:
    case CREATE_CONTENT_MESSAGE_FAILED:
      return false;
    default:
      return state;
  }
};

const updating = (
  state: boolean = false,
  action: IUpdateContentMessage | IUpdateContentMessageSuccess | IUpdateContentMessageFailed,
): boolean => {
  switch (action.type) {
    case UPDATE_CONTENT_MESSAGE:
      return true;
    case UPDATE_CONTENT_MESSAGE_SUCCESS:
    case UPDATE_CONTENT_MESSAGE_FAILED:
      return false;
    default:
      return state;
  }
};

const deleting = (
  state: boolean = false,
  action: IDeleteContentMessages | IDeleteContentMessagesSuccess | IDeleteContentMessagesFailed,
): boolean => {
  switch (action.type) {
    case DELETE_CONTENT_MESSAGES:
      return true;
    case DELETE_CONTENT_MESSAGES_SUCCESS:
    case DELETE_CONTENT_MESSAGES_FAILED:
      return false;
    default:
      return state;
  }
};

function contentMessagesById(
  state: { [key: string]: IContentMessage } = {},
  action: ICannedMessagesLoaded | IDeleteContentMessagesSuccess,
) {
  switch (action.type) {
    case CANNED_MESSAGES_LOADED:
    case DELETE_CONTENT_MESSAGES_SUCCESS:
      return action.payload.messages.reduce(
        (messagesById, message) => ({ ...messagesById, [message.id]: message }),
        state,
      );
    default:
      return state;
  }
}

function contentMessagesList(
  state: string[] = [],
  action: ICannedMessagesLoaded | IDeleteContentMessagesSuccess,
) {
  switch (action.type) {
    case CANNED_MESSAGES_LOADED:
    case DELETE_CONTENT_MESSAGES_SUCCESS:
      return action.payload.messages.map(({ id }) => id);
    default:
      return state;
  }
}

const contentMessages = combineReducers({ byId: contentMessagesById, list: contentMessagesList });

function sent(state: IMessage[] = [], action: IMessagesLoaded): IMessage[] {
  switch (action.type) {
    case MESSAGES_LOADED:
      return action.payload.sent;
    default:
      return state;
  }
}

function scheduled(
  state: IMessage[] = [],
  action: IMessagesLoaded | IDeleteScheduledMessagesSuccess | ICreateScheduledMessageSuccess,
): IMessage[] {
  switch (action.type) {
    case CREATE_SCHEDULED_MESSAGE_SUCCESS:
      return [action.payload.newMessage, ...state];
    case MESSAGES_LOADED:
      return action.payload.scheduled;
    case DELETE_SCHEDULED_MESSAGES_SUCCESS:
      return state.filter(({ id }) => !action.payload.messageIds.includes(id));
    default:
      return state;
  }
}

export default combineReducers({
  contentMessages,
  creating,
  deleting,
  loading,
  scheduled,
  sent,
  updating,
});

export const selectCannedMessagesCreating = (state: IState) => state.messages.creating;
export const selectCannedMessagesUpdating = (state: IState) => state.messages.updating;
export const selectCannedMessageDeleting = (state: IState) => [state.messages.deleting];

export const selectCannedMessages = (state: IState) => [
  state.messages.loading,
  state.messages.contentMessages.list.map(id => state.messages.contentMessages.byId[id]),
];

export const selectCannedMessage = (messageId: string) => (
  state: IState,
): [boolean, IContentMessage | undefined] => [
  state.messages.loading,
  state.messages.contentMessages.byId[messageId],
];

// select only alerts
export const selectContentAlerts = (state: IState) => [
  state.messages.loading,
  state.messages.contentMessages.list
    .map(id => state.messages.contentMessages.byId[id])
    .filter(alert => alert.metadata.type === ALERT),
];

// select only messages
export const selectContentMessages = (state: IState) => {
  const messages = state.messages.contentMessages.list
    .map(id => state.messages.contentMessages.byId[id])
    .filter(message => message.metadata.type === MESSAGE);
  return [state.messages.loading, messages];
};

// select reminders only
export const selectContentReminders = (state: IState) => {
  const reminders = state.messages.contentMessages.list
    .map(id => state.messages.contentMessages.byId[id])
    .filter(reminder => reminder.metadata.type === REMINDER);
  return [state.messages.loading, reminders];
};

// canned message history not implemented yet at back end
export const selectCannedMessageHistory = (messageId: string) => (state: IState) => [
  state.messages.loading,
  // TODO: make the history for canned messages work
  [],
];

export const selectPayloadContent = (messagePayload: { type: string; id: string }) => (
  state: IState,
) => {
  if (!messagePayload) return '';
  const { type, id } = messagePayload;

  switch (type) {
    case 'DOC':
      const document = selectDocument(id)(state);
      return document;
    case 'FORM':
      const form = selectForm(id)(state);
      return form;
    default:
      return '';
  }
};

export const selectMessagesLoading = (state: IState) => state.messages.loading;

export const selectSentMessages = (state: IState) => [state.messages.loading, state.messages.sent];

export const selectScheduledMessages = (state: IState) => [
  state.messages.loading,
  state.messages.scheduled,
];

export const selectSentMessage = (messageId: string) => (state: IState) => {
  const message = state.messages.sent.find(message => message.id === Number(messageId));

  return [state.messages.loading, message || {}];
};

export const selectScheduledMessage = (messageId: string) => (state: IState) => {
  const message = state.messages.scheduled.find(message => message.id === Number(messageId));

  return [state.messages.loading, message || {}];
};
