import React from 'react';
import { Switch, Route } from 'react-router-dom';
import List from './List';
import Details from './Details';
import Edit from './Edit';
import Create from './Create';

export default ({ match }) => (
  <Switch>
    <Route path={`${match.path}/new`} component={Create} />
    <Route path={`${match.path}/:id/edit`} component={Edit} />
    <Route path={`${match.path}/:id`} component={Details} />
    <Route path={`${match.path}`} component={List} />
  </Switch>
);
