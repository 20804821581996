import {
  ISurvey,
  ISurveyData,
  ISurveySubmission,
  ISurveySubmissionsByPipUuid,
  IVersionSurveyData,
} from './types';

export type IFetchSurveys = ReturnType<typeof fetchSurveys>;
export const fetchSurveys = () => ({
  type: 'surveys/fetch' as const,
  payload: {},
});

export type ILoadSurveys = ReturnType<typeof loadSurveys>;
export const loadSurveys = () => ({
  type: 'surveys/load' as const,
  payload: {},
});

export type ISurveysLoaded = ReturnType<typeof surveysLoaded>;
export const surveysLoaded = (surveys: ISurvey[]) => ({
  type: 'surveys/loaded' as const,
  payload: {
    surveys,
  },
});

export type IFetchSurveyData = ReturnType<typeof fetchSurveyData>;
export const fetchSurveyData = (surveyId: string) => ({
  type: 'surveys/fetch-data' as const,
  payload: {
    surveyId,
  },
});

export type IFetchSurveyDataSuccess = ReturnType<typeof fetchSurveyDataSuccess>;
export const fetchSurveyDataSuccess = (surveyId: string, data: ISurveyData) => ({
  type: 'surveys/fetch-data-success' as const,
  payload: {
    surveyId,
    data,
  },
});

export type IFetchSurveyDataFailed = ReturnType<typeof fetchSurveyDataFailed>;
export const fetchSurveyDataFailed = (surveyId: string, error: string) => ({
  type: 'surveys/fetch-data-failed' as const,
  payload: {
    surveyId,
    error,
  },
});

export type IFetchVersionSurveyData = ReturnType<typeof fetchVersionSurveyData>;
export const fetchVersionSurveyData = (
  surveyId: string,
  dataVersion: number,
  schemaVersion: number,
  appUserUISId: string,
) => ({
  type: 'surveys/fetch-version-data' as const,
  payload: {
    surveyId,
    schemaVersion,
    dataVersion,
    appUserUISId,
  },
});

export type IFetchVersionSurveyDataSuccess = ReturnType<typeof fetchVersionSurveyDataSuccess>;
export const fetchVersionSurveyDataSuccess = (
  surveyId: string,
  dataVersion: number,
  data: IVersionSurveyData,
) => ({
  type: 'surveys/fetch-version-data-success' as const,
  payload: {
    surveyId,
    dataVersion,
    data,
  },
});

export type IFetchVersionSurveyDataFailed = ReturnType<typeof fetchVersionSurveyDataFailed>;
export const fetchVersionSurveyDataFailed = (
  surveyId: string,
  dataVersion: number,
  error: string,
) => ({
  type: 'surveys/fetch-version-data-failed' as const,
  payload: {
    surveyId,
    dataVersion,
    error,
  },
});

export type IDownloadSurvey = ReturnType<typeof downloadSurvey>;
export const downloadSurvey = (fileName: string, surveyData: object) => ({
  type: 'surveys/download' as const,
  payload: { fileName, surveyData },
});

export type ICreateSurvey = ReturnType<typeof createSurvey>;
export const createSurvey = (
  name: string,
  description: string,
  language: string,
  published: boolean,
  // surveyData: { schema: object; uiSchema: object },
  type: string,
  path: string,
  // fileName: string,
) => ({
  type: 'surveys/create' as const,
  payload: {
    name,
    description,
    language,
    published,
    // surveyData,
    type,
    path,
    // fileName,
  },
});

export type ISurveyCreated = ReturnType<typeof surveyCreated>;
export const surveyCreated = () => ({
  type: 'surveys/created' as const,
  payload: {},
});

export type IUpdateSurvey = ReturnType<typeof updateSurvey>;
export const updateSurvey = (
  surveyTranslationKey: string,
  uuid: string,
  name: string,
  description: string,
  language: string,
  published: boolean,
  surveyData: { schema: object; uiSchema: object },
  type: string,
  fileName: string,
) => ({
  type: 'surveys/update' as const,
  payload: {
    surveyTranslationKey,
    uuid,
    name,
    description,
    language,
    published,
    surveyData,
    type,
    fileName,
  },
});

export type ISurveyUpdated = ReturnType<typeof surveyUpdated>;
export const surveyUpdated = () => ({
  type: 'surveys/updated' as const,
  payload: {},
});

export type IDeleteSurveys = ReturnType<typeof deleteSurveys>;
export const deleteSurveys = (ids: string[], surveyTranslationKey: string) => ({
  type: 'surveys/delete' as const,
  payload: { ids, surveyTranslationKey },
});

export type ISurveysDeleted = ReturnType<typeof surveysDeleted>;
export const surveysDeleted = (surveys: ISurvey[]) => ({
  type: 'surveys/deleted' as const,
  payload: { surveys },
});

export type IRemoveSurveyData = ReturnType<typeof removeSurveyData>;
export const removeSurveyData = (surveyId: string) => ({
  type: 'surveys/remove-data' as const,
  payload: { surveyId },
});

export type ITemporarySurveysLoaded = ReturnType<typeof temporarySurveysLoaded>;
export const temporarySurveysLoaded = (surveys: ISurvey[]) => ({
  type: 'surveys/temporary-loaded' as const,
  payload: { surveys },
});

export type ICloseSurvey = ReturnType<typeof closeSurvey>;
export const closeSurvey = (surveyId: string) => ({
  type: 'surveys/close' as const,
  payload: { surveyId },
});

export type ICloseSurveySuccess = ReturnType<typeof closeSurveySuccess>;
export const closeSurveySuccess = () => ({
  type: 'surveys/close-success' as const,
  payload: {},
});

export type ICloseSurveyFailed = ReturnType<typeof closeSurveyFailed>;
export const closeSurveyFailed = () => ({
  type: 'surveys/close-failed' as const,
  payload: {},
});

export type IFetchSubmissionsForSurvey = ReturnType<typeof fetchSubmissionsForSurvey>;
export const fetchSubmissionsForSurvey = (surveyId: string) => ({
  type: 'surveys/fetch-submissions' as const,
  payload: { surveyId },
});

export type ISubmissionsForSurveyLoaded = ReturnType<typeof submissionsForSurveyLoaded>;
export const submissionsForSurveyLoaded = (submissions: string) => ({
  type: 'surveys/submissions-loaded' as const,
  payload: { submissions },
});

export type IFetchSubmissionsForSurveyFailed = ReturnType<typeof fetchSubmissionsForSurveyFailed>;
export const fetchSubmissionsForSurveyFailed = () => ({
  type: 'surveys/fetch-submissions-failed' as const,
  payload: {},
});

export type ISubmitSurveyAsAppUser = ReturnType<typeof submitSurveyAsAppUser>;
export const submitSurveyAsAppUser = (surveyId: string, surveyData: object, appUserId: string) => ({
  type: 'surveys/submit-as-app-user' as const,
  payload: { appUserId, surveyId, surveyData },
});

export type ISubmitSurveyAsAppUserSuccess = ReturnType<typeof submitSurveyAsAppUserSuccess>;
export const submitSurveyAsAppUserSuccess = (appUserId: string, submission: ISurveySubmission) => ({
  type: 'surveys/submit-as-app-user-success' as const,
  payload: { appUserId, submission },
});

export type ISubmitSurveyAsAppUserFailed = ReturnType<typeof submitSurveyAsAppUserFailed>;
export const submitSurveyAsAppUserFailed = () => ({
  type: 'surveys/submit-as-app-user-failed' as const,
  payload: {},
});

export type IFetchAppUserSurveySubmissions = ReturnType<typeof fetchAppUserSurveySubmissions>;
export const fetchAppUserSurveySubmissions = (appUserId: string) => ({
  type: 'surveys/fetch-app-user-submissions' as const,
  payload: { appUserId },
});

export type IFetchAppUserSurveySubmissionsSuccess = ReturnType<
  typeof fetchAppUserSurveySubmissionsSuccess
>;
export const fetchAppUserSurveySubmissionsSuccess = (
  appUserId: string,
  submission: ISurveySubmission | undefined,
) => ({
  type: 'surveys/fetch-app-user-submissions-success' as const,
  payload: { appUserId, submission },
});

export type IFetchAppUserSurveySubmissionsFailed = ReturnType<
  typeof fetchAppUserSurveySubmissionsFailed
>;
export const fetchAppUserSurveySubmissionsFailed = () => ({
  type: 'surveys/fetch-app-user-submissions-failed' as const,
  payload: {},
});

export type IFetchAllSurveySubmissions = ReturnType<typeof fetchAllSurveySubmissions>;
export const fetchAllSurveySubmissions = () => ({
  type: 'surveys/fetch-all-survey-submissions' as const,
  payload: {},
});

export type IFetchAllSurveySubmissionsDone = ReturnType<typeof fetchAllSurveySubmissionsDone>;
export const fetchAllSurveySubmissionsDone = (
  surveySubmissionsByPipUuid: ISurveySubmissionsByPipUuid,
) => ({
  type: 'surveys/fetch-all-survey-submissions-done' as const,
  payload: { surveySubmissionsByPipUuid },
});

export type IGetAppUserIdForPipUuid = ReturnType<typeof getAppUserIdForPipUuid>;
export const getAppUserIdForPipUuid = (pipUuid: string) => ({
  type: 'surveys/get-app-user-id-for-pip-id' as const,
  payload: { pipUuid },
});

export type IGetAppUserIdForPipUuidSuccess = ReturnType<typeof getAppUserIdForPipUuidSuccess>;
export const getAppUserIdForPipUuidSuccess = (pipUuid: string, appUserId: string) => ({
  type: 'surveys/get-app-user-id-for-pip-id-success' as const,
  payload: { pipUuid, appUserId },
});

export type IGetAppUserIdForPipUuidFailed = ReturnType<typeof getAppUserIdForPipUuidFailed>;
export const getAppUserIdForPipUuidFailed = (pipUuid: string) => ({
  type: 'surveys/get-app-user-id-for-pip-id-failed' as const,
  payload: { pipUuid },
});

export type INavigateToAppUserSurveys = ReturnType<typeof navigateToAppUserSurveys>;
export const navigateToAppUserSurveys = (pipUuid: string) => ({
  type: 'surveys/navigate-to-app-user-surveys' as const,
  payload: { pipUuid },
});

export type ISetSurveyImagePresignedUrl = ReturnType<typeof setSurveyImagePresignedUrl>;
export const setSurveyImagePresignedUrl = (appUserId: string, imageUrl: string) => ({
  type: 'surverys/set-image-presigned-url' as const,
  payload: { appUserId, imageUrl },
});

export type IEditSurvey = ReturnType<typeof editSurvey>;
export const editSurvey = (id: string, name: string, description: string) => ({
  type: 'surveys/edit' as const,
  payload: { description, id, name },
});

export type ISurveyEdited = ReturnType<typeof surveyEdited>;
export const surveyEdited = (surveys: ISurvey[]) => ({
  type: 'surveys/edited' as const,
  payload: { surveys },
});

export type IEditSurveyFailed = ReturnType<typeof editSurveyFailed>;
export const editSurveyFailed = () => ({
  type: 'surveys/edit-failed' as const,
  payload: {},
});
