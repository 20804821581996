import React, { useCallback, useState } from 'react';
import { Icon, Popover, List, Modal } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector, useDispatch, useStore } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import waitForStoreUpdate from '@utils/waitForStoreUpdate';
import { logoLong } from '@assets';
import {
  faUser,
  faFileAlt,
  faMailbox,
  faHandshakeAlt,
  faKey,
  faCog,
  faTrophy,
  faMusic,
} from '@fortawesome/pro-regular-svg-icons';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import { logout } from '@redux/login/actions';
import { hasValidSession } from '@redux/login/reducer';
import MenuItem from './MenuItem';
import Divider from './Divider';
import LanguageSelector from './LanguageSelectorPopup';
import { Permissions } from '@authorisation/constants';
import { useHasPermissions } from '@authorisation/hooks';

export default () => {
  const name = useSelector(state => state.login.user.name);
  const permissions = useHasPermissions();
  const dispatch = useDispatch();
  const store = useStore();
  const { pathname } = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);

  const handleVisibleChange = visible => {
    setVisible(visible);
  };

  const showLogOutConfirm = useCallback(() => {
    Modal.confirm({
      title: t('logout:title'),
      icon: <FontAwesomeIcon className="log-out-icon" icon={faExclamationCircle} />,
      okText: t('logout:ok'),
      onOk() {
        dispatch(logout());
        return waitForStoreUpdate(store, state => [hasValidSession(state)]);
      },
    });
  }, [dispatch, store, t]);

  return (
    <div className="menu">
      <div className="logo">
        <img src={logoLong} alt="" />
      </div>
      <Divider />
      <div className="menu-items">
        <MenuItem
          icon={<Icon type="bank" />}
          active={pathname === '/'}
          onClick={() => history.push('/')}
        >
          {t('common:Menu.home')}
        </MenuItem>
        {permissions[Permissions.ViewPatients] ? (
          <>
            <Divider />
            <MenuItem
              icon={<FontAwesomeIcon icon={faUser} />}
              active={pathname.startsWith('/app-users')}
              onClick={() => history.push('/app-users')}
            >
              {t('common:Menu.patients.title')}
            </MenuItem>
          </>
        ) : null}
        {permissions[Permissions.ViewMessages] ? (
          <>
            <Divider />
            <MenuItem
              icon={<FontAwesomeIcon icon={faMailbox} />}
              active={pathname.startsWith('/messages')}
              onClick={() => history.push('/messages/sent')}
              subMenuItems={[
                {
                  label: t('common:Menu.messages.sent'),
                  onClick: () => history.push('/messages/sent'),
                  active: pathname.startsWith('/messages/sent'),
                },
                {
                  label: t('common:Menu.messages.scheduled'),
                  onClick: () => history.push('/messages/scheduled'),
                  active: pathname.startsWith('/messages/scheduled'),
                },
              ]}
            >
              {t('common:Menu.messages.title')}
            </MenuItem>
          </>
        ) : null}
        <Divider />
        <MenuItem
          icon={<FontAwesomeIcon icon={faTrophy} />}
          active={pathname.startsWith('/competitions')}
          onClick={() => history.push('/competitions')}
        >
          {t('common:Menu.content.forms')}
        </MenuItem>
        <MenuItem
          icon={<FontAwesomeIcon icon={faMusic} />}
          active={pathname.startsWith('/requests')}
          onClick={() => history.push('/requests')}
        >
          {t('common:Menu.content.requests')}
        </MenuItem>
        <Divider />
        {permissions[Permissions.ViewAdministation] && (
          <MenuItem
            icon={<FontAwesomeIcon icon={faCog} />}
            active={pathname.startsWith('/administration')}
            onClick={() => history.push('/administration')}
            subMenuItems={[
              ...(permissions[Permissions.ViewDashboardUsers]
                ? [
                    {
                      label: t('common:Menu.administration.dashboard-users'),
                      onClick: () => history.push('/administration/dashboard-users'),
                      active: pathname.startsWith('/administration/dashboard-users'),
                    },
                  ]
                : []),
              // ...(permissions[Permissions.AdministerAllHospitals]
              //   ? [
              //       {
              //         label: t('common:Menu.administration.hospitals'),
              //         onClick: () => history.push('/administration/hospitals'),
              //         active: pathname.startsWith('/administration/hospitals'),
              //       },
              //     ]
              //   : []),
              // ...(permissions[Permissions.AdministerHospital]
              //   ? [
              //       {
              //         label: t('common:Menu.administration.hospital'),
              //         onClick: () => history.push('/administration/hospital'),
              //         active: pathname.startsWith('/administration/hospital'),
              //       },
              //     ]
              //   : []),
              {
                label: t('common:Menu.administration.hospitals'),
                onClick: () => history.push('/administration/hospitals'),
                active: pathname.startsWith('/administration/hospitals'),
              },
              {
                label: t('common:Menu.administration.radioStation'),
                onClick: () => history.push('/administration/radio-station'),
                active: pathname.startsWith('/administration/radio-station'),
              },
            ]}
          >
            {t('common:Menu.administration.title')}
          </MenuItem>
        )}
      </div>
      <Popover
        placement="rightBottom"
        title={t('common:Menu.popover.title')}
        trigger="hover"
        content={
          <List
            className="user-details-popover-list"
            size="small"
            dataSource={[
              {
                icon: <Icon type="lock" />,
                title: t('common:Menu.popover.logout'),
                onClick: () => showLogOutConfirm(),
              },
              {
                icon: <FontAwesomeIcon icon={faKey} />,
                title: t('common:Menu.popover.password'),
                onClick: () => history.push('/my-profile/change-password'),
              },
              {
                icon: <FontAwesomeIcon icon={faHandshakeAlt} />,
                title: t('common:Menu.popover.termsnCondition'),
                onClick: () => history.push('/my-profile/terms-and-conditions'),
              },
              {
                icon: <FontAwesomeIcon icon={faFileAlt} />,
                title: t('common:Menu.popover.privacyPolicy'),
                onClick: () => history.push('/my-profile/privacy-policy'),
              },
            ]}
            bordered={false}
            split={false}
            renderItem={item => {
              return item.key ? (
                <Popover
                  overlayClassName="change-languages"
                  content={<LanguageSelector />}
                  placement="rightBottom"
                  trigger="hover"
                  visible={visible}
                  onVisibleChange={handleVisibleChange}
                  getPopupContainer={triggerNode => triggerNode.parentNode}
                >
                  <List.Item>
                    {item.icon} {item.title} {item.rightIcon}
                  </List.Item>
                </Popover>
              ) : (
                <List.Item onClick={item.onClick}>
                  {item.icon} {item.title} {item.rightIcon}
                </List.Item>
              );
            }}
          />
        }
      >
        <div className="user-details">
          <Icon type="lock" />
          <span className="name">{name}</span>
          <Icon type="right" />
        </div>
      </Popover>
    </div>
  );
};
