import {
  faFileAlt,
  faFileEdit,
  faCommentAltLines,
  faPlayCircle,
  faQuestionCircle,
  faTasks,
  faExclamationTriangle,
  faBell,
} from '@fortawesome/pro-regular-svg-icons';
import { pathway } from '@assets/icons';
import { organisationSlug, envName, deploymentSlug, solutionRolePrefix } from 'settings';

export const SONG_REQUEST = 'REQUEST';
export const SHOUT_OUT = 'SHOUTOUT';

// pathways and rules
// what types
export const FEATURE_DOCUMENT = 'FEATURE_DOCUMENT';
export const FEATURE_VIDEO = 'FEATURE_VIDEO';
export const FEATURE_FORM = 'FEATURE_FORM';
export const FEATURE_QUESTIONNAIRE = 'FEATURE_QUESTIONNAIRE';
export const FEATURE_TASK = 'FEATURE_TASK';
export const MESSAGE = 'MESSAGE';
export const MESSAGE_ALERT = 'MESSAGE_ALERT';
export const MESSAGE_REMINDER = 'MESSAGE_REMINDER';
export const TRANSITION_NEXT = 'TRANSITION_NEXT';

export const whatTypes = {
  FEATURE_DOCUMENT: 'cards:ProcedureRuleDetails.what.featureDocument',
  FEATURE_VIDEO: 'cards:ProcedureRuleDetails.what.featureVideo',
  video: 'cards:ProcedureRuleDetails.what.featureVideo', // :FIXME: temporary due to dev data, to be removed
  FEATURE_FORM: 'cards:ProcedureRuleDetails.what.featureForm',
  FEATURE_QUESTIONNAIRE: 'cards:ProcedureRuleDetails.what.featureQuestionnaire',
  questionnaire: 'cards:ProcedureRuleDetails.what.featureQuestionnaire',
  FEATURE_TASK: 'cards:ProcedureRuleDetails.what.featureTask',
  task: 'cards:ProcedureRuleDetails.what.featureTask',
  MESSAGE: 'cards:ProcedureRuleDetails.what.message',
  MESSAGE_ALERT: 'cards:ProcedureRuleDetails.what.messageAlert',
  MESSAGE_REMINDER: 'cards:ProcedureRuleDetails.what.messageReminder',
  TRANSITION_NEXT: 'cards:ProcedureRuleDetails.what.transitionStage',
};

export const whatTypesToContentType = {
  FEATURE_DOCUMENT: 'DOCUMENT',
  FEATURE_VIDEO: 'VIDEO',
  video: 'VIDEO', // :FIXME: temporary due to dev data, to be removed
  FEATURE_FORM: 'FORM',
  FEATURE_QUESTIONNAIRE: 'QUESTIONNAIRE',
  questionnaire: 'QUESTIONNAIRE', // :FIXME: temporary due to dev data, to be removed
  FEATURE_TASK: 'TASK',
  task: 'TASK', // :FIXME: temporary due to dev data, to be removed
  MESSAGE: 'MESSAGE',
  MESSAGE_ALERT: 'ALERT',
  MESSAGE_REMINDER: 'REMINDER',
};

export const whatTypeToTranslationKey = whatType => {
  switch (whatType) {
    case FEATURE_DOCUMENT:
      return 'documents';
    case FEATURE_VIDEO:
      return 'videos';
    case FEATURE_FORM:
      return 'forms';
    case FEATURE_QUESTIONNAIRE:
      return 'questionnaire';
    case FEATURE_TASK:
      return 'tasks';
    case MESSAGE:
      return 'messages';
    case MESSAGE_ALERT:
      return 'alerts';
    case MESSAGE_REMINDER:
      return 'reminders';
    default:
      throw new Error(`Unknown whatType ${whatType}`);
  }
};

export const whatLabelTypes = {
  FEATURE_DOCUMENT: 'common:contentTypes.singular.document',
  FEATURE_VIDEO: 'common:contentTypes.singular.video',
  video: 'common:contentTypes.singular.video', // :FIXME: temporary due to dev data, to be removed
  FEATURE_FORM: 'common:contentTypes.singular.form',
  FEATURE_QUESTIONNAIRE: 'common:contentTypes.singular.questionnaire',
  questionnaire: 'common:contentTypes.singular.questionnaire', // :FIXME: temporary due to dev data, to be removed
  FEATURE_TASK: 'common:contentTypes.singular.task',
  task: 'common:contentTypes.singular.task', // :FIXME: temporary due to dev data, to be removed
  MESSAGE: 'common:contentTypes.singular.message',
  MESSAGE_ALERT: 'common:contentTypes.singular.alert',
  MESSAGE_REMINDER: 'common:contentTypes.singular.reminder',
  TRANSITION_NEXT: 'pathways:ProcedureRules.wizard.content.stageTransition',
};

export const whatTypeIcons = {
  FEATURE_DOCUMENT: faFileAlt,
  FEATURE_VIDEO: faPlayCircle,
  video: faPlayCircle, // :FIXME: temporary due to dev data, to be removed
  FEATURE_FORM: faFileEdit,
  FEATURE_QUESTIONNAIRE: faQuestionCircle,
  questionnaire: faQuestionCircle, // :FIXME: temporary due to dev data, to be removed
  FEATURE_TASK: faTasks,
  task: faTasks, // :FIXME: temporary due to dev data, to be removed
  MESSAGE: faCommentAltLines,
  MESSAGE_ALERT: faExclamationTriangle,
  MESSAGE_REMINDER: faBell,
  TRANSITION_NEXT: pathway,
};

// who types
export const ALL = 'ALL';
export const GROUP = 'GROUP';

export const whoTypes = {
  ALL: 'cards:ProcedureRuleDetails.who.all',
  GROUP: 'cards:ProcedureRuleDetails.who.group',
};

// when types
export const DELAY = 'DELAY';
export const INDEX_EVENT = 'INDEX_EVENT';
// INDEX_EVENT_DELAY is not a real thing. Its just for make UI stuff easier
// before submitting it will need to be changed to 'INDEX_EVENT' with days set in whenDetail
export const INDEX_EVENT_DELAY = 'INDEX_EVENT_DELAY';
export const STAGE_TRANSITION = 'STAGE_TRANSITION';
export const FORM_SUBMITTED = 'FORM_SUBMITTED';
// Questionnaire and Task submitted are not real when types in pathways backend.
// Will need to be changed to FORM_SUBMITTED when submitting to backend.
export const QUESTIONNAIRE_SUBMITTED = 'QUESTIONNAIRE_SUBMITTED';
export const TASK_SUBMITTED = 'TASK_SUBMITTED';
// MANUAL is not a real thing. Its just for make UI stuff easier
// before submitting it will be changed to STAGE_TRANSITION (this is the default WHEN)
export const MANUAL = 'MANUAL';

export const whenTypes = {
  DELAY: 'cards:ProcedureRuleDetails.when.delay',
  INDEX_EVENT: 'cards:ProcedureRuleDetails.when.indexEvent',
  INDEX_EVENT_DELAY: 'cards:ProcedureRuleDetails.when.indexEventDelay',
  STAGE_TRANSITION: 'cards:ProcedureRuleDetails.when.stageTransition',
  FORM_SUBMITTED: 'cards:ProcedureRuleDetails.when.formSubmitted',
  QUESTIONNAIRE_SUBMITTED: 'cards:ProcedureRuleDetails.when.questionnaireSubmitted',
  TASK_SUBMITTED: 'cards:ProcedureRuleDetails.when.taskSubmitted',
  MANUAL: 'cards:ProcedureRuleDetails.when.manual',
};

// message types
export const NOTIFICATION_ONLY = 'notification-only';
export const OPEN_CONTENT = 'open-content';
export const OPEN_LINK = 'open-link';

export const messageTypeToText = {
  [NOTIFICATION_ONLY]: 'common:messageTypes.notificationOnly',
  [OPEN_CONTENT]: 'common:messageTypes.openContent',
  [OPEN_LINK]: 'common:messageTypes.openLink',
};

// user types
export const HOSPITAL_ADMIN = 'hospital-admin';
export const HOSPITAL_USER = 'hospital-user';
export const SUPER_ADMIN = 'super-admin';
export const SUPPORT_ADMIN = 'support-admin';
export const SYSTEM_ADMIN = 'system-admin';
export const APP_USER = 'app-user';

export const userTypesWithLabel = [
  {
    label: 'common:userTypes.hospitalAdmin',
    value: HOSPITAL_ADMIN,
  },
  {
    label: 'common:userTypes.hospitalUser',
    value: HOSPITAL_USER,
  },
  {
    label: 'common:userTypes.superAdmin',
    value: SUPER_ADMIN,
  },
  {
    label: 'common:userTypes.supportAdmin',
    value: SUPPORT_ADMIN,
  },
  {
    label: 'common:userTypes.systemAdmin',
    value: SYSTEM_ADMIN,
  },
  {
    label: 'common:userTypes.appUser',
    value: 'app-user',
  },
];

export const HOSPITAL_ADMIN_URI = `lg:${deploymentSlug}:idm:${organisationSlug}:role/${solutionRolePrefix}${envName}-${HOSPITAL_ADMIN}`;
export const HOSPITAL_USER_URI = `lg:${deploymentSlug}:idm:${organisationSlug}:role/${solutionRolePrefix}${envName}-${HOSPITAL_USER}`;
export const SUPER_ADMIN_URI = `lg:${deploymentSlug}:idm:${organisationSlug}:role/${solutionRolePrefix}${envName}-${SUPER_ADMIN}`;
export const SUPPORT_ADMIN_URI = `lg:${deploymentSlug}:idm:${organisationSlug}:role/${solutionRolePrefix}${envName}-${SUPPORT_ADMIN}`;
export const SYSTEM_ADMIN_URI = `lg:${deploymentSlug}:idm:${organisationSlug}:role/${solutionRolePrefix}${envName}-${SYSTEM_ADMIN}`;

export const mapUserTypeToRoleURI = {
  [HOSPITAL_ADMIN]: HOSPITAL_ADMIN_URI,
  [HOSPITAL_USER]: HOSPITAL_USER_URI,
  [SUPER_ADMIN]: SUPER_ADMIN_URI,
  [SUPPORT_ADMIN]: SUPPORT_ADMIN_URI,
  [SYSTEM_ADMIN]: SYSTEM_ADMIN_URI,
};

export const mapRoleURIToUserType = Object.entries(mapUserTypeToRoleURI).reduce(
  (prev, [key, value]) => ({ ...prev, [value]: key }),
  {},
);

export const DASHBOARD_USER_TYPES = [
  HOSPITAL_USER,
  HOSPITAL_ADMIN,
  SUPER_ADMIN,
  SUPPORT_ADMIN,
  SYSTEM_ADMIN,
];
export const HOSPITAL_USER_TYPES = [HOSPITAL_ADMIN, HOSPITAL_USER];

export const GDPR_DOWNLOAD_REQUEST = 'gdpr-download';
export const GDPR_DELETE_REQUEST = 'gdpr-delete';

// source/owner types
export const HOSPITAL = 'hospital';
export const GLOBAL = 'global';

// Ubiquity messages
export const NO_APP_USER_SELECTED = 'No App User or Group selected';
