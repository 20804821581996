export const ACTIVATE_APP_USER = 'ACTIVATE_APP_USER';
export const APP_ACCESS_CODE_GENERATION_FAILED = 'APP_ACCESS_CODE_GENERATION_FAILED';
export const APP_USER_ACTIVATED = 'APP_USER_ACTIVATED';
export const APP_USER_ACTIVATION_FAILED = 'APP_USER_ACTIVATION_FAILED';
export const APP_USER_DEACTIVATED = 'APP_USER_DEACTIVATED';
export const APP_USER_DEACTIVATION_FAILED = 'APP_USER_DEACTIVATION_FAILED';
export const APP_USERS_LOADED = 'APP_USERS_LOADED';
export const APP_USERS_FORMS_LOADED = 'APP_USERS_FORMS_LOADED';

export const UPDATE_APP_USER = 'UPDATE_APP_USER';
export const UPDATE_APP_USER_SUCCESS = 'UPDATE_APP_USER_SUCCESS';
export const UPDATE_APP_USER_FAILED = 'UPDATE_APP_USER_FAILED';

export const APP_USER_SUBMIT_FORM = 'APP_USER_SUBMIT_FORM';
export const APP_USER_SUBMIT_FORM_SUCCESS = 'APP_USER_SUBMIT_FORM_SUCCESS';
export const APP_USER_SUBMIT_FORM_FAILED = 'APP_USER_SUBMIT_FORM_FAILED';

export const DEACTIVATE_APP_USER = 'DEACTIVATE_APP_USER';
export const DELETE_APP_USERS = 'DELETE_APP_USERS';
export const FETCH_APP_USERS = 'FETCH_APP_USERS';
export const FETCH_APP_USERS_FAILED = 'FETCH_APP_USERS_FAILED';
export const FETCH_APP_USERS_FORMS = 'FETCH_APP_USERS_FORMS';
export const LOAD_APP_USERS_FORMS = 'LOAD_APP_USERS_FORMS';
export const INVITE_APP_USER = 'INVITE_APP_USER';
export const INVITE_APP_USER_SUCCESS = 'INVITE_APP_USER_SUCCESS';
export const INVITE_APP_USER_FAILED = 'INVITE_APP_USER_FAILED';
export const LOAD_APP_USERS = 'LOAD_APP_USERS';
export const RESEND_APP_USER_INVITE = 'RESEND_APP_USER_INVITE';
export const RESEND_APP_USER_INVITE_FAILED = 'RESEND_APP_USER_INVITE_FAILED';
export const RESEND_APP_USER_INVITE_SUCCESS = 'RESEND_APP_USER_INVITE_SUCCESS';

export interface IAppUser {
  address: string;
  age?: number;
  city: string;
  dateOfBirth: string;
  doctor: string;
  email: string;
  firstName: string;
  // FPA Style Hospital
  hospitalId: string;
  // RLP Hospital
  hospital: string;
  id: string;
  ids: {
    [key: string]: string;
  };
  invitationCodes: IUISInvitationCode[];
  isActive: boolean;
  language: string;
  lastName: string;
  phone: string;
  postcode: string;
  roomNumber: string | null;
  sex: string;
  title: string;
  url: string;
  userUrl: string | null;
  uuid: string;
}

interface IPipIds {
  pip: string;
  uuid: string;
}

interface IUISInvitationCode {
  code: string;
  consumed: boolean;
  expires_at: string;
}

export type IUISUserProfile = {
  address: string;
  age?: number;
  city: string;
  dateOfBirth: string;
  doctor: string;
  email: string;
  firstName: string;
  // RLP Hospital ID
  hospital: string;
  hospitalSlug?: string;
  hospitalId: string;
  language: string;
  lastName: string;
  phoneNumber: string;
  postcode: string;
  roomNumber: string | null;
  sex: string;
  title: string;
  yearOfBirth?: number;
};

export interface IUISUser {
  app_id: number;
  ids: IPipIds[];
  disabled: boolean;
  codes: IUISInvitationCode[];
  profile: IUISUserProfile;
  url: string;
  user: string | null;
  uuid: string;
}

export interface IFormSubmissionRaw {
  'form-object-type': string;
  versions: {
    [key: string]: number;
  };
  created: string;
  uuid: string;
}

export interface IFormSubmission {
  formObjectType: string;
  versions: {
    [key: string]: number;
  };
  created: string;
  uuid: string;
}

export interface IAppUsersDataState {
  byId: { [key: string]: IAppUser };
  list: string[];
}

export interface IAppUsersFormsState {
  loading: boolean;
  data: {
    [key: string]: IFormSubmission[];
  };
  submitting: boolean;
}
