import React from 'react';
import AppUserHospitalColumn from '@rlpHospitals/AppUserHospitalColumn';

export default (t, appUsers, canViewPII) => [
  {
    dataIndex: 'firstName',
    title: t('cards:PatientList.columns.name'),
    key: 'name',
    render: firstName => (canViewPII ? firstName : '******'),
    sorter: (a, b) => (a?.firstName || '').localeCompare(b?.firstName || ''),
  },
  {
    dataIndex: 'age',
    title: t('cards:PatientList.columns.age'),
    key: 'age',
    render: age => age,
    sorter: (a, b) => a > b,
  },
  {
    dataIndex: 'hospital',
    title: t('cards:PatientList.columns.hospital'),
    key: 'hospital',
    render: hospital => <AppUserHospitalColumn hospital={hospital} />,
    sorter: (a, b) => a?.name?.localeCompare(b?.name),
    filters: Object.values(
      appUsers
        .filter(au => au.hospital)
        .reduce(
          (acc, nextValue) =>
            nextValue.hospital.id in acc
              ? acc
              : {
                  ...acc,
                  [nextValue.hospital.id]: {
                    text: nextValue.hospital.name,
                    value: nextValue.hospital.id,
                  },
                },
          {},
        ),
    ),
    onFilter: (value, record) => record.hospital?.id === value,
  },
  {
    dataIndex: 'roomNumber',
    title: t('cards:PatientList.columns.ward'),
    key: 'ward',
    render: roomNumber => roomNumber,
    // render: appUser => appUser?.roomNumber,
  },
];
