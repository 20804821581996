import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { usePageActions, useTitleUpdate } from '@hooks';
import { Column, ColumnGroup } from '@cards/Card';
import HeaderButton from '@components/HeaderButton';
import { selectCurrentSurvey, selectSurveyClosing } from '@surveys/redux/reducers';
import { closeSurvey, fetchSurveys } from '@surveys/redux/actions';
import SurveyDetailsCard from '@surveys/cards/Details';
import ListCard from '../cards/List';

const SurveyButton = ({ isSurveyOpen, loading, history, match, survey }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isClosing = useSelector(selectSurveyClosing);

  if (loading) return null;

  return (
    <HeaderButton
      key="new-content"
      loading={isClosing}
      onClick={() =>
        isSurveyOpen ? dispatch(closeSurvey(survey.uuid)) : history.push(`${match.path}/new`)
      }
    >
      {isSurveyOpen ? t('surveys:List.close') : t('surveys:List.new')}
    </HeaderButton>
  );
};

function SurveysList({ history, match }) {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const [loading, currentSurvey] = useSelector(selectCurrentSurvey);

  useEffect(() => {
    dispatch(fetchSurveys());
  }, [dispatch]);

  const { setActions, setTitle } = usePageActions({
    title: t(`surveys:List.title`),
    actions: [
      <SurveyButton
        isSurveyOpen={!!currentSurvey}
        key="create"
        loading={loading}
        history={history}
        match={match}
        survey={currentSurvey}
      />,
    ],
  });
  useTitleUpdate(setTitle, `surveys:List.title`);
  document.title = t(`surveys:List.title`);

  useEffect(() => {
    setActions([
      <SurveyButton
        isSurveyOpen={!!currentSurvey}
        key="create"
        loading={loading}
        history={history}
        match={match}
        survey={currentSurvey}
      />,
    ]);
  }, [currentSurvey, loading, history, match, setActions]);

  return (
    <ColumnGroup>
      <Column>
        <ListCard match={match} />
      </Column>
      <Column>
        <SurveyDetailsCard
          extra={
            currentSurvey ? (
              <Button type="primary" onClick={() => history.push(`${match.path}/edit`)}>
                Edit
              </Button>
            ) : null
          }
          surveyId={currentSurvey?.uuid}
        />
      </Column>
    </ColumnGroup>
  );
}

export default SurveysList;
