import { select } from 'redux-saga/effects';
import { selectCurrentDashboardUser } from '@redux/login/reducer';
import { appToken } from 'settings';

export const BASE_SURVEYS_OBJECT_TYPE = `${appToken}-surveys`;
export const BASE_SURVEY_OBJECT_TYPE = `${appToken}-survey`;
export const SURVEY_SUBMISSIONS_TYPE = `${BASE_SURVEY_OBJECT_TYPE}-submissions`;

export function* getBaseObjectTypeForSurveys() {
  const currentDashboardUser = yield select(selectCurrentDashboardUser);

  if (currentDashboardUser.profile.hospitalId) {
    return `${BASE_SURVEYS_OBJECT_TYPE}-${currentDashboardUser.profile.hospitalId}`;
  }

  return null;
}

export function getBaseObjectTypeForSurvey(surveyId: string) {
  return `${BASE_SURVEY_OBJECT_TYPE}-${surveyId}`;
}
