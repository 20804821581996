import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { editSurvey, fetchSurveys } from '@surveys/redux/actions';
import { selectCurrentSurvey, selectSurveryEditing } from '@surveys/redux/reducers';
import SurveyWizard from './Wizard';

function EditSurvey() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(fetchSurveys());
  }, [dispatch]);

  const [loading, currentSurvey] = useSelector(selectCurrentSurvey);
  const editing = useSelector(selectSurveryEditing);

  const onCompleted = useCallback(
    formData => {
      dispatch(editSurvey(currentSurvey.uuid, formData.details.name, formData.details.description));
    },
    [currentSurvey, dispatch],
  );

  return (
    <SurveyWizard
      exitMessage={t(`surveys:Wizard.exitConfirmation`)}
      initialSurvey={currentSurvey}
      loading={loading}
      submitting={editing}
      title={t('Edit Competition')}
      onCompleted={onCompleted}
    />
  );
}

export default EditSurvey;
