import React from 'react';
import { bool, string } from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { selectOwner } from '@redux/core/reducers';
import './style.less';

function SourceIcon({ source, showText }) {
  const { t } = useTranslation();
  const sourceData = useSelector(selectOwner(source));

  if (!source) return t('common:unknown');

  const { name, icon } = sourceData;

  if (showText) {
    return (
      <span className="source-icon-with-text">
        <FontAwesomeIcon icon={icon} size="lg" />
        {t(name)}
      </span>
    );
  }
  return (
    <Tooltip placement="bottom" title={t(name)}>
      <FontAwesomeIcon icon={icon} size="lg" />
    </Tooltip>
  );
}

SourceIcon.propTypes = {
  source: string,
  showText: bool,
};

SourceIcon.defaultProps = {
  source: null,
  showText: false,
};

export default SourceIcon;
