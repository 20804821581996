import React, { useCallback, useMemo, useState } from 'react';
import { Button, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import useWizard from '@components/Wizard/useWizard';
import { Card } from '@cards/Card';
import IconButton from '@components/IconButton';
import IndividualAccessModal from '@components/IndividualAccessModal';

function Individuals({ error, setError }) {
  const { t } = useTranslation();
  const { currentStepData, form, setCurrentStepData } = useWizard();
  const [selected, setSelected] = useState(currentStepData.individuals || []);
  const [isVisible, setVisible] = useState(false);
  const removeSelected = useCallback(
    selectedId => {
      const updatedSelected = selected.filter(({ id }) => selectedId !== id);
      setSelected(updatedSelected);
      setCurrentStepData({ ...currentStepData, individuals: updatedSelected });
    },
    [currentStepData, selected, setCurrentStepData],
  );

  const toggleVisibility = useCallback(() => {
    setVisible(!isVisible);
  }, [isVisible]);

  const onSubmit = useCallback(
    (_, selectedRows) => {
      setSelected(selectedRows);
      setCurrentStepData({ ...currentStepData, individuals: selectedRows });
      if (selectedRows.length) {
        setError(null);
        form.resetFields(['message-audience']);
      }
      toggleVisibility();
    },
    [currentStepData, form, toggleVisibility, setCurrentStepData, setError],
  );

  const columns = useMemo(
    () => [
      {
        ellipsis: true,
        key: 'name',
        title: t('messages:Wizard.audience.individuals.column'),
        render: (_, record) => `${record.firstName}`,
      },
      {
        dataIndex: 'id',
        title: t('messages:Wizard.audience.delete'),
        render: id => (
          <IconButton
            icon={faTrashAlt}
            tooltip={t('common:buttons.delete')}
            onClick={() => removeSelected(id)}
          />
        ),
        width: 100,
      },
    ],
    [t, removeSelected],
  );

  return (
    <>
      <Card
        className="message-wizard-audience-card"
        title={t('messages:Wizard.audience.individuals.title')}
      >
        {error ? <p className="message-wizard-error">{error}</p> : null}
        <Table columns={columns} dataSource={selected} pagination={false} rowKey="id" />
        <div className="message-wizard-audience-footer">
          <Button icon="plus" type="dashed" onClick={toggleVisibility}>
            {t('messages:Wizard.audience.individuals.add')}
          </Button>
        </div>
      </Card>
      <IndividualAccessModal
        registeredUsersOnly
        visible={isVisible}
        onCancel={toggleVisibility}
        onSubmit={onSubmit}
      />
    </>
  );
}

export default Individuals;
