export const FETCH_RADIO_STATIONS = 'FETCH_RADIO_STATIONS';
export const LOAD_RADIO_STATIONS = 'LOAD_RADIO_STATIONS';
export const LOAD_RADIO_STATIONS_SUCCESS = 'LOAD_RADIO_STATIONS_SUCCESS';
export const LOAD_RADIO_STATIONS_FAILED = 'LOAD_RADIO_STATIONS_FAILED';

export const UPDATE_STATION = 'UPDATE_STATION';
export const UPDATE_STATION_SUCCESS = 'UPDATE_STATION_SUCCESS';
export const UPDATE_STATION_FAILED = 'UPDATE_STATION_FAILED';

type IStream = {
  id: string;
  title: string;
  url: string;
};

export type IStation = {
  id: string;
  name: string;
  address: string;
  streams: IStream[];
};
