import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useReducer,
  useCallback,
} from 'react';
import { useLocation } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

const ActionsContext = createContext(null);

const initialState = {
  actions: {},
  order: [],
  title: {},
  showClose: {},
  showBack: {},
  onClose: {},
};

const pageActionsReducer = (state, action) => {
  switch (action.type) {
    case 'setActions':
      return {
        ...state,
        actions: {
          ...state.actions,
          [action.id]: action.currentActions,
        },
        order: state.order.includes(action.id) ? state.order : [...state.order, action.id],
      };
    case 'clearActions':
      return {
        ...state,
        actions: { ...state.actions, [action.id]: undefined },
        order: state.order.filter(orderId => orderId !== action.id),
      };
    case 'setTitle':
      return {
        ...state,
        title: {
          [action.location.pathname]: action.title,
        },
      };
    case 'setShowClose':
      return {
        ...state,
        showClose: {
          [action.location.pathname]: action.showClose,
        },
      };
    case 'setShowBack':
      return {
        ...state,
        showBack: {
          [action.location.pathname]: action.showBack,
        },
      };
    case 'setOnClose':
      return {
        ...state,
        onClose: {
          [action.location.pathname]: action.onClose,
        },
      };
    default:
      throw new Error();
  }
};

// Wrap <Container> children with this.
export const PageActionsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(pageActionsReducer, initialState);

  return <ActionsContext.Provider value={{ state, dispatch }}>{children}</ActionsContext.Provider>;
};

// Use this in <Header> to get the actions for the current page
export const usePageActionsConsumer = () => {
  const ctx = useContext(ActionsContext);
  const [actions, setActions] = useState([]);
  const location = useLocation();
  useEffect(() => {
    setActions([].concat(...ctx.state.order.map(id => ctx.state.actions[id])));
  }, [ctx.state]);

  return {
    actions,
    title: ctx.state.title[location.pathname],
    showClose: ctx.state.showClose[location.pathname],
    showBack: ctx.state.showBack[location.pathname],
    onClose: ctx.state.onClose[location.pathname],
  };
};

/**
 * Takes initial actions and returns header configuration callbacks
 * @param {array} initialActions
 */
export const usePageActions = ({
  actions: initialActions,
  title: initialTitle,
  showClose: initialShowClose,
  showBack: initialShowBack,
  onClose: initialOnClose,
}) => {
  const defaultCtx = { dispatch: () => {} }; // provide default context if provider has not been initialised
  const ctx = useContext(ActionsContext) || defaultCtx;
  const [id] = useState(uuid());

  const location = useLocation();

  useEffect(() => {
    ctx.dispatch({ type: 'setActions', location, id, currentActions: initialActions });
    if (initialTitle !== undefined) {
      document.title = initialTitle;
      ctx.dispatch({ type: 'setTitle', location, title: initialTitle });
    }
    if (initialShowClose !== undefined) {
      ctx.dispatch({ type: 'setShowClose', location, showClose: initialShowClose });
    }
    if (initialShowBack !== undefined) {
      ctx.dispatch({ type: 'setShowBack', location, showBack: initialShowBack });
    }
    if (initialOnClose !== undefined) {
      ctx.dispatch({ type: 'setOnClose', location, onClose: initialOnClose });
    }

    return () => ctx.dispatch({ type: 'clearActions', id });
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  // ctx is intentionally ommited from deps for these callbacks as it changes every time.
  const setActions = useCallback(
    newActions => ctx.dispatch({ type: 'setActions', location, id, currentActions: newActions }),
    [id, location], // eslint-disable-line react-hooks/exhaustive-deps
  );
  const setTitle = useCallback(
    newTitle => {
      document.title = newTitle;
      return ctx.dispatch({ type: 'setTitle', location, title: newTitle });
    },
    [location], // eslint-disable-line react-hooks/exhaustive-deps
  );
  const setShowClose = useCallback(
    showClose => ctx.dispatch({ type: 'setShowClose', location, showClose }),
    [location], // eslint-disable-line react-hooks/exhaustive-deps
  );
  const setShowBack = useCallback(
    showBack => ctx.dispatch({ type: 'setShowBack', location, showBack }),
    [location], // eslint-disable-line react-hooks/exhaustive-deps
  );

  return {
    setActions,
    setTitle,
    setShowClose,
    setShowBack,
  };
};
