import {
  put,
  call,
  takeLatest,
  getContext,
  select,
  takeEvery,
  take,
  delay,
} from 'redux-saga/effects';
import { message } from 'antd';
import { v4 as uuid } from 'uuid';
import i18next from '../../i18n';
import createPipClient, { PIPObject } from '@api/pipClient';
import { findLatest } from '@utils';
import { selectCurrentDashboardUser } from '@redux/login/reducer';
import { determineSourceTypeForNewContent } from '@utils';
import { selectPermissionsForUser } from '@authorisation/selectors';
import { Permissions } from '@authorisation/constants';
import { SURVEY } from '@utils/contentTypes';
import { appToken, pipAppUrl, getUploadViewURL } from 'settings';
import {
  loadSurveys,
  surveysLoaded,
  fetchSurveyDataSuccess,
  fetchSurveyDataFailed,
  IDownloadSurvey,
  IFetchSurveyData,
  ICreateSurvey,
  surveyCreated,
  surveyUpdated,
  IUpdateSurvey,
  IDeleteSurveys,
  surveysDeleted,
  fetchVersionSurveyDataSuccess,
  IFetchVersionSurveyData,
  ICloseSurvey,
  closeSurveyFailed,
  closeSurveySuccess,
  ISubmitSurveyAsAppUser,
  submitSurveyAsAppUserFailed,
  submitSurveyAsAppUserSuccess,
  IFetchAppUserSurveySubmissions,
  fetchAppUserSurveySubmissionsSuccess,
  fetchAllSurveySubmissionsDone,
  IGetAppUserIdForPipUuid,
  getAppUserIdForPipUuidFailed,
  getAppUserIdForPipUuidSuccess,
  INavigateToAppUserSurveys,
  setSurveyImagePresignedUrl,
  IEditSurvey,
  editSurveyFailed,
  surveyEdited,
} from './actions';
import { doFetchAppUsers } from '@redux/appUsers/sagas/fetch';
import { selectAppUser } from '@redux/appUsers/reducers';
import { selectSurvey, selectCurrentSurvey, selectAppUserByPipUuid } from './reducers';
import { ISurvey, ISurveyData, ISurveySubmission, IVersionSurveyData } from './types';
import takeFirst from '../../redux/takeFirst';
import i18n from '../../i18n';
import {
  getBaseObjectTypeForSurveys,
  getBaseObjectTypeForSurvey,
  BASE_SURVEYS_OBJECT_TYPE,
  SURVEY_SUBMISSIONS_TYPE,
} from './utils';
import { APP_USERS_LOADED, IFormSubmissionRaw } from '@redux/appUsers/types';
import { fetchAppUsers } from '@redux/appUsers/actions';
import competitionFormSchema from '../competition-form-schema.json';

export default function* root() {
  yield takeFirst('surveys/fetch', doFetchSurveys);
  yield takeLatest('surveys/fetch-data', doFetchSurveyData);
  yield takeLatest('surveys/fetch-version-data', doFetchVersionSurveyData);
  yield takeLatest('surveys/download', doDownloadFile);
  yield takeLatest('surveys/create', doCreateSurvey);
  yield takeLatest('surveys/update', doUpdateSurvey);
  yield takeLatest('surveys/delete', doDeleteSurveys);
  yield takeLatest('surveys/close', doCloseSurvey);
  yield takeEvery('surveys/submit-as-app-user', doSubmitSurveyAsAppUser);
  yield takeEvery('surveys/fetch-app-user-submissions', doFetchAppUserSurveySubmissions);
  yield takeLatest('surveys/fetch-all-survey-submissions', doFetchAllSurveySubmissions);
  yield takeEvery('surveys/get-app-user-id-for-pip-id', doGetAppUserIdForPipId);
  yield takeLatest('surveys/navigate-to-app-user-surveys', doNavigateToAppUserSurveys);
  yield takeEvery('surveys/edit', doEditSurvey);
}

function* doFetchSurveys(): any {
  yield put(loadSurveys());
  const tokens = yield getContext('tokens');
  const pipClient = yield call(createPipClient, tokens);
  const objectType = yield call(getBaseObjectTypeForSurveys);
  const [usersLatestSurveys] = yield call(pipClient.getObjectsForType, objectType, 'latest');

  if (usersLatestSurveys && usersLatestSurveys.json && Array.isArray(usersLatestSurveys.json)) {
    const surveys: ISurvey[] = usersLatestSurveys.json.map((survey: ISurvey) => ({
      ...survey,
      type: SURVEY,
    }));

    yield put(surveysLoaded(surveys));

    return;
  }

  yield put(surveysLoaded([]));
}

function* doFetchAllSurveySubmissions(): any {
  try {
    yield doFetchAppUsers(false);
    // fetch all surveys
    let [, currentSurvey] = yield select(selectCurrentSurvey);

    if (!currentSurvey) {
      yield call(doFetchSurveys);
      [, currentSurvey] = yield select(selectCurrentSurvey);
    }

    if (!currentSurvey) {
      return;
    }

    const tokens = yield getContext('tokens');
    const pipClient = yield call(createPipClient, tokens);

    const latestObjectsForAppUsers = yield call(
      pipClient.getLatestObjectsForUsers,
      SURVEY_SUBMISSIONS_TYPE,
    );

    const latestObjectsForCurrentSurveyByAppUserId = latestObjectsForAppUsers.reduce(
      (
        latestByAppUser: {},
        latestSubmissions: {
          app_user: string;
          created: string;
          uuid: string;
          json: { submissions: { created: string; 'form-object-type': string }[] };
        },
      ) => {
        const submissionsForCurrentSurvey =
          latestSubmissions.json.submissions?.filter(submission =>
            submission['form-object-type'].includes(currentSurvey.uuid),
          ) || [];

        if (submissionsForCurrentSurvey.length > 0) {
          submissionsForCurrentSurvey.sort((a, b) => b.created.localeCompare(a.created));
          const extractedAppUserPipId = /[A-Za-z0-9]{8}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{12}/.exec(
            latestSubmissions.app_user,
          );

          if (extractedAppUserPipId) {
            const pipUuid = extractedAppUserPipId[0];

            return { ...latestByAppUser, [pipUuid]: submissionsForCurrentSurvey[0] };
          }
        }

        return latestByAppUser;
      },
      {},
    );

    yield put(fetchAllSurveySubmissionsDone(latestObjectsForCurrentSurveyByAppUserId));
  } catch (err) {
    console.error(err);
    yield put(fetchAllSurveySubmissionsDone({}));
    yield call(message.error, i18n.t('surveys:List.networkError'));
  }
}

function* doFetchSurveyData({ payload: { surveyId } }: IFetchSurveyData): any {
  const tokens = yield getContext('tokens');
  const pipClient = yield call(createPipClient, tokens);
  let objectType = yield call(getBaseObjectTypeForSurvey, surveyId);
  const surveyList = yield select(state => state.surveys.list);
  if (!surveyList.includes(surveyId)) {
    objectType = `${BASE_SURVEYS_OBJECT_TYPE}-${surveyId}`;
  }

  try {
    const { results }: { results: PIPObject<ISurveyData>[] } = yield call(
      pipClient.getObjectsForType,
      objectType,
    );
    const latest = findLatest(results);
    if (latest && latest.json) {
      const {
        results: translations,
      }: { results: PIPObject<{ [key: string]: any }[]>[] } = yield call(
        pipClient.getObjectsForType,
        `${objectType}-i18n`,
      );
      const translationsLatest = findLatest(translations) || { json: {} };
      for (const [key, value] of Object.entries(translationsLatest.json)) {
        i18next.addResourceBundle(key, 'surveys', value, true, false);
      }
      yield put(fetchSurveyDataSuccess(surveyId, latest.json));
    }
  } catch (err) {
    console.error(err);
    yield put(fetchSurveyDataFailed(surveyId, 'failed'));
  }
}

function* doFetchVersionSurveyData({
  payload: { surveyId, dataVersion, schemaVersion, appUserUISId },
}: IFetchVersionSurveyData): any {
  try {
    yield doFetchAppUsers();
    const [, appUser] = yield select(selectAppUser(appUserUISId));

    const tokens = yield getContext('tokens');
    const pipClient = yield call(createPipClient, tokens);

    const appUserResponse: any = yield call(pipClient.getAppUser, appUser.ids.pip);

    if (appUserResponse.results.length === 0) {
      yield call(message.warning, 'User could not be found');
    }

    const pipAppUser = appUserResponse.results[0];
    const objectType = yield call(getBaseObjectTypeForSurvey, surveyId);

    // fetch survey schema version
    // const { results: schemaResult }: { results: PIPObject<any>[] } = yield call(
    //   pipClient.getObjectsForType,
    //   objectType,
    //   schemaVersion.toString(),
    // );

    // fetch survey data
    const results: PIPObject<{ fileName: string }>[] = yield call(
      pipClient.getObjectsForType,
      `${objectType}-data`,
      // dataVersion?.toString(),
      'latest',
      pipAppUser.uuid,
    );

    if (results && results[results.length - 1]) {
      const tokens = yield getContext('tokens');
      const response = yield call(fetch, getUploadViewURL, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${tokens.user.signInUserSession.idToken.jwtToken}`,
        },
        body: JSON.stringify({ file_name: results[results.length - 1].json.fileName }),
      });

      const imageUrl = yield call(response.json.bind(response));
      yield put(setSurveyImagePresignedUrl(appUserUISId, imageUrl.presigned_url));
    }

    const resultForAppUser = results
      .reverse()
      .find(({ app_user }) => app_user?.includes(pipAppUser.uuid));

    if (resultForAppUser) {
      // store successfully
      const versionSurveyData = {
        // schema: schemaResult[0].json.schema,
        // uiSchema: schemaResult[0].json.uiSchema,
        surveyData: resultForAppUser.json,
      } as IVersionSurveyData;

      yield put(fetchVersionSurveyDataSuccess(surveyId, dataVersion, versionSurveyData));
      return;
    }

    throw new Error("Couldn't find survey result for app user");
  } catch (err) {
    console.error(err);
  }
}

function* doDownloadFile({ payload: { fileName, surveyData } }: IDownloadSurvey) {
  const fileData = JSON.stringify(surveyData);
  const blob = new Blob([fileData], { type: 'text/plain' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.download = fileName;
  link.href = url;
  link.click();

  yield call(message.success, i18next.t('surveys:fileDownloaded'));
}

function* doCreateSurvey({
  payload: { name, description, language, published, type, path /* , surveyData, fileName */ },
}: ICreateSurvey): any {
  const permissions = yield select(selectPermissionsForUser);
  const currentDashboardUser = yield select(selectCurrentDashboardUser);

  const newSurvey: ISurvey = {
    type: SURVEY,
    name,
    description: description || '',
    language,
    published,
    version: 1,
    metadata: {
      type,
      fileName: 'competition.json',
      ...(permissions.includes(Permissions.ManagePatients)
        ? { hospitalId: currentDashboardUser.profile.hospitalId }
        : {}),
    },
    uuid: uuid(),
    created: new Date().toISOString(),
    closed: null,
    owner: determineSourceTypeForNewContent(permissions),
  };

  const tokens = yield getContext('tokens');
  const pipClient = yield call(createPipClient, tokens);
  const objectType = yield call(getBaseObjectTypeForSurveys);

  let [latest]: PIPObject<ISurvey[]>[] = yield call(
    pipClient.getObjectsForType,
    objectType,
    'latest',
  );

  if (latest === undefined) {
    yield call(pipClient.createObjectType, objectType, appToken);
  }

  const newSurveys = [newSurvey, ...(latest?.json || [])];

  const response = yield call(pipClient.createObject, objectType, newSurveys);

  const createdSurvey = response.json[0];

  if (createdSurvey.name === newSurvey.name) {
    const surveyTypeName = yield call(getBaseObjectTypeForSurvey, createdSurvey.uuid);

    const objectTypeResponse = yield call(pipClient.createObjectType, surveyTypeName, pipAppUrl);

    const surveySlug = objectTypeResponse.slug;

    const surveySchema = {
      schema: competitionFormSchema.schema,
      uiSchema: competitionFormSchema.uiSchema,
    };

    yield call(pipClient.createObject, surveySlug, surveySchema);

    yield call(pipClient.createObjectType, `${surveyTypeName}-data`, pipAppUrl, [
      objectTypeResponse.url,
    ]);
    yield call(pipClient.createObjectType, `${surveyTypeName}-i18n`, pipAppUrl, [
      objectTypeResponse.url,
    ]);

    yield put(surveysLoaded(newSurveys));
    yield put(surveyCreated());

    const history = yield getContext('history');
    const newPath = path.replace('new', createdSurvey.uuid);

    yield call(history.replace, newPath);

    yield call(message.success, i18next.t(`surveys:Wizard.success`));
  }
}

function* doEditSurvey({ payload: { description, id, name } }: IEditSurvey): any {
  try {
    const [, survey] = yield select(selectCurrentSurvey);

    if (survey) {
      const editedSurvey = {
        ...survey,
        description,
        name,
      };
      const tokens = yield getContext('tokens');
      const pipClient = yield call(createPipClient, tokens);
      const objectType = yield call(getBaseObjectTypeForSurveys);

      const [latest]: PIPObject<ISurvey[]>[] = yield call(
        pipClient.getObjectsForType,
        objectType,
        'latest',
      );

      const editedLatestJson = latest.json.map(survey =>
        survey.uuid === id ? editedSurvey : survey,
      );

      yield call(pipClient.createObject, objectType, editedLatestJson);
      yield put(surveyEdited(editedLatestJson));

      const history = yield getContext('history');
      yield call(history.goBack);
      yield call(message.success, i18next.t('Competition edited successfully'));
    }
  } catch (err) {
    console.error(err);
    yield put(editSurveyFailed());
  }
}

function* doUpdateSurvey({
  payload: {
    surveyTranslationKey,
    uuid,
    name,
    description,
    language,
    published,
    surveyData,
    type,
    fileName,
  },
}: IUpdateSurvey): any {
  const [, survey] = yield select(selectSurvey(uuid));

  const objectType = yield call(getBaseObjectTypeForSurveys);

  const updatedSurvey: ISurvey = {
    ...survey,
    name,
    description: description || '',
    language,
    published,
    version: 1,
    metadata: {
      ...survey.metadata,
      type,
      fileName,
    },
    uuid,
  };

  const tokens = yield getContext('tokens');

  const pipClient = yield call(createPipClient, tokens);

  const [latest]: PIPObject<ISurvey[]>[] = yield call(
    pipClient.getObjectsForType,
    objectType,
    'latest',
  );

  const newSurveys: ISurvey[] = latest.json.map(survey =>
    survey.uuid === uuid ? updatedSurvey : survey,
  );

  yield call(pipClient.createObject, objectType, newSurveys);

  const surveySchema = {
    schema: surveyData.schema,
    uiSchema: surveyData.uiSchema,
  };

  const surveyObjectType = yield call(getBaseObjectTypeForSurvey, uuid);

  yield call(pipClient.createObject, surveyObjectType, surveySchema);

  yield put(surveysLoaded(newSurveys));

  yield put(surveyUpdated());

  const history = yield getContext('history');

  yield call(history.goBack);

  yield call(message.success, i18next.t(`${surveyTranslationKey}:EditSurvey.successMessage`));
}

function* doDeleteSurveys({ payload: { ids, surveyTranslationKey } }: IDeleteSurveys): any {
  const tokens = yield getContext('tokens');
  const objectType = yield call(getBaseObjectTypeForSurveys);
  const pipClient = yield call(createPipClient, tokens);
  const [latest] = yield call(pipClient.getObjectsForType, objectType, 'latest');

  const newSurveys = latest.json.filter((survey: ISurvey) => !ids.includes(survey.uuid));

  yield call(pipClient.createObject, objectType, newSurveys);

  yield put(surveysDeleted(newSurveys));

  yield call(
    message.success,
    i18next.t(`${surveyTranslationKey}:DeleteSurvey.deleteSuccess`, {
      count: ids.length,
    }),
  );
}

function* doCloseSurvey({ payload: { surveyId } }: ICloseSurvey): any {
  try {
    const tokens = yield getContext('tokens');
    const pipClient = yield call(createPipClient, tokens);
    const objectType = yield call(getBaseObjectTypeForSurveys);

    let [latest]: PIPObject<ISurvey[]>[] = yield call(
      pipClient.getObjectsForType,
      objectType,
      'latest',
    );

    const updatedSurveys = latest.json.map((survey: ISurvey) =>
      survey.uuid === surveyId ? { ...survey, closed: new Date().toISOString() } : survey,
    );
    yield call(pipClient.createObject, objectType, updatedSurveys);

    yield put(surveysLoaded(updatedSurveys));
    yield put(closeSurveySuccess());
  } catch (err) {
    console.error(err);
    yield put(closeSurveyFailed());
  }
}

function* doSubmitSurveyAsAppUser({
  payload: { appUserId, surveyData, surveyId },
}: ISubmitSurveyAsAppUser): any {
  try {
    const [, appUser] = yield select(selectAppUser(appUserId));
    const tokens = yield getContext('tokens');
    const pipClient = yield call(createPipClient, tokens);

    const appUserResponse: any = yield call(pipClient.getAppUser, appUser.ids.pip);
    const pipAppUser = appUserResponse.results[0];

    if (!pipAppUser) throw new Error("Couldn't find App User");

    const baseSurveyObjectType = getBaseObjectTypeForSurvey(surveyId);
    const surveyDataObjectType = `${baseSurveyObjectType}-data`;

    // submit new survey data as app user
    yield call(pipClient.createObject, surveyDataObjectType, surveyData, pipAppUser.uuid);

    // fetch describe_versions for form

    const versions = yield call(
      pipClient.describeVersionsForType,
      baseSurveyObjectType,
      pipAppUser.uuid,
      [surveyDataObjectType],
    );

    const newVersion = {
      versions,
      created: new Date().toISOString(),
      uuid: uuid(),
      'form-object-type': baseSurveyObjectType,
    };

    // submit new form submission as app user
    const [latest]: [
      PIPObject<{
        submissions: ISurveySubmission[];
      }>,
    ] = yield call(pipClient.getObjectsForType, SURVEY_SUBMISSIONS_TYPE, 'latest', pipAppUser.uuid);

    if (latest === undefined) {
      try {
        // check if object type exists before attempting to save to it
        yield call(pipClient.getObjectType, SURVEY_SUBMISSIONS_TYPE);
      } catch (err) {
        console.error(err);
        yield call(pipClient.createObjectType, SURVEY_SUBMISSIONS_TYPE, appToken);
      }
    }

    const latestSubmissions = latest && latest.json ? latest.json.submissions : [];
    const newSubmissions = [...latestSubmissions, newVersion] as ISurveySubmission[];

    yield call(
      pipClient.createObject,
      SURVEY_SUBMISSIONS_TYPE,
      { submissions: newSubmissions },
      pipAppUser.uuid,
    );

    yield call(message.success, i18n.t('patients:IndividualDetail.submitFormSuccess'));

    yield put(submitSurveyAsAppUserSuccess(appUserId, newVersion));
  } catch (err) {
    console.error(err);
    yield put(submitSurveyAsAppUserFailed());
    yield call(message.warning, i18n.t('patients:IndividualDetail.submitFormFailed'));
  }
}

function* doFetchAppUserSurveySubmissions({
  payload: { appUserId },
}: IFetchAppUserSurveySubmissions): any {
  let [, appUser] = yield select(selectAppUser(appUserId));

  if (!appUser) {
    yield put(fetchAppUsers());

    while (true) {
      const {
        payload: { appUsers },
      } = yield take(APP_USERS_LOADED);
      if (appUsers) {
        [, appUser] = yield select(selectAppUser(appUserId));
        break;
      }
    }
  }

  // fetch all surveys
  let [, currentSurvey] = yield select(selectCurrentSurvey);

  if (!currentSurvey) {
    yield call(doFetchSurveys);
    [, currentSurvey] = yield select(selectCurrentSurvey);
  }

  if (!currentSurvey) {
    yield put;
  }

  const tokens = yield getContext('tokens');
  const pipClient = yield call(createPipClient, tokens);

  let appUserResponse: any = yield call(pipClient.getAppUser, appUser.ids.pip);

  let pipAppUser = appUserResponse.results[0];
  if (appUserResponse.results.length === 0) {
    console.info('User could not be found in PIP, attempting to create it');
    try {
      const appDetails = yield call(pipClient.getApp, appToken);
      pipAppUser = yield call(
        pipClient.createAppUser,
        appDetails['uuid'],
        appUser.ids.pip,
        'app-user',
      );
      if (!pipAppUser) throw Error('Empty response');
    } catch {
      // yield call(message.warning, 'User could not be found');
      console.error('Failed to create User in PIP. Returning empty forms data.');
      yield put(fetchAppUserSurveySubmissionsSuccess(appUserId, undefined));
      return;
    }
  }

  const [latestSubmissions]: [
    PIPObject<{
      submissions: IFormSubmissionRaw[];
    }>,
  ] = yield call(pipClient.getObjectsForType, SURVEY_SUBMISSIONS_TYPE, 'latest', pipAppUser.uuid);

  if (latestSubmissions && latestSubmissions.json) {
    const submissionsForCurrentSurvey = latestSubmissions.json.submissions.filter(submission =>
      submission['form-object-type'].includes(currentSurvey.uuid),
    );

    if (submissionsForCurrentSurvey.length > 0)
      submissionsForCurrentSurvey.sort((a, b) => b.created.localeCompare(a.created));

    yield put(fetchAppUserSurveySubmissionsSuccess(appUserId, submissionsForCurrentSurvey[0]));
    return;
  }

  yield put(fetchAppUserSurveySubmissionsSuccess(appUserId, undefined));
}

function* doGetAppUserIdForPipId({ payload: { pipUuid } }: IGetAppUserIdForPipUuid): any {
  try {
    const tokens = yield getContext('tokens');
    const client = yield call(createPipClient, tokens);
    const pipAppUser = yield call(client.getAppUserByUuid, pipUuid);

    yield put(getAppUserIdForPipUuidSuccess(pipUuid, pipAppUser.app_user_id));
  } catch (err) {
    console.error(err);
    yield put(getAppUserIdForPipUuidFailed(pipUuid));
  }
}

function* doNavigateToAppUserSurveys({ payload: { pipUuid } }: INavigateToAppUserSurveys): any {
  const history = yield getContext('history');

  while (true) {
    const [, appUser] = yield select(selectAppUserByPipUuid(pipUuid));

    if (appUser) {
      history.push(`/app-users/${appUser.id}/surveys`);
      break;
    }

    yield delay(200);
  }
}
