import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Column, ColumnGroup } from '@cards/Card';
import Wizard from '@components/Wizard';
import WizardItem from '@components/Wizard/Item';
import { usePageActions } from '@hooks/usePageActions';
import SurveyDetailsStep from './DetailsStep';
// import UploadSurveyStep from './UploadStep';
import ReviewStepColumns from './ReviewStepColumns';
import './style.less';

function SurveyWizard({ title, initialSurvey, onCompleted, loading, submitting, exitMessage }) {
  const { t } = useTranslation();

  usePageActions({
    title,
  });

  document.title = title;

  const initialWizardData = useMemo(
    () => ({
      details: {
        name: initialSurvey.name,
        description: initialSurvey.description,
      },
    }),
    [initialSurvey],
  );

  return (
    <Wizard
      onCompleted={onCompleted}
      loading={loading}
      submitting={submitting}
      initialData={initialWizardData}
      exitModalTitle={exitMessage}
    >
      <WizardItem stepKey="details" title={t('forms:FormWizard.steps.details.title')}>
        <ColumnGroup>
          <Column>
            <SurveyDetailsStep />
          </Column>
          <Column></Column>
        </ColumnGroup>
      </WizardItem>
      {/* <WizardItem stepKey="content" title={t('forms:FormWizard.steps.content.title')}>
        <ColumnGroup>
          <Column>
            <UploadSurveyStep />
          </Column>
          <Column></Column>
        </ColumnGroup>
      </WizardItem> */}
      <WizardItem stepKey="review" title={t('forms:FormWizard.steps.review.title')}>
        <ColumnGroup>
          <ReviewStepColumns />
        </ColumnGroup>
      </WizardItem>
    </Wizard>
  );
}

SurveyWizard.propTypes = {
  onCompleted: PropTypes.func.isRequired,
  initialSurvey: PropTypes.object,
  initialSurveyData: PropTypes.object,
  exitMessage: PropTypes.string.isRequired,
};

SurveyWizard.defaultProps = {
  initialSurvey: {},
  initialSurveyData: undefined,
};

export default SurveyWizard;
