import { select } from 'redux-saga/effects';
import { selectCurrentDashboardUser } from '@redux/login/reducer';
import { appToken } from 'settings';

export const BASE_STUDIO_CONTACT_OBJECT_TYPE = `${appToken}-studio-contact`;

export function* getBaseObjectTypeForStudioContact() {
  const currentDashboardUser = yield select(selectCurrentDashboardUser);

  if (currentDashboardUser.profile.hospitalId) {
    return `${BASE_STUDIO_CONTACT_OBJECT_TYPE}-${currentDashboardUser.profile.hospitalId}`;
  }

  return null;
}
