import { IFormSubmissionRaw, IFormSubmission } from './../types';
import { put, call, getContext, select, take } from 'redux-saga/effects';
import { message } from 'antd';
import { GLOBAL_FORMS_OBJECT_TYPE } from '@redux/forms/utils';
import { IForm } from '@redux/forms/types';
import { temporaryFormsLoaded } from '@redux/forms/actions';
import i18n from '../../../i18n';
import {
  fetchAppUsers,
  fetchAppUsersFailed,
  loadAppUsers,
  appUsersFormsLoaded,
  appUsersLoaded,
  IFetchAppUsersForms,
  loadAppUserForms,
  IFetchFormsForAppUserResults,
} from '../actions';
import { selectAppUser, selectAppUsers } from '../reducers';
import { IUISUser, APP_USERS_LOADED } from '@redux/appUsers/types';
import doCreateUISAdminClient from '../../doCreateUISAdminClient';
import { mapUISUserToAppUser } from '@redux/appUsers/utils';
import createPipClient, { PIPObject } from '@api/pipClient';
import { appToken } from 'settings';

export function* doFetchAppUsers(displayErrorMessage = true) {
  yield put(loadAppUsers());

  const latestSeenId = yield select(state => state.appUsers.latestSeenUser);
  const [, currentLoadedUsers] = yield select(selectAppUsers);

  try {
    const client = yield call(doCreateUISAdminClient);
    const appUsers = [];
    let page = 1;
    let done = false;
    let newLatestSeen = undefined;
    while (!done) {
      const { next, results }: { next: string; results: IUISUser[] } = yield call(
        client.listAppUsersForApp,
        page,
      );

      for (const user of results) {
        if (!newLatestSeen) {
          newLatestSeen = user.uuid;
        }
        if (latestSeenId && latestSeenId === user.uuid) {
          done = true;
          break;
        } else {
          appUsers.push(mapUISUserToAppUser(user));
        }
      }
      if (!next) break;
      page += 1;
    }

    const finalNewUsers = [...appUsers, ...currentLoadedUsers];

    yield put(appUsersLoaded(finalNewUsers, newLatestSeen));
  } catch (err) {
    console.error(err);
    yield put(fetchAppUsersFailed());
    if (displayErrorMessage) yield call(message.error, i18n.t('cards:PatientList.networkError'));
  }
}

export function* doFetchAppUserForms({ payload: { appUserUISId } }: IFetchAppUsersForms) {
  yield put(loadAppUserForms());

  let [, appUser] = yield select(selectAppUser(appUserUISId));

  if (!appUser) {
    yield put(fetchAppUsers());

    while (true) {
      const {
        payload: { appUsers },
      } = yield take(APP_USERS_LOADED);
      if (appUsers) {
        [, appUser] = yield select(selectAppUser(appUserUISId));
        break;
      }
    }
  }

  const tokens = yield getContext('tokens');
  const pipClient = yield call(createPipClient, tokens);

  let appUserResponse: any = yield call(pipClient.getAppUser, appUser.ids.pip);

  let pipAppUser = null;
  if (appUserResponse.results.length === 0) {
    console.info('User could not be found in PIP, attempting to create it');
    try {
      const appDetails = yield call(pipClient.getApp, appToken);
      pipAppUser = yield call(
        pipClient.createAppUser,
        appDetails['uuid'],
        appUser.ids.pip,
        'app-user',
      );
      if (!pipAppUser) throw Error('Empty response');
    } catch {
      // yield call(message.warning, 'User could not be found');
      console.error('Failed to create User in PIP. Returning empty forms data.');
      yield put(appUsersFormsLoaded(appUserUISId, []));
      return;
    }
  } else {
    pipAppUser = appUserResponse.results[0];
  }

  const [latest]: [
    PIPObject<{
      submissions: IFormSubmissionRaw[];
    }>,
  ] = yield call(
    pipClient.getObjectsForType,
    `${appToken}-form-submissions`,
    'latest',
    pipAppUser.uuid,
  );

  let formSubmissions: IFormSubmission[] = [];
  if (latest && latest.json) {
    formSubmissions = latest.json.submissions.map(sub => {
      return {
        formObjectType: sub['form-object-type'],
        created: sub.created,
        versions: sub.versions,
        uuid: sub.uuid,
      };
    });
  }

  yield put(appUsersFormsLoaded(appUserUISId, formSubmissions));
}

export function* doFetchAppUserFormsForResults({
  payload: { formSubmissions },
}: IFetchFormsForAppUserResults) {
  try {
    const extractedFormIds = formSubmissions.map(submission => {
      const extractedId = /[A-Za-z\d]{6}-form-(.*)$/.exec(submission.formObjectType);

      return extractedId ? extractedId[1] : null;
    });

    const tokens = yield getContext('tokens');
    const client = yield call(createPipClient, tokens);

    const [latest]: PIPObject<IForm[]>[] = yield call(
      client.getObjectsForType,
      GLOBAL_FORMS_OBJECT_TYPE,
      'latest',
    );

    const appUserResultFormDetails = latest.json.filter(form =>
      extractedFormIds.includes(form.uuid),
    );

    yield put(temporaryFormsLoaded(appUserResultFormDetails));
  } catch (err) {
    console.error(err);
  }
}
