import React, { useEffect } from 'react';
import { bool, shape, string } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Empty } from 'antd';
import moment from 'moment';
import DetailForm from '@components/DetailForm';
import DetailRow from '@components/DetailRow';
import None from '@components/None';
import { fetchSurveys } from '@surveys/redux/actions';
import { selectSurvey } from '@surveys/redux/reducers';
import { Card } from '@cards/Card';
import './style.less';

export const SurveyDetailCard = ({ survey, loading, onChange, title, ...props }) => {
  const { t } = useTranslation();

  return (
    <Card.Half className="form-details" noPadding loading={loading} title={t(title)} {...props}>
      {survey === undefined ? (
        <Empty
          description="There is no competition that is currently open"
          image={Empty.PRESENTED_IMAGE_SIMPLE}
        />
      ) : (
        <DetailForm>
          <DetailRow label={t('cards:FormDetail.labels.title')} value={survey.name} />
          <DetailRow
            label={t('cards:FormDetail.labels.created')}
            value={moment(survey.created).format('L')}
          />
          <DetailRow
            label={t('cards:FormDetail.labels.description')}
            value={survey.description || <None />}
          />
        </DetailForm>
      )}
    </Card.Half>
  );
};

SurveyDetailCard.propTypes = {
  loading: bool.isRequired,
  survey: shape({}),
  title: string,
};

SurveyDetailCard.defaultProps = {
  title: 'cards:FormDetail.title',
  survey: undefined,
};

const SurveyDetailCardById = ({ surveyId, ...props }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSurveys());
  }, [dispatch]);
  const [loading, survey] = useSelector(selectSurvey(surveyId));

  return <SurveyDetailCard survey={survey} loading={loading} {...props} />;
};

export default SurveyDetailCardById;
