import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchAppUsers } from '@redux/appUsers/actions';
import { ColumnGroup, Column } from '@cards/Card';
import AppUserDetails from '@cards/AppUsers/Details';
import { matchType } from '../../propTypes';

function AppUserOverviewTab({ match }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAppUsers());
  }, [dispatch]);

  return (
    <ColumnGroup>
      <Column>
        <AppUserDetails appUserId={match.params.id} />
      </Column>
      <Column></Column>
    </ColumnGroup>
  );
}

AppUserOverviewTab.propTypes = {
  match: matchType.isRequired,
};

export default AppUserOverviewTab;
