import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Spin } from 'antd';
import { getAppUserIdForPipUuid } from '../../redux/actions';
import { selectAppUserByPipUuid } from '@surveys/redux/reducers';
import { selectHospital } from '@rlpHospitals/redux/reducers';

function AppUserHospital({ pipUuid }) {
  const dispatch = useDispatch();
  const [loading$1, appUser] = useSelector(selectAppUserByPipUuid(pipUuid));
  const [loading$2, hospital] = useSelector(selectHospital(appUser?.hospital));

  const loading = loading$1 === 'loading' || loading$1 === 'initial' || loading$2;

  useEffect(() => {
    if (loading$1 === 'initial') {
      dispatch(getAppUserIdForPipUuid(pipUuid));
    }
  }, [appUser, dispatch, loading$1, pipUuid]);

  return loading ? <Spin /> : <div>{hospital?.name}</div>;
}

function AppUserName({ pipUuid }) {
  const dispatch = useDispatch();
  const [loading, appUser] = useSelector(selectAppUserByPipUuid(pipUuid));

  useEffect(() => {
    if (loading === 'initial') {
      dispatch(getAppUserIdForPipUuid(pipUuid));
    }
  }, [appUser, dispatch, loading, pipUuid]);

  return loading === 'loading' || loading === 'initial' ? (
    <Spin />
  ) : (
    <div>{appUser?.firstName}</div>
  );
}

export default (t, hospitals, selectHospitalIdForSurvey) => [
  {
    dataIndex: 'created',
    defaultSortOrder: 'descend',
    key: 'date',
    title: t('cards:SurveyList.columns.date'),
    width: 150,
    render: date => moment(date).format('l LT'),
    sorter: (a, b) => new Date(a.created) > new Date(b.created),
  },
  {
    key: 'name',
    title: t(`cards:SurveyList.columns.name`),
    render: record => <AppUserName pipUuid={record.pipUuid} />,
    // sorter: (a, b) => a.name.localeCompare(b.name),
  },
  {
    key: 'hospital',
    title: t(`cards:PatientList.columns.hospital`),
    render: record => <AppUserHospital pipUuid={record.pipUuid} />,
    filters: hospitals.map(h => ({ value: h.id, text: h.name })),
    onFilter: (value, record) => value === selectHospitalIdForSurvey(record),
  },
];
