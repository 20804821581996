import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bool } from 'prop-types';
import { useTranslation } from 'react-i18next';
import useAppUsersWithHospital from '@rlpHospitals/useAppUsersWithHospital';
import AppUserHospitalColumn from '@rlpHospitals/AppUserHospitalColumn';
import { fetchAppUsers } from '@redux/appUsers/actions';
import { selectAppUsers } from '@redux/appUsers/reducers';
import SelectionModal from './SelectionModal';

const IndividualAccessModal = ({
  registeredUsersOnly,
  submitting,
  visible,
  onCancel,
  onSubmit: onSubmitProp,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loading, appUsers] = useSelector(selectAppUsers);
  const appUsersWithHospital = useAppUsersWithHospital(appUsers);

  useEffect(() => {
    dispatch(fetchAppUsers());
  }, [dispatch]);

  const onSubmit = useCallback(
    selectedRowKeys => {
      const selectedAppUsers = appUsers.filter(user => selectedRowKeys.includes(user.id));
      onSubmitProp(selectedRowKeys, selectedAppUsers);
    },
    [appUsers, onSubmitProp],
  );

  return (
    <SelectionModal
      columns={[
        {
          title: t('common:firstName'),
          dataIndex: 'firstName',
          key: 'firstName',
          render: firstName => <div>{firstName}</div>,
          sorter: (a, b) => a.firstName > b.firstName,
        },
        {
          dataIndex: 'hospital',
          title: t('cards:PatientList.columns.hospital'),
          key: 'hospital',
          render: hospital => <AppUserHospitalColumn hospital={hospital} />,
        },
        {
          title: t('common:age'),
          dataIndex: 'age',
          key: 'age',
          sorter: (a, b) => a.age > b.age,
        },
        {
          title: t('common:room'),
          dataIndex: 'roomNumber',
          key: 'roomNumber',
          sorter: (a, b) => a.roomNumber > b.roomNumber,
        },
      ]}
      dataSource={appUsersWithHospital}
      displayKeys={['firstName']}
      loading={loading}
      primaryKey="id"
      submitting={submitting}
      title={t('cards:DocumentList.individualAccessTitle')}
      width={700}
      visible={visible}
      onCancel={onCancel}
      onSubmit={onSubmit}
    />
  );
};

IndividualAccessModal.propTypes = {
  registeredUsersOnly: bool,
  submitting: bool,
};

IndividualAccessModal.defaultProps = {
  registeredUsersOnly: false,
  submitting: false,
};

export default IndividualAccessModal;
