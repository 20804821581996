import { put, take, select } from 'redux-saga/effects';
import { APP_USERS_LOADED } from '@redux/appUsers/types';
import { fetchAppUsers } from '@redux/appUsers/actions';
import { selectAppUsers } from '@redux/appUsers/reducers';

// for use in sagas, where saga is reliant upon appUsers list
function* doGetAppUsersList() {
  let [, appUsers] = yield select(selectAppUsers);

  if (!appUsers.length) {
    yield put(fetchAppUsers());

    while (true) {
      yield take(APP_USERS_LOADED);

      [, appUsers] = yield select(selectAppUsers);
      break;
    }
  }

  return appUsers;
}

export default doGetAppUsersList;
