import { IState } from '@redux/reducer';
import { combineReducers } from 'redux';
import {
  IDeleteAllRequests,
  IDeleteAllRequestsFailed,
  IDeleteAllRequestsSuccess,
  IDeleteRequest,
  IDeleteRequestFailed,
  IDeleteRequestSuccess,
  ILoadRequests,
  ILoadRequestsFailed,
  IRequestsLoaded,
} from './actions';
import { IRequest } from './types';

function deleting(
  state = false,
  action:
    | IDeleteAllRequests
    | IDeleteAllRequestsFailed
    | IDeleteAllRequestsSuccess
    | IDeleteRequest
    | IDeleteRequestSuccess
    | IDeleteRequestFailed,
) {
  switch (action.type) {
    case 'requests/delete-all':
    case 'requests/delete':
      return true;
    case 'requests/delete-all-failed':
    case 'requests/delete-all-success':
    case 'requests/delete-failed':
    case 'requests/delete-success':
      return false;
    default:
      return state;
  }
}

function loading(state = false, action: ILoadRequests | IRequestsLoaded | ILoadRequestsFailed) {
  switch (action.type) {
    case 'requests/load':
      return true;
    case 'requests/loaded':
    case 'requests/load-failed':
      return false;
    default:
      return state;
  }
}

function list(
  state: IRequest[] = [],
  action: IRequestsLoaded | IDeleteAllRequestsSuccess | IDeleteRequestSuccess,
) {
  switch (action.type) {
    case 'requests/loaded':
      return action.payload.requests;
    case 'requests/delete-all-success':
      return [];
    case 'requests/delete-success':
      return state.filter(request => {
        // Return all requests where either the pipUuid or the created are not equal to the payload
        return (
          request.pipUuid !== action.payload.pipAppUserId ||
          request.created !== action.payload.created
        );
      });
    default:
      return state;
  }
}

export const selectRequestsDeleting = (state: IState) => state.requests.deleting;
export const selectRequestsList = (state: IState) => [state.requests.loading, state.requests.list];

export default combineReducers({ deleting, loading, list });
