import React from 'react';
import { Tooltip, Button } from 'antd';
import PropTypes from 'prop-types';

import './style.less';

const HeaderButton = ({ tooltip, tooltipPlacement, icon, children, ghost = true, ...props }) => (
  <span className="header-button">
    <Tooltip placement={tooltipPlacement} title={tooltip}>
      <Button ghost={ghost} type="primary" {...props}>
        {children}
      </Button>
    </Tooltip>
  </span>
);

HeaderButton.propTypes = {
  tooltip: PropTypes.string,
  tooltipPlacement: PropTypes.string,
};

HeaderButton.defaultProps = {
  tooltip: undefined,
  tooltipPlacement: 'bottom',
};

export default HeaderButton;
