import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import {
  faFileAlt,
  faPlayCircle,
  faQuestionCircle,
  faFileEdit,
  faTasks,
  faCommentAltLines,
  faExclamationTriangle,
  faBell,
} from '@fortawesome/pro-regular-svg-icons';

type contentType = {
  label: string;
  icon: IconDefinition;
  path: string;
};

export const SURVEY = 'SURVEY';
export const DOCUMENT = 'DOCUMENT';
export const VIDEO = 'VIDEO';
export const FORM = 'FORM';
export const QUESTIONNAIRE = 'QUESTIONNAIRE';
export const TASK = 'TASK';
export const MESSAGE = 'MESSAGE';
export const ALERT = 'ALERT';
export const REMINDER = 'REMINDER';

export const documentTypes = [DOCUMENT, VIDEO];
export const formTypes = [FORM, QUESTIONNAIRE, TASK];
export const messageTypes = [MESSAGE, ALERT, REMINDER];

export const contentTypesList = [...documentTypes, ...formTypes, ...messageTypes];

export const contentTypes: { [key: string]: contentType } = {
  DOCUMENT: {
    label: 'common:contentTypes.singular.document',
    icon: faFileAlt,
    path: 'documents',
  },
  VIDEO: {
    label: 'common:contentTypes.singular.video',
    icon: faPlayCircle,
    path: 'videos',
  },
  FORM: {
    label: 'common:contentTypes.singular.form',
    icon: faFileEdit,
    path: 'forms',
  },
  QUESTIONNAIRE: {
    label: 'common:contentTypes.singular.questionnaire',
    icon: faQuestionCircle,
    path: 'questionnaires',
  },
  TASK: {
    label: 'common:contentTypes.singular.task',
    icon: faTasks,
    path: 'tasks',
  },
  MESSAGE: {
    label: 'common:contentTypes.singular.message',
    icon: faCommentAltLines,
    path: 'messages',
  },
  ALERT: {
    label: 'common:contentTypes.singular.alert',
    icon: faExclamationTriangle,
    path: 'alerts',
  },
  REMINDER: {
    label: 'common:contentTypes.singular.reminder',
    icon: faBell,
    path: 'reminders',
  },
};

export const getContentTypeValues = (key: string): contentType => contentTypes[key];

export default { contentTypes, getContentTypeValues };
