import React from 'react';
import { Button, Icon } from 'antd';
import { useHistory } from 'react-router-dom';
import { usePageActionsConsumer } from '../../hooks';
import BackButton from './BackButton';

export default ({ title: titleOverride, showClose: showCloseOverride }) => {
  const history = useHistory();
  const { actions, title, showClose, showBack, onClose } = usePageActionsConsumer();

  const onClick = onClose || history.goBack;

  return (
    <div className="header">
      <div>
        {showBack && <BackButton />}
        <h1>{titleOverride || title}</h1>
      </div>
      <div className="actions">
        {actions}
        {(showCloseOverride || showClose) && (
          <Button size="large" shape="circle" onClick={onClick}>
            <Icon type="close" className="close" />
          </Button>
        )}
      </div>
    </div>
  );
};
