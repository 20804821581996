import React from 'react';
import { Switch, Route } from 'react-router-dom';
import New from './New';
import List from './List';
import Edit from './Edit';

export default ({ match, type }) => (
  <Switch>
    <Route path={`${match.path}/edit`} component={Edit} />
    <Route path={`${match.path}/new`} component={New} />
    <Route path={match.path} component={List} />
  </Switch>
);
