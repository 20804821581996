import React from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from '../../cards/Card';
import { usePageActions, useTitleUpdate } from '../../hooks';
import NewMessageDropdown from './NewMessageDropdown';
import ScheduledMessages from '@cards/Messages/ScheduledList';

function ScheduledList() {
  const { t } = useTranslation();
  const { setTitle } = usePageActions({
    title: t('messages:Scheduled.title'),
    actions: [<NewMessageDropdown key="messages-list" />],
  });
  useTitleUpdate(setTitle, 'messages:Scheduled.title');
  document.title = t('messages:Scheduled.title');

  return (
    <Column>
      <ScheduledMessages />
    </Column>
  );
}

export default ScheduledList;
