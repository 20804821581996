import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import {
  ISurvey,
  ISurveyData,
  ISurveySubmission,
  ISurveySubmissionForList,
  IVersionSurveyData,
} from './types';
import {
  ILoadSurveys,
  ISurveysLoaded,
  IDeleteSurveys,
  ISurveysDeleted,
  IFetchSurveyData,
  IFetchSurveyDataFailed,
  IFetchSurveyDataSuccess,
  IFetchVersionSurveyData,
  IFetchVersionSurveyDataSuccess,
  IFetchVersionSurveyDataFailed,
  ICreateSurvey,
  ISurveyCreated,
  IUpdateSurvey,
  ISurveyUpdated,
  IRemoveSurveyData,
  ITemporarySurveysLoaded,
  ICloseSurvey,
  ICloseSurveyFailed,
  ICloseSurveySuccess,
  ISubmitSurveyAsAppUser,
  ISubmitSurveyAsAppUserFailed,
  ISubmitSurveyAsAppUserSuccess,
  IFetchAppUserSurveySubmissions,
  IFetchAppUserSurveySubmissionsFailed,
  IFetchAppUserSurveySubmissionsSuccess,
  IFetchAllSurveySubmissions,
  IFetchAllSurveySubmissionsDone,
  IGetAppUserIdForPipUuid,
  IGetAppUserIdForPipUuidFailed,
  IGetAppUserIdForPipUuidSuccess,
  ISetSurveyImagePresignedUrl,
  IEditSurvey,
  ISurveyEdited,
  IEditSurveyFailed,
} from './actions';
import { IState } from '../../redux/reducer';
import { selectAppUser } from '@redux/appUsers/reducers';

function appUserSubmissionImages(
  state: { [key: string]: string } = {},
  action: ISetSurveyImagePresignedUrl,
) {
  switch (action.type) {
    case 'surverys/set-image-presigned-url':
      return { ...state, [action.payload.appUserId]: action.payload.imageUrl };
    default:
      return state;
  }
}

function appUserSubmissions(
  state: { [pipUuid: string]: ISurveySubmission } = {},
  action:
    | ISubmitSurveyAsAppUserSuccess
    | IFetchAppUserSurveySubmissionsSuccess
    | IFetchAllSurveySubmissionsDone,
) {
  switch (action.type) {
    case 'surveys/fetch-app-user-submissions-success':
    case 'surveys/submit-as-app-user-success':
      // TODO: fix this to use pip uuid
      return {
        ...state,
        [action.payload.appUserId]: action.payload.submission,
      };
    case 'surveys/fetch-all-survey-submissions-done':
      return action.payload.surveySubmissionsByPipUuid;
    default:
      return state;
  }
}

function allAppUserSubmissionsLoading(
  state = false,
  action: IFetchAllSurveySubmissions | IFetchAllSurveySubmissionsDone,
) {
  switch (action.type) {
    case 'surveys/fetch-all-survey-submissions':
      return true;
    case 'surveys/fetch-all-survey-submissions-done':
      return false;
    default:
      return state;
  }
}

function appUserSubmissionsLoading(
  state = false,
  action:
    | IFetchAppUserSurveySubmissions
    | IFetchAppUserSurveySubmissionsFailed
    | IFetchAppUserSurveySubmissionsSuccess,
) {
  switch (action.type) {
    case 'surveys/fetch-app-user-submissions':
      return true;
    case 'surveys/fetch-app-user-submissions-failed':
    case 'surveys/fetch-app-user-submissions-success':
      return false;
    default:
      return state;
  }
}

function closing(state = false, action: ICloseSurvey | ICloseSurveyFailed | ICloseSurveySuccess) {
  switch (action.type) {
    case 'surveys/close':
      return true;
    case 'surveys/close-failed':
    case 'surveys/close-success':
      return false;
    default:
      return state;
  }
}

const creating = (state: boolean = false, action: ICreateSurvey | ISurveyCreated): boolean => {
  switch (action.type) {
    case 'surveys/create':
      return true;
    case 'surveys/created':
      return false;
    default:
      return state;
  }
};

const deleting = (state: boolean = false, action: IDeleteSurveys | ISurveysDeleted): boolean => {
  switch (action.type) {
    case 'surveys/delete':
      return true;
    case 'surveys/deleted':
      return false;
    default:
      return state;
  }
};

const editing = (
  state: boolean = false,
  action: IEditSurvey | ISurveyEdited | IEditSurveyFailed,
): boolean => {
  switch (action.type) {
    case 'surveys/edit':
      return true;
    case 'surveys/edited':
      return false;
    default:
      return state;
  }
};

const loading = (state: boolean = false, action: ILoadSurveys | ISurveysLoaded): boolean => {
  switch (action.type) {
    case 'surveys/load':
      return true;
    case 'surveys/loaded':
      return false;
    default:
      return state;
  }
};

function submittingSurvey(
  state = false,
  action: ISubmitSurveyAsAppUser | ISubmitSurveyAsAppUserFailed | ISubmitSurveyAsAppUserSuccess,
) {
  switch (action.type) {
    case 'surveys/submit-as-app-user':
      return true;
    case 'surveys/submit-as-app-user-failed':
    case 'surveys/submit-as-app-user-success':
      return false;
    default:
      return state;
  }
}

const updating = (state: boolean = false, action: IUpdateSurvey | ISurveyUpdated): boolean => {
  switch (action.type) {
    case 'surveys/update':
      return true;
    case 'surveys/updated':
      return false;
    default:
      return state;
  }
};

const byId = (
  state: { [key: string]: ISurvey } = {},
  action: ISurveysLoaded | ISurveysDeleted | ITemporarySurveysLoaded | ISurveyEdited,
): { [key: string]: ISurvey } => {
  switch (action.type) {
    case 'surveys/loaded':
    case 'surveys/deleted':
    case 'surveys/temporary-loaded':
    case 'surveys/edited':
      return action.payload.surveys.reduce(
        (surveysById, survey) => ({
          ...surveysById,
          [survey.uuid]: survey,
        }),
        state,
      );
    default:
      return state;
  }
};

const list = (
  state: string[] = [],
  action: ISurveysLoaded | ISurveysDeleted | ISurveyEdited,
): string[] => {
  switch (action.type) {
    case 'surveys/loaded':
    case 'surveys/deleted':
    case 'surveys/edited':
      return action.payload.surveys.map(({ uuid }) => uuid);
    default:
      return state;
  }
};

export interface ISurveyDataState {
  [key: string]: {
    loading: boolean;
    data: ISurveyData | {};
  };
}
const surveyData = (
  state: ISurveyDataState = {},
  action: IFetchSurveyData | IFetchSurveyDataSuccess | IFetchSurveyDataFailed | IRemoveSurveyData,
): ISurveyDataState => {
  switch (action.type) {
    case 'surveys/fetch-data':
      return {
        ...state,
        [action.payload.surveyId]: {
          loading: true,
          data: {},
        },
      };
    case 'surveys/fetch-data-success':
      return {
        ...state,
        [action.payload.surveyId]: {
          loading: false,
          data: action.payload.data,
        },
      };
    case 'surveys/fetch-data-failed':
      return {
        ...state,
        [action.payload.surveyId]: {
          loading: false,
          data: {},
        },
      };
    case 'surveys/remove-data':
      const { [action.payload.surveyId]: toRemove, ...newState } = state;
      return newState;
    default:
      return state;
  }
};

export interface IVersionSurveyDataState {
  [key: string]: {
    loading: boolean;
    data: IVersionSurveyData | {};
  };
}
const versionSurveyData = (
  state: IVersionSurveyDataState = {},
  action: IFetchVersionSurveyData | IFetchVersionSurveyDataSuccess | IFetchVersionSurveyDataFailed,
): IVersionSurveyDataState => {
  switch (action.type) {
    case 'surveys/fetch-version-data':
      return {
        ...state,
        [`${action.payload.surveyId}_${action.payload.dataVersion}`]: {
          loading: true,
          data: {},
        },
      };
    case 'surveys/fetch-version-data-success':
      return {
        ...state,
        [`${action.payload.surveyId}_${action.payload.dataVersion}`]: {
          loading: false,
          data: action.payload.data,
        },
      };
    case 'surveys/fetch-version-data-failed':
      return {
        ...state,
        [`${action.payload.surveyId}_${action.payload.dataVersion}`]: {
          loading: false,
          data: {},
        },
      };

    default:
      return state;
  }
};

type LoadingState = 'initial' | 'loading' | 'loaded' | 'failed';

function loadingPipUuidToAppUserId(
  state: { [pipUuid: string]: LoadingState } = {},
  action: IGetAppUserIdForPipUuid | IGetAppUserIdForPipUuidFailed | IGetAppUserIdForPipUuidSuccess,
) {
  switch (action.type) {
    case 'surveys/get-app-user-id-for-pip-id':
      return { ...state, [action.payload.pipUuid]: 'loading' };
    case 'surveys/get-app-user-id-for-pip-id-success':
      return { ...state, [action.payload.pipUuid]: 'loaded' };
    case 'surveys/get-app-user-id-for-pip-id-failed':
      return { ...state, [action.payload.pipUuid]: 'failed' };
    default:
      return state;
  }
}

function pipUuidToAppUserId(
  state: { [pipUuid: string]: string } = {},
  action: IGetAppUserIdForPipUuidSuccess,
) {
  switch (action.type) {
    case 'surveys/get-app-user-id-for-pip-id-success':
      return { ...state, [action.payload.pipUuid]: action.payload.appUserId };
    default:
      return state;
  }
}

export default combineReducers({
  appUserSubmissionImages,
  appUserSubmissions,
  allAppUserSubmissionsLoading,
  appUserSubmissionsLoading,
  byId,
  closing,
  creating,
  deleting,
  editing,
  surveyData,
  list,
  loading,
  loadingPipUuidToAppUserId,
  pipUuidToAppUserId,
  submittingSurvey,
  updating,
  versionSurveyData,
});

// selects all surveys regardless of type
export const selectSurveys = (state: IState): [boolean, ISurvey[]] => [
  state.surveys.loading,
  state.surveys.list.map(id => state.surveys.byId[id]),
];

export const selectSurvey = (surveyId: string) => (state: IState): [boolean, ISurvey?] => [
  state.surveys.loading,
  state.surveys.byId[surveyId],
];

export const selectSurveyData = (surveyId: string) => (state: IState) => [
  state.surveys.surveyData[surveyId]?.loading ?? false,
  state.surveys.surveyData[surveyId]?.data ?? {},
];

export const selectVersionSurveyData = (surveyId: string, dataVersion: number) => (
  state: IState,
) => [
  state.surveys.versionSurveyData[`${surveyId}_${dataVersion}`]?.loading ?? false,
  state.surveys.versionSurveyData[`${surveyId}_${dataVersion}`]?.data ?? undefined,
];

export const selectSurveyIdList = (state: IState) => state.surveys.list;

export const selectSurveyClosing = (state: IState): boolean => state.surveys.closing;
export const selectSurveyCreating = (state: IState): boolean => state.surveys.creating;
export const selectSurveyDeleting = (state: IState): boolean => state.surveys.deleting;
export const selectSurveryEditing = (state: IState): boolean => state.surveys.editing;
export const selectSurveyUpdating = (state: IState): boolean => state.surveys.updating;

export const selectCurrentSurvey = (state: IState): [boolean, ISurvey | undefined] => {
  const currentSurvey = Object.values(state.surveys.byId).find(survey => !survey.closed);

  return [state.surveys.loading, currentSurvey];
};

export const selectSubmittingSurvey = (state: IState) => state.surveys.submittingSurvey;

export const selectAppUserSurveySubmissions = (appUserId: string) => (state: IState) => [
  state.surveys.appUserSubmissionsLoading,
  state.surveys.appUserSubmissions[appUserId],
];

const selectAppUserSurveySubmissionsListSelector = (state: IState) => {
  const [, currentSurvey] = selectCurrentSurvey(state);

  if (!currentSurvey) return [state.surveys.appUserSubmissionsLoading, []];

  return [
    state.surveys.allAppUserSubmissionsLoading,
    Object.entries(state.surveys.appUserSubmissions).reduce(
      // @ts-ignore
      (list, [pipUuid, submission]: [string, ISurveySubmission]) => [
        ...list,
        { ...submission, pipUuid },
      ],
      [] as ISurveySubmissionForList[],
    ),
  ];
};

export const selectAppUserSurveySubmissionsList = createSelector(
  // @ts-ignore
  [selectAppUserSurveySubmissionsListSelector],
  (
    submissionsList: [
      boolean,
      {
        appUserId: string;
        firstName: string;
        submittedOn: string;
      }[],
    ],
  ) => submissionsList,
);

export const selectAppUserByPipUuid = (pipUuid: string) => (state: IState) => {
  const appUserId = state.surveys.pipUuidToAppUserId[pipUuid];
  let loadingState = (state.surveys.loadingPipUuidToAppUserId[pipUuid] as string) || 'initial';

  if (appUserId) {
    const [appUserLoading, appUser] = selectAppUser(appUserId)(state);

    if (appUserLoading) {
      loadingState = 'loading';
    }

    return [loadingState, appUser];
  }

  return [loadingState, undefined];
};

export const selectAppUserSubmissionImage = (appUserId: string) => (state: IState) =>
  state.surveys.appUserSubmissionImages[appUserId];
