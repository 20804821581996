import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Card } from '@cards/Card';
import DetailForm from '@components/DetailForm';
import DetailRow from '@components/DetailRow';
import { usePageActions } from '@hooks';
import { fetchRLPHospitals } from '../redux/actions';
import { selectHospital } from '../redux/reducers';

import './style.less';

const HospitalDetailsCard = ({ hospital, loading = false }) => {
  const { t } = useTranslation();

  return (
    <>
      <Card
        className="hospital-details-card"
        loading={loading}
        title={t('cards:rlp-hospitals.Details.title')}
      >
        <DetailForm>
          <h3>{t('cards:rlp-hospitals.Details.geofence')}</h3>
          <DetailRow label={t('cards:rlp-hospitals.Details.latitude')} value={hospital?.latitude} />
          <DetailRow
            label={t('cards:rlp-hospitals.Details.longitude')}
            value={hospital?.longitude}
          />
          <DetailRow label={t('cards:rlp-hospitals.Details.radius')} value={hospital?.radius} />
          <DetailRow label={t('cards:rlp-hospitals.Details.hospitalId')} value={hospital?.id} />
        </DetailForm>
      </Card>
    </>
  );
};

const HospitalDetailsByIdCard = ({ id }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchRLPHospitals());
  }, [dispatch]);

  const [loading, hospital] = useSelector(selectHospital(id));

  const { setTitle } = usePageActions({});

  useEffect(() => {
    setTitle(hospital?.name);
  }, [hospital, setTitle]);

  return <HospitalDetailsCard hospital={hospital} loading={loading} />;
};

export default HospitalDetailsByIdCard;
export { HospitalDetailsByIdCard, HospitalDetailsCard };
