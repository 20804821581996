import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Input } from 'antd';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useSearch } from '@hooks';
import { fetchRLPHospitals } from '../redux/actions';
import { selectHospitals } from '../redux/reducers';
import { Card } from '@cards/Card';

import './style.less';

const createColumns = t => [
  {
    title: t('cards:rlp-hospitals.List.columns.name'),
    dataIndex: 'name',
    key: 'name',
    sorter: (a, b) => (a.name || '').localeCompare(b.name || ''),
  },
];

const filterFunc = (value, comparator) => comparator(value.name);

const HospitalsListCard = ({ match }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const columns = useMemo(() => createColumns(t), [t]);

  useEffect(() => {
    dispatch(fetchRLPHospitals());
  }, [dispatch]);

  const [loading, hospitals] = useSelector(selectHospitals);

  const [searchResults, searchProps] = useSearch(hospitals, filterFunc);

  return (
    <>
      <Card className="hospital-list-card" noPadding loading={loading} rowKey="id">
        <div className="search-container">
          <Input.Search placeholder={t('common:search')} {...searchProps}></Input.Search>
        </div>
        <Table
          columns={columns}
          dataSource={searchResults}
          pagination={{ position: 'bottom' }}
          rowKey="uuid"
          onRow={({ id }) => ({
            onClick: () => history.push(`${match.url}/${id}`),
          })}
        />
      </Card>
    </>
  );
};

export default HospitalsListCard;
