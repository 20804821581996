import React from 'react';
import { bool } from 'prop-types';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useWizard from '@components/Wizard/useWizard';
import { Column } from '@cards/Card';
import DetailsCard from '@cards/RadioStation/Details';
import StreamsCard from '@cards/RadioStation/Streams';
import { createStationFromFormData } from '../../Edit';
import { selectRadioStation } from '@redux/stations/reducers';

function ReviewHospitalDetails({ editing }) {
  const { formData } = useWizard();
  const { id } = useParams();
  const [, station] = useSelector(selectRadioStation(id));

  const updatedStation = createStationFromFormData(station, formData);

  return (
    <>
      <Column>
        <DetailsCard station={updatedStation} />
      </Column>
      <Column>
        <StreamsCard station={updatedStation} />
      </Column>
    </>
  );
}

ReviewHospitalDetails.propTypes = {
  editing: bool,
};

ReviewHospitalDetails.defaultProps = {
  editing: false,
};

export default ReviewHospitalDetails;
