import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import DetailForm from '@components/DetailForm';
import DetailRow from '@components/DetailRow';
import { Card } from '../Card';
import { selectRadioStation } from '@redux/stations/reducers';

export default ({ id, station, ...props }) => {
  const { t } = useTranslation();

  let loading = false;
  if (!station) {
    const [_loading, _station] = useSelector(selectRadioStation(id));
    station = _station;
    loading = _loading;
  }

  return (
    <Card loading={loading} title={t('cards:RadioStationDetails.title')} {...props}>
      <DetailForm>
        <DetailRow label={t('cards:RadioStationDetails.name')} value={station?.name} />
        <DetailRow label={t('cards:RadioStationDetails.address')} value={station?.address} />
      </DetailForm>
    </Card>
  );
};
