import React from 'react';
import { useTranslation } from 'react-i18next';

import { Card } from '@cards/Card';
import { Form, Input, Button } from 'antd';

import './style.less';

const HospitalEditCard = ({
  form,
  onSubmit,
  isSubmitting,
  hospital = undefined,
  loading = false,
}) => {
  const { t } = useTranslation();

  const onSubmit$1 = e => {
    e.preventDefault();
    form.validateFieldsAndScroll((err, data) => {
      if (!err) {
        onSubmit(data);
      }
    });
  };

  return (
    <>
      <Card
        className="hospital-edit-card"
        loading={loading}
        title={t('cards:rlp-hospitals.Edit.title')}
      >
        <Form
          labelCol={{ span: 8 }}
          layout="vertical"
          wrapperCol={{ span: 16 }}
          onSubmit={onSubmit$1}
        >
          <Form.Item label={t('cards:rlp-hospitals.Edit.name.label')}>
            {form.getFieldDecorator('name', {
              initialValue: hospital?.name,
              rules: [{ required: true, message: t('cards:rlp-hospitals.Edit.name.required') }],
            })(<Input />)}
          </Form.Item>
          <Form.Item label={t('cards:rlp-hospitals.Edit.latitude.label')}>
            {form.getFieldDecorator('latitude', {
              initialValue: hospital?.latitude,
              rules: [{ required: true, message: t('cards:rlp-hospitals.Edit.latitude.required') }],
            })(<Input />)}
          </Form.Item>
          <Form.Item label={t('cards:rlp-hospitals.Edit.longitude.label')}>
            {form.getFieldDecorator('longitude', {
              initialValue: hospital?.longitude,
              rules: [
                { required: true, message: t('cards:rlp-hospitals.Edit.longitude.required') },
              ],
            })(<Input />)}
          </Form.Item>
          <Form.Item label={t('cards:rlp-hospitals.Edit.radius.label')}>
            {form.getFieldDecorator('radius', {
              initialValue: hospital?.radius || 500,
              rules: [{ required: true, message: t('cards:rlp-hospitals.Edit.radius.required') }],
            })(<Input />)}
          </Form.Item>
          <Button htmlType="submit" loading={isSubmitting} type="primary" onClick={onSubmit$1}>
            {t('common:buttons.save')}
          </Button>
        </Form>
      </Card>
    </>
  );
};

export default Form.create()(HospitalEditCard);
