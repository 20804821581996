import React from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from '../../cards/Card';
import { usePageActions, useTitleUpdate } from '../../hooks';
import NewMessageDropdown from './NewMessageDropdown';
import SentMessages from '@cards/Messages/SentList';

function SentList() {
  const { t } = useTranslation();
  const { setTitle } = usePageActions({
    title: t('messages:Sent.title'),
    actions: [<NewMessageDropdown key="messages-list" />],
  });
  useTitleUpdate(setTitle, 'messages:Sent.title');
  document.title = t('messages:Sent.title');
  return (
    <Column>
      <SentMessages />
    </Column>
  );
}

export default SentList;
