import { IState } from '@redux/reducer';
import { combineReducers } from 'redux';
import {
  Hospital,
  IRLPHospitalsLoaded,
  LOAD_RLP_HOSPITALS,
  RLP_HOSPITALS_LOADED,
  CREATE_RLP_HOSPITAL,
  CREATE_RLP_HOSPITAL_SUCCESS,
  ICreateRLPHospitalSuccess,
  IEditRLPHospitalSuccess,
  EDIT_RLP_HOSPITAL,
  EDIT_RLP_HOSPITAL_SUCCESS,
  IDeleteRLPHospitalSuccess,
} from './actions';

const loadingFactory = (start: string, end: string) => (state = false, action: any) => {
  switch (action.type) {
    case start:
      return true;
    case end:
      return false;
    default:
      return state;
  }
};

const loading = loadingFactory(LOAD_RLP_HOSPITALS, RLP_HOSPITALS_LOADED);
const creating = loadingFactory(CREATE_RLP_HOSPITAL, CREATE_RLP_HOSPITAL_SUCCESS);
const editing = loadingFactory(EDIT_RLP_HOSPITAL, EDIT_RLP_HOSPITAL_SUCCESS);

const list = (state: string[] = [], action: IRLPHospitalsLoaded | ICreateRLPHospitalSuccess) => {
  switch (action.type) {
    case 'rlp-hospitals/hospitals-loaded':
      return action.payload.hospitals.map(h => h.id);
    case 'rlp-hospitals/create-hospital-success':
      return [...state, action.payload.hospital.id];
    default:
      return state;
  }
};

type HospitalMap = {
  [key: string]: Hospital;
};

const byId = (
  state: HospitalMap = {},
  action:
    | IRLPHospitalsLoaded
    | ICreateRLPHospitalSuccess
    | IEditRLPHospitalSuccess
    | IDeleteRLPHospitalSuccess,
) => {
  switch (action.type) {
    case 'rlp-hospitals/hospitals-loaded':
      return action.payload.hospitals.reduce((acc, cur) => ({ ...acc, [cur.id]: cur }), {});
    case 'rlp-hospitals/create-hospital-success':
      return {
        ...state,
        [action.payload.hospital.id]: action.payload.hospital,
      };
    case 'rlp-hospitals/edit-hospital-success':
      return {
        ...state,
        [action.payload.id]: action.payload.hospital,
      };
    case 'rlp-hospitals/delete-success':
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          isDeleted: true,
        },
      };
    default:
      return state;
  }
};

export const hasLoadedHospitals = (state: IState) => state.rlpHospitals.list.length > 0;

export const selectHospitals = (state: IState, includeDeleted = false) => [
  state.rlpHospitals.loading,
  state.rlpHospitals.list
    .map(id => state.rlpHospitals.byId[id])
    .filter(hospital => includeDeleted || !hospital.isDeleted),
];

export const selectHospital = (id: string) => (state: IState) => [
  state.rlpHospitals.loading,
  state.rlpHospitals.byId[id],
];

export default combineReducers({
  loading,
  creating,
  editing,
  list,
  byId,
});
