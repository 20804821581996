import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { usePageActions } from '@hooks';
import { Column, ColumnGroup } from '@cards/Card';
import Edit from '@rlpHospitals/cards/Edit';
import { editRLPHospital, fetchRLPHospitals } from '@rlpHospitals/redux/actions';
import { selectHospital } from '@rlpHospitals/redux/reducers';

export default () => {
  const dispatch = useDispatch();

  const { id } = useParams();

  const isSubmitting = useSelector(state => state.rlpHospitals.editing);
  const [loading, hospital] = useSelector(selectHospital(id));

  useEffect(() => {
    dispatch(fetchRLPHospitals());
  }, [dispatch]);

  const onSubmit = useCallback(data => dispatch(editRLPHospital(id, data)), [dispatch, id]);

  const { setTitle } = usePageActions({});

  useEffect(() => {
    setTitle(hospital?.name);
  }, [hospital, setTitle]);

  return (
    <ColumnGroup>
      <Column size="half">
        <Edit
          isSubmitting={isSubmitting}
          onSubmit={onSubmit}
          loading={loading}
          hospital={hospital}
        />
      </Column>
    </ColumnGroup>
  );
};
