import React from 'react';
import { Tooltip, Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/pro-regular-svg-icons';
import PublishedStatusIcon from '@components/PublishedStatusIcon';
import DashboardUserTypeIcon from '@components/DashboardUserTypeIcon';

import './styles.less';

export default (t, hospitalIndex = {}) => [
  {
    title: t('cards:DashboardUsersList.labels.userType'),
    key: 'type',
    render: user => <DashboardUserTypeIcon userType={user.userType} />,
    sorter: (a, b) => a.userType.localeCompare(b.userType),
    onFilter: (value, record) => record.userType === value,
    width: 200,
  },
  {
    title: t('cards:DashboardUsersList.labels.hospital'),
    key: 'hospital',
    render: user =>
      user.hospitalId && hospitalIndex[user.hospitalId] ? hospitalIndex[user.hospitalId].name : '-',
    sorter: (a, b) => {
      const aHospitalName =
        (a.hospitalId && hospitalIndex[a.hospitalId] && hospitalIndex[a.hospitalId].name) || '';
      const bHospitalName =
        (b.hospitalId && hospitalIndex[b.hospitalId] && hospitalIndex[b.hospitalId].name) || '';

      return aHospitalName.localeCompare(bHospitalName);
    },
    width: 350,
  },
  {
    title: t('cards:DashboardUsersList.labels.fullName'),
    key: 'name',
    render: user => user.name,
    sorter: (a, b) => a.name.localeCompare(b.name),
    onFilter: (value, record) => record.name === value,
    width: 200,
  },
  {
    title: t('cards:DashboardUsersList.labels.contact'),
    key: 'contact',
    render: user => (
      <Tooltip placement="bottom" title={t('cards:DashboardUsersList.email')}>
        <Button
          type="link"
          onClick={e => e.stopPropagation()}
          href={`mailto:${user.email}`}
          className="contact-button"
        >
          <FontAwesomeIcon size="lg" icon={faEnvelope} />
        </Button>
      </Tooltip>
    ),
    width: 100,
    fixed: 'right',
  },
  {
    dataIndex: 'activated',
    title: t('cards:DashboardUsersList.labels.activeAccounts'),
    key: 'active',
    render: activated => <PublishedStatusIcon published={activated} />,
    width: 150,
    fixed: 'right',
  },
];
