import React, { useCallback } from 'react';
import { Modal } from 'antd';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Column, ColumnGroup } from '@cards/Card';
import { usePageActions } from '@hooks';
import HeaderButton from '@components/HeaderButton';
import DetailsCard from '../cards/Details';
import { useTranslation } from 'react-i18next';
import { deleteRLPHospital } from '@rlpHospitals/redux/actions';

export default ({ history, match }) => {
  const { t } = useTranslation();
  const { id } = useParams();

  const dispatch = useDispatch();

  const showModal = useCallback(() => {
    Modal.confirm({
      title: 'Delete Hospital',
      okText: 'Delete Forever',
      cancelText: 'Cancel',
      onOk: closeModal => {
        dispatch(deleteRLPHospital(id));
        closeModal();
      },
      content: (
        <>
          <p>Are you sure you want to delete this hospital?</p>
          <p>
            This will completely remove the geofence for this hospital, and all app users with this
            hospital geofence selected will no longer be able to use the radio streams in this
            location. This action cannot be undone.
          </p>
        </>
      ),
    });
  }, [id, dispatch]);

  usePageActions({
    actions: [
      <HeaderButton
        type="primary"
        ghost={false}
        tooltip={t('rlp-hospitals:details:delete')}
        onClick={showModal}
      >
        {t('rlp-hospitals:details:delete')}
      </HeaderButton>,
      <HeaderButton
        tooltip={t('rlp-hospitals:details:edit')}
        onClick={() => history.push(`${match.url}/edit`)}
      >
        {t('rlp-hospitals:details:edit')}
      </HeaderButton>,
    ],
  });

  return (
    <ColumnGroup>
      <Column size="half">
        <DetailsCard id={id} />
      </Column>
    </ColumnGroup>
  );
};
