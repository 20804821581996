import React from 'react';
import { useSelector } from 'react-redux';
import { Tooltip } from 'antd';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { contentTypes, messageTypes } from '@utils';
import { NO_APP_USER_SELECTED } from '@constants';
import { selectAppUsersById } from '@redux/appUsers/reducers';

export function Audience({ audienceDisplay, t, users }) {
  const [, appUsers] = useSelector(selectAppUsersById('ubiquity', users));

  if (audienceDisplay === NO_APP_USER_SELECTED) {
    return t('cards:ScheduledMessagesList.noAudience');
  }

  return appUsers?.length > 0
    ? appUsers.map(appUser => `${appUser.firstName}`).join(', ')
    : audienceDisplay;
}

export default t => [
  {
    title: t('cards:SentMessagesList.columns.type.title'),
    dataIndex: ['metadata', 'type'],
    key: 'metadata-type',
    render: type => (
      <Tooltip placement="bottom" title={t(contentTypes[type.toUpperCase()].label)}>
        <FontAwesomeIcon size="lg" icon={contentTypes[type.toUpperCase()].icon} />
      </Tooltip>
    ),
    width: 130,
    sorter: (a, b) => a.metadata.type.localeCompare(b.metadata.type),
    filters: messageTypes.map(type => ({ text: t(contentTypes[type].label), value: type })),
    onFilter: (value, record) => record.metadata.type.toUpperCase() === value,
  },
  {
    title: t('cards:SentMessagesList.columns.dateSent'),
    dataIndex: 'lastSentDatetime',
    key: 'lastSentDatetime-date',
    width: 200,
    ellipsis: true,
    sorter: (a, b) => a.lastSentDatetime > b.lastSentDatetime,
    render: dateString => {
      const sentMoment = moment(dateString);

      return `${sentMoment.format('L')} ${sentMoment.format('LT')}`;
    },
  },
  {
    title: t('cards:SentMessagesList.columns.audience'),
    dataIndex: 'audienceDisplay',
    key: 'audience',
    ellipsis: true,
    render: (audienceDisplay, record) => (
      <Audience audienceDisplay={audienceDisplay} t={t} users={record.users} />
    ),
  },
  {
    title: t('cards:SentMessagesList.columns.title'),
    dataIndex: 'title',
    key: 'title',
    ellipsis: true,
  },
];
