import React from 'react';
import { Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { Form } from 'antd';
import useWizard from '@components/Wizard/useWizard';
import DetailForm from '@components/DetailForm';
import { Card } from '@cards/Card';
import './style.less';

const FormDetailsCard = props => {
  const { t } = useTranslation();
  const { currentStepData, form } = useWizard();

  return (
    <Card.Half
      className="new-form-details"
      title={t('forms:FormWizard.steps.details.title')}
      {...props}
    >
      <DetailForm>
        <Form.Item label={t('forms:FormWizard.steps.details.labels.title')}>
          {form.getFieldDecorator('name', {
            rules: [
              {
                required: true,
                message: t('forms:FormWizard.steps.details.feedback.titleRequired'),
              },
            ],
            initialValue: currentStepData.name,
          })(<Input placeholder={t('forms:FormWizard.steps.details.placeholders.title')} />)}
        </Form.Item>

        <Form.Item label={t('cards:FormDetail.labels.description')}>
          {form.getFieldDecorator('description', {
            rules: [],
            initialValue: currentStepData.description,
          })(
            <Input.TextArea
              placeholder={t('forms:FormWizard.steps.details.placeholders.description')}
            />,
          )}
        </Form.Item>
      </DetailForm>
    </Card.Half>
  );
};

export default FormDetailsCard;
