import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { createSurvey } from '@surveys/redux/actions';
import { selectSurveyCreating } from '@surveys/redux/reducers';
import SurveyWizard from './Wizard';

function NewSurvey({ type, match }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { path } = match;

  const creating = useSelector(selectSurveyCreating);

  const onCompleted = useCallback(
    formData => {
      dispatch(
        createSurvey(
          formData.details.name,
          formData.details.description,
          formData.details.language,
          formData.details.status === 'publish' ? true : false,
          // formData.content.json,
          type,
          path,
          // formData.content.fileList[0].name,
        ),
      );
    },
    [dispatch, type, path],
  );

  return (
    <SurveyWizard
      title={t(`surveys:Wizard.title`)}
      exitMessage={t(`surveys:Wizard.exitConfirmation`)}
      type={type}
      onCompleted={onCompleted}
      submitting={creating}
    />
  );
}

export default NewSurvey;
