import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRLPHospitals } from './redux/actions';

export default appUsers => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchRLPHospitals());
  }, [dispatch]);

  const { loading, byId } = useSelector(state => ({
    loading: state.rlpHospitals.loading,
    byId: state.rlpHospitals.byId,
  }));

  return useMemo(() => {
    if (loading) {
      return appUsers.map(au => ({ ...au, hospital: undefined }));
    }
    return appUsers.map(au => ({ ...au, hospital: byId[au.hospital] }));
  }, [loading, appUsers, byId]);
};
