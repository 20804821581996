import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Container from '../components/Container';
import Authentication from './Authentication';
import AuthValidator from './Authentication/AuthValidator';
import AppUserPages from './AppUsers';
import MyProfilePage from './MyProfile';
import Surveys from '@surveys/pages';
import Requests from '@requests/pages';
import Messages from './Messages';
import HomePage from './Home';
import Administration from './Administration';
import { useHasPermissions } from '@authorisation/hooks';
import { Permissions } from '@authorisation/constants';

const App = () => {
  const permissions = useHasPermissions(Permissions.ViewPatients);
  return (
    <AuthValidator>
      <Switch>
        <Route path="/auth" component={Authentication} />
        <Container>
          {permissions[Permissions.ViewPatients] ? (
            <Route path="/app-users" component={AppUserPages} />
          ) : null}
          {/* <Route
          path="/content/documents"
          render={props => (
            <Documents
              {...props}
              documentTranslationKey="documents"
              selector={selectNonVideoDocuments}
            />
          )}
        /> */}
          {/* <Route
          path="/content/videos"
          render={props => (
            <Documents {...props} documentTranslationKey="videos" selector={selectVideoDocuments}>
              <Route path={'/content/videos/new'} component={NewVideo} />
              <Route path={'/content/videos/:id/edit'} component={EditVideo} />
            </Documents>
          )}
        /> */}
          {permissions[Permissions.ViewPatients] ? (
            <Route path="/competitions" component={Surveys} />
          ) : null}
          {permissions[Permissions.ViewPatients] ? (
            <Route path="/requests" component={Requests} />
          ) : null}
          {/* <Route
          path="/content/questionnaires"
          render={props => (
            <FormPages
              {...props}
              type="QUESTIONNAIRE"
              formTranslationKey="questionnaire"
              selector={selectContentQuestionnaires}
            />
          )}
        /> */}
          {/* <Route
          path="/content/tasks"
          render={props => (
            <FormPages
              {...props}
              type="TASK"
              formTranslationKey="tasks"
              selector={selectContentTasks}
            />
          )}
        /> */}
          {/* <Route
          path="/content/messages"
          render={props => (
            <CannedMessages
              {...props}
              type="MESSAGE"
              messageTranslationKey="messages"
              selector={selectContentMessages}
            />
          )}
        /> */}
          {/* <Route
          path="/content/alerts"
          render={props => (
            <CannedMessages
              {...props}
              type="ALERT"
              messageTranslationKey="alerts"
              selector={selectContentAlerts}
            />
          )}
        /> */}
          {/* <Route
          path="/content/reminders"
          render={props => (
            <CannedMessages
              {...props}
              type="REMINDER"
              messageTranslationKey="reminders"
              selector={selectContentReminders}
            />
          )}
        /> */}
          <Route path="/messages" component={Messages} />
          <Route path="/administration" component={Administration} />
          <Route path="/my-profile" component={MyProfilePage} />
          <Route exact path="/" component={HomePage} />
        </Container>
      </Switch>
    </AuthValidator>
  );
};

export default withTranslation()(App);
