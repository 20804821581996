import React, { useCallback, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useTitleUpdate, usePageActions } from '@hooks';
import { fetchStations, updateStation } from '@redux/stations/actions';
import { selectRadioStation } from '@redux/stations/reducers';
import StationWizard from './Wizard';

export const createStationFromFormData = (station, formData) => {
  return {
    ...station,
    name: formData.details.name,
    address: formData.details.address,
    streams: [
      {
        ...station?.streams[0],
        title: formData.streams.stream1Title,
        url: formData.streams.stream1Url,
      },
      {
        ...station?.streams[1],
        title: formData.streams.stream2Title,
        url: formData.streams.stream2Url,
      },
    ],
  };
};

function EditStation({ match }) {
  const dispatch = useDispatch();
  const selector = useCallback(selectRadioStation(match.params.id), [match]);
  const [loading, station] = useSelector(selector);
  const { t } = useTranslation();

  const interpolation = useMemo(() => ({ stationName: station?.name || '' }), [station]);
  const { setTitle } = usePageActions({
    title: t('stations:Wizard.editTitle', interpolation),
  });

  useTitleUpdate(setTitle, 'stations:Wizard.editTitle', interpolation);

  useEffect(() => {
    dispatch(fetchStations());
  }, [dispatch]);

  const initialData = useMemo(() => {
    return {
      details: { name: station?.name, address: station?.address },
      streams: {
        stream1Title: station?.streams[0]?.title,
        stream1Url: station?.streams[0]?.url,
        stream2Title: station?.streams[1]?.title,
        stream2Url: station?.streams[1]?.url,
      },
    };
  }, [station]);

  const onCompleted = useCallback(
    formData => {
      const updatedStation = createStationFromFormData(station, formData);
      dispatch(updateStation(updatedStation));
    },
    [dispatch, station],
  );

  return (
    <StationWizard
      editing
      initialData={initialData}
      loading={loading}
      submitting={false} // STATIONS TODO
      onCompleted={onCompleted}
    />
  );
}

export default EditStation;
