export const FETCH_RLP_HOSPITALS = 'rlp-hospitals/fetch-hospitals' as const;
export type IFetchRLPHospitals = ReturnType<typeof loadRLPHospitals>;
export const fetchRLPHospitals = () => ({
  type: FETCH_RLP_HOSPITALS,
  payload: {},
});

export const LOAD_RLP_HOSPITALS = 'rlp-hospitals/load-hospitals' as const;
export type ILoadRLPHospitals = ReturnType<typeof loadRLPHospitals>;
export const loadRLPHospitals = () => ({
  type: LOAD_RLP_HOSPITALS,
  payload: {},
});

export interface Hospital {
  id: string;
  radioStationId: string;
  name: string;
  latitude: string;
  longitude: string;
  radius: number;
  isDeleted?: boolean;
}

export const RLP_HOSPITALS_LOADED = 'rlp-hospitals/hospitals-loaded' as const;
export type IRLPHospitalsLoaded = ReturnType<typeof rlpHospitalsLoaded>;
export const rlpHospitalsLoaded = (hospitals: Hospital[]) => ({
  type: RLP_HOSPITALS_LOADED,
  payload: {
    hospitals,
  },
});

export interface NewHospitalData {
  name: string;
  latitude: string;
  longitude: string;
  radius: number;
}

export const CREATE_RLP_HOSPITAL = 'rlp-hospitals/create-hospital' as const;
export type ICreateRLPHospital = ReturnType<typeof createRLPHospital>;
export const createRLPHospital = (hospital: NewHospitalData) => ({
  type: CREATE_RLP_HOSPITAL,
  payload: {
    hospital,
  },
});

export const CREATE_RLP_HOSPITAL_SUCCESS = 'rlp-hospitals/create-hospital-success' as const;
export type ICreateRLPHospitalSuccess = ReturnType<typeof createRLPHospitalSuccess>;
export const createRLPHospitalSuccess = (hospital: Hospital) => ({
  type: CREATE_RLP_HOSPITAL_SUCCESS,
  payload: {
    hospital,
  },
});

export const EDIT_RLP_HOSPITAL = 'rlp-hospitals/edit-hospital' as const;
export type IEditRLPHospital = ReturnType<typeof editRLPHospital>;
export const editRLPHospital = (id: string, data: NewHospitalData) => ({
  type: EDIT_RLP_HOSPITAL,
  payload: {
    id,
    data,
  },
});

export const EDIT_RLP_HOSPITAL_SUCCESS = 'rlp-hospitals/edit-hospital-success' as const;
export type IEditRLPHospitalSuccess = ReturnType<typeof editRLPHospitalSuccess>;
export const editRLPHospitalSuccess = (id: string, hospital: Hospital) => ({
  type: EDIT_RLP_HOSPITAL_SUCCESS,
  payload: {
    id,
    hospital,
  },
});

export const DELETE_RLP_HOSPITAL = 'rlp-hospitals/delete' as const;
export type IDeleteRLPHospital = ReturnType<typeof deleteRLPHospital>;
export const deleteRLPHospital = (id: string) => ({
  type: DELETE_RLP_HOSPITAL,
  payload: {
    id,
  },
});

export const DELETE_RLP_HOSPITAL_SUCCESS = 'rlp-hospitals/delete-success' as const;
export type IDeleteRLPHospitalSuccess = ReturnType<typeof deleteRLPHospitalSuccess>;
export const deleteRLPHospitalSuccess = (id: string) => ({
  type: DELETE_RLP_HOSPITAL_SUCCESS,
  payload: {
    id,
  },
});
