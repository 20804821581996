import React from 'react';
import { Column } from '@cards/Card';
import useWizard from '@components/Wizard/useWizard';
import Details from './Details';
// import { FormContentCard } from '@cards/Forms/FormContent';

import './style.less';

export default function ReviewStepColumns() {
  const { formData } = useWizard();

  return (
    <>
      <Column>
        <Details form={formData.details} />
        {/* <FormContentCard
          form={{ metadata: { fileName: formData.content.fileList[0].name } }}
          formData={formData.content.json}
          loading={false}
          canDownload
          canPreview={false}
        /> */}
      </Column>
      <Column></Column>
    </>
  );
}
