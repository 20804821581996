import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { fetchAppUsers } from '@redux/appUsers/actions';
import { selectAppUsers } from '@redux/appUsers/reducers';
import createColumns from './createColumns';
import { useHasPermissions } from '@authorisation/hooks';
import { Permissions } from '@authorisation/constants';
import useAppUsersWithHospital from '@rlpHospitals/useAppUsersWithHospital';
import SearchableTable from '@components/SearchableTable';
import './styles.less';

const filterFunc = (value, comparator) => {
  return comparator(`${value.firstName}`) || comparator(value.email);
};

const AppUserListCard = ({ showTitle }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(fetchAppUsers());
  }, [dispatch]);

  const [loading, appUsers] = useSelector(selectAppUsers);
  const appUsersWithHospital = useAppUsersWithHospital(appUsers);
  const { [Permissions.ViewPatients]: canViewPII } = useHasPermissions(Permissions.ViewPatients);
  const columns = useMemo(() => createColumns(t, appUsersWithHospital, canViewPII), [
    appUsersWithHospital,
    canViewPII,
    t,
  ]);

  return (
    <SearchableTable
      className="app-user-list-card"
      columns={columns}
      dataSource={appUsersWithHospital}
      filterFunc={filterFunc}
      hasRoutedPagination
      loading={loading}
      title={showTitle ? <h3>{t('cards:DocumentList.title')}</h3> : null}
      onRowClick={({ id }) => history.push(`/app-users/${id}`)}
    />
  );
};

export default AppUserListCard;
