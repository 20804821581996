import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { usePageActions, useTitleUpdate } from '@hooks';
import { Column } from '@cards/Card';
import HeaderButton from '@components/HeaderButton';
import ListCard from '@requests/cards/List';
import { deleteAllRequests, fetchRequests } from '@requests/redux/actions';
import { selectRequestsDeleting } from '@requests/redux/reducers';

function DeleteAllButton() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isDeletingRequests = useSelector(selectRequestsDeleting);

  return (
    <HeaderButton
      key="delete-all"
      loading={isDeletingRequests}
      onClick={() => dispatch(deleteAllRequests())}
    >
      {t('requests:List.delete')}
    </HeaderButton>
  );
}

function RequestsList({ history, match }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchRequests());
  }, [dispatch]);

  const { setActions, setTitle } = usePageActions({
    title: t(`requests:List.title`),
    actions: [<DeleteAllButton key="delete-all" />],
  });
  useTitleUpdate(setTitle, `requests:List.title`);
  document.title = t(`requests:List.title`);

  useEffect(() => {
    setActions([<DeleteAllButton key="delete-all" />]);
  }, [setActions, t]);

  return (
    <Column>
      <ListCard match={match} />
    </Column>
  );
}

export default RequestsList;
