import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form, Empty } from 'antd';
import moment from 'moment';
import DetailRow from '@components/DetailRow';
import ContentIcon from '@components/ContentIcon';
import LanguageIcon from '@components/LanguageIcon';
import SourceIcon from '@components/SourceIcon';
import None from '@components/None';
import PublishedStatusIcon from '@components/PublishedStatusIcon';
import { fetchForms } from '@redux/forms/actions';
import { selectForm } from '@redux/forms/reducers';
import { Card } from '@cards/Card';
import './style.less';

const FormDetailCard = ({ form, loading, onChange, title, formTranslationKey, ...props }) => {
  const { t } = useTranslation();

  return (
    <Card.Half className="form-details" noPadding loading={loading} title={t(title)} {...props}>
      {form === undefined ? (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      ) : (
        <Form
          labelCol={{ span: 8 }}
          layout="vertical"
          wrapperCol={{ span: 16 }}
          className="detail-form"
        >
          <DetailRow
            label={t('cards:FormDetail.labels.type')}
            value={<ContentIcon contentType={form.metadata?.type || form.type} showText />}
          />
          <DetailRow label={t('cards:FormDetail.labels.title')} value={form.name} />
          <DetailRow label={t('cards:FormDetail.labels.version')} value={form.version} />
          <DetailRow
            label={t('cards:FormDetail.labels.status')}
            value={<PublishedStatusIcon published={form.published} showText />}
          />
          <DetailRow
            label={t('cards:FormDetail.labels.created')}
            value={moment(form.created).format('L')}
          />
          <DetailRow
            label={t('cards:FormDetail.labels.description')}
            value={form.description || <None />}
          />
          <DetailRow
            label={t('cards:FormDetail.labels.source')}
            value={<SourceIcon source={form.owner} showText />}
          />
          <DetailRow
            label={t('cards:FormDetail.labels.language')}
            value={<LanguageIcon language={form.language} showText />}
          />
        </Form>
      )}
    </Card.Half>
  );
};

FormDetailCard.propTypes = {
  title: PropTypes.string,
  form: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  formTranslationKey: PropTypes.string,
};

FormDetailCard.defaultProps = {
  title: 'cards:FormDetail.title',
  form: undefined,
  formTranslationKey: 'forms',
};

const FormDetailCardById = ({ formId, ...props }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchForms());
  }, [dispatch]);
  const [loading, form] = useSelector(selectForm(formId));

  return <FormDetailCard form={form} loading={loading} {...props} />;
};

export { FormDetailCard };

export default FormDetailCardById;
