import React from 'react';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import IconButton from '@components/IconButton';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import './styles.less';

const BackButton = ({ onClick, ...props }) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <span className="back-button">
      <IconButton
        icon={faChevronLeft}
        tooltip={t('common:buttons.back')}
        onClick={onClick || history.goBack}
        {...props}
      />
    </span>
  );
};

export default BackButton;
