import i18n from '../i18n';

export const groupNameColumn = {
  title: i18n.t('cards:GroupList.columns.title'),
  dataIndex: 'name',
  key: 'name',
  width: 250,
  ellipsis: true,
  sorter: (a, b) => a.name.localeCompare(b.name),
};

export const individualsNameColumn = t => ({
  title: t('common:name'),
  key: 'firstName',
  render: appUser => `${appUser.firstName}`,
  sorter: (a, b) => (a.firstName || '').localeCompare(b.firstName || ''),
});
