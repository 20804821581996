import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Column, ColumnGroup } from '@cards/Card';
import HeaderButton from '@components/HeaderButton';
import { usePageActions } from '@hooks';
import DetailsCard from '@cards/RadioStation/Details';
import StreamsCard from '@cards/RadioStation/Streams';
import { selectRadioStation } from '@redux/stations/reducers';

export default ({ match, history }) => {
  const { t } = useTranslation();
  const { id } = match.params;
  const [, station] = useSelector(selectRadioStation(id));

  const { setTitle } = usePageActions({
    title: '',
    actions: [
      <HeaderButton
        key="edit-btn"
        tooltip={t('common:buttons.edit')}
        onClick={() => history.push(`${match.path.replace('/:id', `/edit/${id}`)}`)}
      >
        {t('common:buttons.edit')}
      </HeaderButton>,
    ],
    showBack: false,
  });

  useEffect(() => {
    setTitle(station?.name);
  }, [setTitle, station]);

  return (
    <ColumnGroup>
      <Column>
        <DetailsCard id={id} />
      </Column>
      <Column>
        <StreamsCard id={id} />
      </Column>
    </ColumnGroup>
  );
};
