import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Input } from 'antd';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSearch } from '@hooks';
import { fetchDashboardUsers } from '@redux/dashboardUsers/actions';
import { selectDashboardUsers } from '@redux/dashboardUsers/reducers';
import { fetchHospitals } from '@redux/hospitals/actions';
import { selectHospitals } from '@redux/hospitals/reducers';
import { Card } from '@cards/Card';
import createColumns from './createColumns';

import './styles.less';

const filterFunc = (value, comparator) => {
  return comparator(value.name);
};

const AppUserListCard = ({ showTitle }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(fetchDashboardUsers());
    dispatch(fetchHospitals());
  }, [dispatch]);

  const [hospitalsloading, hospitalList] = useSelector(selectHospitals);
  const [dashboardUsersLoading, dashboardUsers] = useSelector(selectDashboardUsers);

  const hospitalIndex = useMemo(
    () =>
      hospitalList.reduce(
        (acc, hos) => ({
          ...acc,
          [hos.uuid]: hos,
        }),
        {},
      ),
    [hospitalList],
  );

  const loading = hospitalsloading || dashboardUsersLoading;

  const [searchResults, searchProps] = useSearch(dashboardUsers, filterFunc);

  const columns = useMemo(() => createColumns(t, hospitalIndex), [t, hospitalIndex]);

  return (
    <Card.Full className="dashboard-user-list-card" noPadding>
      <div className="search-container">
        {showTitle ? <h3>{t('cards:DocumentList.title')}</h3> : null}
        <Input.Search placeholder={t('common:search')} {...searchProps} />
      </div>
      <Table
        columns={columns}
        dataSource={searchResults}
        loading={loading}
        pagination={{ position: 'bottom' }}
        rowKey="id"
        scroll={{ x: 1000 }}
        onRow={({ id }) => ({
          onClick: () => history.push(`/administration/dashboard-users/${id}`),
        })}
      />
    </Card.Full>
  );
};

export default AppUserListCard;
