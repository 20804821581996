import React, { useEffect } from 'react';
import { string } from 'prop-types';
import { Menu } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser /* faMobile */ } from '@fortawesome/pro-regular-svg-icons';
import SubNavMenuContainer from '@components/SubNavMenuContainer';
import SubNavMenu from '@components/SubNavMenu';
import { usePageActions } from '@hooks';
import { fetchAppUsers } from '@redux/appUsers/actions';
import { selectAppUser } from '@redux/appUsers/reducers';
import { contentTypes } from '@utils/contentTypes';
import AppUserOverviewTab from './AppUserOverviewTab';
import SurveySubmissions from './SurveySubmissions';
// import TermsAndConditions from './TermsAndConditions';
// import PrivacyPolicy from './PrivacyPolicy';
// import AppUserAppAccessTab from './AppUserAppAccessTab';
import { getMatchTypeWithParams } from '../../propTypes';

const routes = {
  edit: 'edit',
  overview: 'overview',
  forms: 'forms',
  access: 'access',
  termsAndConditions: 'terms-and-conditions',
  privacyPolicy: 'privacy-policy',
  surveys: 'surveys',
};

function AppUserDetails({ history, match }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    params: { id },
  } = match;

  useEffect(() => {
    dispatch(fetchAppUsers());
  }, [dispatch, id]);

  const [, appUser] = useSelector(selectAppUser(id));
  const { setTitle } = usePageActions({ showBack: true });

  useEffect(() => {
    setTitle(appUser && `${appUser.firstName}`);
  }, [appUser, setTitle]);

  return (
    <Switch>
      <SubNavMenuContainer
        menu={
          <SubNavMenu>
            <Menu.Item key={routes.overview}>
              <FontAwesomeIcon icon={faUser} className="tab-item-icon" />
              {t('patients:IndividualDetail.tabs.overview')}
            </Menu.Item>
            <Menu.Item key={routes.surveys}>
              <FontAwesomeIcon icon={contentTypes.FORM.icon} className="tab-item-icon" />
              {t('patients:IndividualDetail.tabs.surveys')}
            </Menu.Item>
            {/* <Menu.ItemGroup
              key="app-details-group"
              title={
                <span>
                  <FontAwesomeIcon icon={faMobile} className="tab-item-icon" />
                  <span>{t('patients:IndividualDetail.tabs.appDetails')}</span>
                </span>
              }
            >
              <Menu.Item key={routes.access}>
                {t('patients:IndividualDetail.tabs.appAccess')}
              </Menu.Item>
              <Menu.Item key={routes.termsAndConditions}>
                {t('patients:IndividualDetail.tabs.terms')}
              </Menu.Item>
              <Menu.Item key={routes.privacyPolicy}>
                {t('patients:IndividualDetail.tabs.privacyPolicy')}
              </Menu.Item>
            </Menu.ItemGroup> */}
          </SubNavMenu>
        }
      >
        <Switch>
          <Route path={`${match.path}/${routes.overview}`} component={AppUserOverviewTab} />
          <Route path={`${match.path}/${routes.surveys}`} component={SurveySubmissions} />
          {/* <Route path={`${match.path}/${routes.access}`} component={AppUserAppAccessTab} />
          <Route
            path={`${match.path}/${routes.termsAndConditions}`}
            component={TermsAndConditions}
          />
          <Route path={`${match.path}/${routes.privacyPolicy}`} component={PrivacyPolicy} /> */}
          <Route path={match.path} render={() => <Redirect to={`${match.url}/overview`} />} />
        </Switch>
      </SubNavMenuContainer>
    </Switch>
  );
}

AppUserDetails.propTypes = {
  match: getMatchTypeWithParams({
    id: string.isRequired,
    activeTab: string,
    detailId: string,
  }).isRequired,
};

AppUserDetails.defaultProps = {
  activeTab: null,
  detailId: null,
};

export default AppUserDetails;
