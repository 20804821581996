import { put, call, getContext, select } from 'redux-saga/effects';
import { message } from 'antd';
import { v4 as uuid } from 'uuid';
import createPipClient, { PIPObject } from '@api/pipClient';
import { appToken } from 'settings';
import { IFormSubmissionRaw, IFormSubmission } from './../types';
import doCreateUISAdminClient from '../../doCreateUISAdminClient';
import i18n from '../../../i18n';
import { createRegistrationCode, mapUISUserToAppUser } from '../utils';
import {
  inviteAppUserSuccess,
  IInviteAppUser,
  ISubmitFormAsAppUser,
  fetchAppUsers,
  submitFormAsAppUserSuccess,
  IUpdateAppUser,
  updateAppUserSuccess,
  updateAppUserFailed,
} from '../actions';
import { selectAppUser } from '../reducers';
import { FORM, QUESTIONNAIRE, TASK } from '@utils/contentTypes';

export function* doInviteAppUser({ payload: { appUser, journeys } }: IInviteAppUser) {
  const history = yield getContext('history');
  const dashboardUserProfile: {
    hospitalId?: string;
  } = yield select(state => state.login.user.profile);

  try {
    const appUserProfile = {
      ...appUser,
      hospitalId: dashboardUserProfile.hospitalId,
    };

    const uisClient = yield call(doCreateUISAdminClient);

    // create App User in UIS
    const newUisUser = yield call(uisClient.createAppUser, appUserProfile);
    const newAppUser = mapUISUserToAppUser(newUisUser);

    yield call(uisClient.createUserRegistrationCode, newAppUser.url, createRegistrationCode());

    yield put(inviteAppUserSuccess(newAppUser.uuid, newAppUser, journeys));

    yield call(history.push, '/app-users');
  } catch (err) {
    console.error(err);
  }
}

export function* doDeleteAppUsers() {
  yield call(message.success, i18n.t('patients:deleteConfirmation'));
}

export function* doSubmitAppUserForm({
  payload: { formId, formData, appUserUISId, type },
}: ISubmitFormAsAppUser) {
  try {
    yield put(fetchAppUsers());

    const [, appUser] = yield select(selectAppUser(appUserUISId));

    const tokens = yield getContext('tokens');
    const pipClient = yield call(createPipClient, tokens);

    const appUserResponse: any = yield call(pipClient.getAppUser, appUser.ids.pip);
    const pipAppuser = appUserResponse.results[0];

    // submit new form data as app user
    yield call(
      pipClient.createObject,
      `${appToken}-form-${formId}-data`,
      formData,
      pipAppuser.uuid,
    );

    // fetch describe_versions for form
    const formSchemaObjectType = `${appToken}-form-${formId}`;
    const formDataObjectType = `${formSchemaObjectType}-data`;
    const versions = yield call(
      pipClient.describeVersionsForType,
      `${appToken}-form-${formId}`,
      pipAppuser.uuid,
      [formDataObjectType],
    );

    const newVersion = {
      versions,
      created: new Date().toISOString(),
      uuid: uuid(),
      'form-object-type': `${appToken}-form-${formId}`,
    };

    // submit new form submission as app user
    const [latest]: [
      PIPObject<{
        submissions: IFormSubmissionRaw[];
      }>,
    ] = yield call(
      pipClient.getObjectsForType,
      `${appToken}-form-submissions`,
      'latest',
      pipAppuser.uuid,
    );
    const latestSubmissions = latest && latest.json ? latest.json.submissions : [];
    const newSubmissions = [...latestSubmissions, newVersion] as IFormSubmissionRaw[];

    yield call(
      pipClient.createObject,
      `${appToken}-form-submissions`,
      { submissions: newSubmissions },
      pipAppuser.uuid,
    );

    switch (type) {
      case FORM:
        yield call(message.success, i18n.t('patients:IndividualDetail.submitFormSuccess'));
        break;
      case QUESTIONNAIRE:
        yield call(message.success, i18n.t('patients:IndividualDetail.submitQuestionnaireSuccess'));
        break;
      case TASK:
        yield call(message.success, i18n.t('patients:IndividualDetail.submitTaskSuccess'));
        break;
    }

    yield put(
      submitFormAsAppUserSuccess(
        appUserUISId,
        newSubmissions.map(sub => ({
          formObjectType: sub['form-object-type'],
          created: sub.created,
          versions: sub.versions,
          uuid: sub.uuid,
        })) as IFormSubmission[],
      ),
    );
  } catch (err) {
    console.error(err);

    switch (type) {
      case FORM:
        yield call(message.warning, i18n.t('patients:IndividualDetail.submitFormFailed'));
        break;
      case QUESTIONNAIRE:
        yield call(message.warning, i18n.t('patients:IndividualDetail.submitQuestionnaireFailed'));
        break;
      case TASK:
        yield call(message.warning, i18n.t('patients:IndividualDetail.submitTaskFailed'));
        break;
    }
  }
}

export function* doUpdateAppUser({ payload: { appUserId, profile, navigate } }: IUpdateAppUser) {
  try {
    const uisClient = yield call(doCreateUISAdminClient);

    const updatedUisUser = yield call(uisClient.updateAppUser, appUserId, profile);

    const updatedAppUser = mapUISUserToAppUser(updatedUisUser);

    yield put(updateAppUserSuccess(appUserId, updatedAppUser));

    if (navigate) {
      const history = yield getContext('history');
      yield call(history.goBack);
      yield call(message.success, i18n.t('patients:EditPatient.successMessage'));
    }
  } catch (err) {
    console.error(err);
    yield call(message.warning, i18n.t('patients:EditPatient.failedMessage'));
    yield put(updateAppUserFailed());
  }
}
