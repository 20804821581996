import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCommentAltLines,
  faMusic,
  faTrophy,
  faUser,
  faUsersCog,
} from '@fortawesome/pro-regular-svg-icons';
import { ColumnGroup, Column } from '@cards/Card';
import { useHasPermissions } from '@authorisation/hooks';
import { usePageActions, useTitleUpdate } from '@hooks';
import LinkCard from '@cards/Link';
import { Permissions } from '@authorisation/constants';

export default () => {
  const { t } = useTranslation();
  const history = useHistory();
  const perms = useHasPermissions();

  const { setTitle } = usePageActions({
    title: t('home:header'),
  });
  useTitleUpdate(setTitle, 'home:header');
  document.title = t('home:header');

  return (
    <ColumnGroup>
      <Column>
        {/* {perms[Permissions.AdministerHospital] ? <HospitalAdminOnboarding /> : null} */}
        {perms[Permissions.ViewPatients] ? (
          <LinkCard
            title={t('home:links.patients.title')}
            description={t('home:links.patients.description')}
            onContentClick={() => history.push('/app-users')}
            showButton={Boolean(perms[Permissions.ManagePatients])}
            buttonIcon={<FontAwesomeIcon icon={faUser} />}
            buttonLabel={t('home:links.patients.buttonLabel')}
            buttonClick={() => history.push('/app-users')}
          />
        ) : null}
        {perms[Permissions.ViewMessages] ? (
          <LinkCard
            title={t('home:links.competition.title')}
            description={t('home:links.competition.description')}
            onContentClick={() => history.push('/competitions')}
            showButton={Boolean(perms[Permissions.ViewMessages])}
            buttonIcon={<FontAwesomeIcon icon={faTrophy} />}
            buttonLabel={t('home:links.competition.buttonLabel')}
            buttonClick={() => history.push('/competitions')}
          />
        ) : null}
        {perms[Permissions.ViewDashboardUsers] ? (
          <LinkCard
            title={t('home:links.dashboardUsers.title')}
            description={t('home:links.dashboardUsers.description')}
            onContentClick={() => history.push('/administration/dashboard-users')}
            buttonIcon={<FontAwesomeIcon icon={faUsersCog} />}
            buttonLabel={t('home:links.dashboardUsers.buttonLabel')}
            buttonClick={() => history.push('/administration/dashboard-users/new')}
          />
        ) : null}
      </Column>
      <Column>
        {perms[Permissions.ViewMessages] ? (
          <LinkCard
            title={t('home:links.messages.title')}
            description={t('home:links.messages.description')}
            onContentClick={() => history.push('/messages')}
            showButton={Boolean(perms[Permissions.ViewMessages])}
            buttonIcon={<FontAwesomeIcon icon={faCommentAltLines} />}
            buttonLabel={t('home:links.messages.buttonLabel')}
            buttonClick={() => history.push('/messages/new')}
          />
        ) : null}
        {perms[Permissions.ViewMessages] ? (
          <LinkCard
            title={t('home:links.requests.title')}
            description={t('home:links.requests.description')}
            onContentClick={() => history.push('/requests')}
            showButton={Boolean(perms[Permissions.ViewMessages])}
            buttonIcon={<FontAwesomeIcon icon={faMusic} />}
            buttonLabel={t('home:links.requests.buttonLabel')}
            buttonClick={() => history.push('/requests')}
          />
        ) : null}
      </Column>
    </ColumnGroup>
  );
};
