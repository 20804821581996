import { select, takeEvery, put, take, getContext, call } from '@redux-saga/core/effects';
import { v4 as uuid } from 'uuid';
import { message } from 'antd';
import { LOAD_RADIO_STATIONS_SUCCESS } from '@redux/stations/types';
import { selectFirstRadioStation } from '@redux/stations/reducers';
import { fetchStations } from '@redux/stations/actions';
import pipClient from '@api/pipClient';
import { envName, appToken } from 'settings';
import i18n from '../../i18n';
import {
  FETCH_RLP_HOSPITALS,
  loadRLPHospitals,
  LOAD_RLP_HOSPITALS,
  rlpHospitalsLoaded,
  CREATE_RLP_HOSPITAL,
  ICreateRLPHospital,
  createRLPHospitalSuccess,
  EDIT_RLP_HOSPITAL,
  IEditRLPHospital,
  Hospital,
  editRLPHospitalSuccess,
  RLP_HOSPITALS_LOADED,
  IDeleteRLPHospital,
  deleteRLPHospitalSuccess,
  DELETE_RLP_HOSPITAL,
} from './actions';
import { hasLoadedHospitals, selectHospitals } from './reducers';
import { APP_USERS_LOADED, UPDATE_APP_USER_SUCCESS } from '@redux/appUsers/types';
import { IAppUsersLoaded, updateAppUser } from '@redux/appUsers/actions';
import { buildProfileFromAppUser } from '@redux/appUsers/utils';

const RLP_HOSPITALS_PIP_OBJECT = `${appToken}-lolliplay-${envName}-subhospitals`;

export default function*() {
  yield takeEvery(FETCH_RLP_HOSPITALS, doFetchRLPHospitals);
  yield takeEvery(LOAD_RLP_HOSPITALS, doLoadRLPHospitals);
  yield takeEvery(CREATE_RLP_HOSPITAL, doCreateRLPHospital);
  yield takeEvery(EDIT_RLP_HOSPITAL, doEditRLPHospital);
  yield takeEvery(DELETE_RLP_HOSPITAL, doDeleteRLPHospital);
  yield takeEvery(APP_USERS_LOADED, doMigrateAppUsers);
}

function* doFetchRLPHospitals() {
  const existing: boolean = yield select(hasLoadedHospitals);
  if (existing) {
    return;
  }
  yield put(loadRLPHospitals());
}

function* doLoadRLPHospitals() {
  const tokens = yield getContext('tokens');
  const pip = yield call(pipClient, tokens);

  const result = (yield call(pip.getLatestObjectsForUsers, RLP_HOSPITALS_PIP_OBJECT))[0];

  yield put(rlpHospitalsLoaded(result.json));
}

function* doCreateRLPHospital({ payload: { hospital } }: ICreateRLPHospital) {
  let [, radioStation] = yield select(selectFirstRadioStation);
  if (!radioStation) {
    yield put(fetchStations());
    yield take(LOAD_RADIO_STATIONS_SUCCESS);
  }
  [, radioStation] = yield select(selectFirstRadioStation);
  const finalHospital = {
    ...hospital,
    id: uuid(),
    radioStationId: radioStation.id,
  };

  const [, allHospitals] = yield select(state => selectHospitals(state, true));

  const finalData = [...allHospitals, finalHospital];

  const tokens = yield getContext('tokens');
  const pip = yield call(pipClient, tokens);

  yield call(pip.createObject, RLP_HOSPITALS_PIP_OBJECT, finalData);
  yield put(createRLPHospitalSuccess(finalHospital));

  const history = yield getContext('history');
  yield call(history.goBack.bind(history));

  yield call(message.success, i18n.t('rlp-hospitals:create:success'));
}

function* doEditRLPHospital({ payload: { id, data } }: IEditRLPHospital) {
  const [, hospitals] = yield select(state => selectHospitals(state, true));
  const updated = hospitals.map((h: Hospital) => (h.id === id ? { ...h, ...data } : h));

  const tokens = yield getContext('tokens');
  const pip = yield call(pipClient, tokens);

  yield call(pip.createObject, RLP_HOSPITALS_PIP_OBJECT, updated);
  // This is a bit awkward.
  yield put(
    editRLPHospitalSuccess(
      id,
      updated.find((h: Hospital) => h.id === id),
    ),
  );

  const history = yield getContext('history');
  yield call(history.goBack.bind(history));

  yield call(message.success, i18n.t('rlp-hospitals:edit:success'));
}

function* doDeleteRLPHospital({ payload: { id } }: IDeleteRLPHospital) {
  const [, hospitals] = yield select(state => selectHospitals(state, true));
  const hospital = hospitals.find((h: Hospital) => h.id === id);
  const updated = hospitals.map((h: Hospital) => (h.id === id ? { ...h, isDeleted: true } : h));

  const tokens = yield getContext('tokens');
  const pip = yield call(pipClient, tokens);
  yield call(pip.createObject, RLP_HOSPITALS_PIP_OBJECT, updated);

  const history = yield getContext('history');
  yield call(history.goBack.bind(history));

  yield put(deleteRLPHospitalSuccess(id));

  yield call(
    message.success,
    i18n.t('rlp-hospitals:delete:success', { hospitalName: hospital.name }),
  );
}

function* doMigrateAppUsers({ payload: { appUsers } }: IAppUsersLoaded) {
  const existing: boolean = yield select(hasLoadedHospitals);
  if (!existing) {
    yield put(loadRLPHospitals());
    yield take(RLP_HOSPITALS_LOADED);
  }
  const [, hospitals] = yield select(selectHospitals);
  const hospital = hospitals[0]?.id;
  for (const appUser of appUsers.filter(au => au.hospital === 'Unknown')) {
    const newProfile = buildProfileFromAppUser({ ...appUser, hospital });
    yield put(updateAppUser(appUser.id, newProfile, false));
    yield take(UPDATE_APP_USER_SUCCESS);
  }
}
