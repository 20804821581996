import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Modal, Spin } from 'antd';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import IconButton from '@components/IconButton';
import { SHOUT_OUT, SONG_REQUEST } from '@constants';
import { getAppUserIdForPipUuid } from '@surveys/redux/actions';
import { selectAppUserByPipUuid } from '@surveys/redux/reducers';
import { selectHospital } from '@rlpHospitals/redux/reducers';

function AppUserHospital({ pipUuid }) {
  const dispatch = useDispatch();
  const [loading$1, appUser] = useSelector(selectAppUserByPipUuid(pipUuid));
  const [loading$2, hospital] = useSelector(selectHospital(appUser?.hospital));

  const loading = loading$1 === 'loading' || loading$1 === 'initial' || loading$2;

  useEffect(() => {
    if (loading$1 === 'initial') {
      dispatch(getAppUserIdForPipUuid(pipUuid));
    }
  }, [appUser, dispatch, loading$1, pipUuid]);

  return loading ? <Spin /> : <div>{hospital?.name}</div>;
}

function AppUserName({ pipUuid }) {
  const dispatch = useDispatch();
  const [loading, appUser] = useSelector(selectAppUserByPipUuid(pipUuid));
  debugger;

  useEffect(() => {
    if (loading === 'initial') {
      dispatch(getAppUserIdForPipUuid(pipUuid));
    }
  }, [appUser, dispatch, loading, pipUuid]);

  return loading === 'loading' || loading === 'initial' ? (
    <Spin />
  ) : (
    <div>{appUser?.firstName}</div>
  );
}

export default (t, hospitals, selectHospitalIdForRequest, deleteRequest) => [
  {
    dataIndex: 'created',
    defaultSortOrder: 'descend',
    key: 'date',
    title: t('cards:RequestsList.columns.date'),
    width: 200,
    render: date => moment(date).format('l LT'),
    sorter: (a, b) => new Date(a.created) - new Date(b.created),
  },
  {
    key: 'firstName',
    title: t(`cards:RequestsList.columns.firstName`),
    render: record => <AppUserName pipUuid={record.pipUuid} />,
  },
  {
    key: 'hospital',
    title: t(`cards:PatientList.columns.hospital`),
    render: record => <AppUserHospital pipUuid={record.pipUuid} />,
    filters: hospitals.map(h => ({ value: h.id, text: h.name })),
    onFilter: (value, record) => value === selectHospitalIdForRequest(record),
  },
  {
    dataIndex: 'request',
    width: 150,
    filters: [
      { text: t('common:contentTypes.singular.shoutOut'), value: SHOUT_OUT },
      { text: t('common:contentTypes.singular.request'), value: SONG_REQUEST },
    ],
    key: 'request',
    title: t(`cards:RequestsList.columns.request`),
    onFilter: (value, record) => value === record.request,
    render: requestType => {
      switch (requestType) {
        case SHOUT_OUT:
          return t('common:contentTypes.singular.shoutOut');
        case SONG_REQUEST:
          return t('common:contentTypes.singular.request');
        default:
          return null;
      }
    },
    sorter: (a, b) => (a.request || '').localeCompare(b.request || ''),
  },
  {
    dataIndex: 'name',
    width: 120,
    key: 'name',
    title: t(`cards:RequestsList.columns.name`),
    sorter: (a, b) => (a.name || '').localeCompare(b.name || ''),
  },
  {
    dataIndex: 'content',
    key: 'details',
    title: t(`cards:RequestsList.columns.details`),
    sorter: (a, b) => (a.content || '').localeCompare(b.content || ''),
  },
  {
    fixed: 'right',
    key: 'delete',
    render: record => (
      <IconButton
        icon={faTrashAlt}
        tooltip={t('common:buttons.delete')}
        onClick={() =>
          Modal.confirm({
            title: 'Do you want to delete this request?',
            onCancel: () => {},
            onOk: () => {
              deleteRequest(record.pipUuid, record.created);
            },
          })
        }
      />
    ),
  },
];
