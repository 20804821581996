import React from 'react';
import { bool, func, shape } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ColumnGroup, Column } from '@cards/Card';
import Wizard from '@components/Wizard';
import WizardItem from '@components/Wizard/Item';
import Details from './Details';
import Streams from './Streams';
import ReviewDetails from './Review/Details';
import './style.less';

function StationWizard({ editing, initialData, loading, submitting, onCompleted }) {
  const { t } = useTranslation();
  return (
    <Wizard
      initialData={initialData}
      loading={loading}
      submitting={submitting}
      onCompleted={onCompleted}
    >
      <WizardItem stepKey="details" title={t('stations:Wizard.steps.details')}>
        <ColumnGroup>
          <Column>
            <Details />
          </Column>
          <Column></Column>
        </ColumnGroup>
      </WizardItem>
      <WizardItem stepKey="streams" title={t('stations:Wizard.steps.streams')}>
        <Streams />
      </WizardItem>
      <WizardItem stepKey="review" title={t('stations:Wizard.steps.review')}>
        <ColumnGroup>
          <Column>
            <ReviewDetails />
          </Column>
          <Column></Column>
        </ColumnGroup>
      </WizardItem>
    </Wizard>
  );
}

StationWizard.propTypes = {
  initialData: shape({}),
  loading: bool,
  submitting: bool,
  onCompleted: func.isRequired,
};

StationWizard.defaultProps = {
  loading: false,
  submitting: false,
};

export default StationWizard;
