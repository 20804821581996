import { combineReducers } from 'redux';
import {
  IStation,
  LOAD_RADIO_STATIONS,
  LOAD_RADIO_STATIONS_SUCCESS,
  LOAD_RADIO_STATIONS_FAILED,
  UPDATE_STATION_SUCCESS,
} from './types';
import {
  IUpdateStationsSuccess,
  ILoadRadioStations,
  ILoadRadioStationsSuccess,
  ILoadRadioStationsFailed,
} from './actions';

export interface IStationState {
  stations: {
    stations: IStation[];
    loading: boolean;
  };
}

const stations = (
  state: IStation[] = [],
  action: IUpdateStationsSuccess | ILoadRadioStationsSuccess,
): IStation[] => {
  switch (action.type) {
    case LOAD_RADIO_STATIONS_SUCCESS:
    case UPDATE_STATION_SUCCESS:
      return action.payload.stations;
    default:
      return state;
  }
};

const loading = (
  state: boolean = false,
  action: ILoadRadioStations | ILoadRadioStationsSuccess | ILoadRadioStationsFailed,
): boolean => {
  switch (action.type) {
    case LOAD_RADIO_STATIONS:
      return true;
    case LOAD_RADIO_STATIONS_SUCCESS:
    case LOAD_RADIO_STATIONS_FAILED:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  stations,
  loading,
});

export const selectFirstRadioStation = (state: IStationState): [boolean, IStation | undefined] => [
  state.stations.loading,
  state.stations.stations[0],
];

export const selectRadioStation = (id: string) => (
  state: IStationState,
): [boolean, IStation | undefined] => [
  state.stations.loading,
  state.stations.stations.find(st => st.id === id),
];

export const selectRadioStations = (state: IStationState): [boolean, IStation[]] => [
  state.stations.loading,
  state.stations.stations,
];
