import React from 'react';
import { Switch, Route } from 'react-router-dom';
import AppUserList from './AppUserList';
import AppUserDetail from './AppUserDetail';

import './style.less';

export default ({ match }) => {
  return (
    <Switch>
      <Route exact path={match.path} component={AppUserList} />
      <Route path={`${match.path}/:id`} component={AppUserDetail} />
    </Switch>
  );
};
