import React, { useEffect /*, useState */ } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { useTranslation } from 'react-i18next';
import { ColumnGroup, Column } from '@cards/Card';
import SurveyDetailsCard from '@surveys/cards/Details';
import SurveyPreviewCard from '@surveys/cards/Preview';
// import IconButton from '@components/IconButton';
// import { SubmitSurveyModals, VISIBLE_MODAL } from '@surveys/cards/Submit';
import { selectCurrentSurvey } from '@surveys/redux/reducers';
import { fetchSurveys } from '@surveys/redux/actions';
// import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { matchType } from '../../propTypes';

const AppUserSurveySubmissionDetails = ({ match }) => {
  // const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id: appUserId } = match.params;

  const [loading, survey] = useSelector(selectCurrentSurvey);

  useEffect(() => {
    dispatch(fetchSurveys());
  }, [dispatch]);

  // const [visibleModal, setVisibleModal] = useState();

  return (
    <>
      <ColumnGroup>
        <Column>
          <SurveyDetailsCard
            extra={
              [
                // <IconButton
                //   disabled={!survey}
                //   key="add-survey-submission-button"
                //   tooltip={
                //     survey
                //       ? t('cards:AppUserSurveyList.submitTooltip')
                //       : t('cards:AppUserSurveyList.noActive')
                //   }
                //   icon={faPlus}
                //   onClick={() => setVisibleModal(VISIBLE_MODAL.SUBMIT)}
                //   tooltipPlacement="right"
                // />,
              ]
            }
            loading={loading}
            surveyId={survey?.uuid}
          />
        </Column>
        <Column>
          {survey ? (
            <SurveyPreviewCard
              surveyId={survey?.uuid}
              appUserId={appUserId} /* submissionId={submissionId} */
            />
          ) : null}
        </Column>
      </ColumnGroup>
      {/* <SubmitSurveyModals
        appUserId={appUserId}
        visibleModal={visibleModal}
        setVisibleModal={setVisibleModal}
        selectedSurvey={survey}
        setSelectedSurvey={() => {}}
      /> */}
    </>
  );
};

AppUserSurveySubmissionDetails.propTypes = {
  match: matchType.isRequired,
};

export default AppUserSurveySubmissionDetails;
