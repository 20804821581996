import React from 'react';
import { Route, Switch } from 'react-router-dom';
import SurveySubmissionDetails from './SurveySubmissionDetails';

const SurveySubmissions = ({ match }) => {
  return (
    <Switch>
      <Route path={`${match.path}`} component={SurveySubmissionDetails} />
    </Switch>
  );
};

export default SurveySubmissions;
