import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import {
  fetchSurveys,
  fetchAllSurveySubmissions,
  navigateToAppUserSurveys,
} from '@surveys/redux/actions';
import {
  selectAppUserSurveySubmissionsList,
  selectAppUserByPipUuid,
} from '@surveys/redux/reducers';
import { fetchAppUsers } from '@redux/appUsers/actions';
import { fetchRLPHospitals } from '@rlpHospitals/redux/actions';
import SearchableTable from '@components/SearchableTable';
import { selectHospitals } from '@rlpHospitals/redux/reducers';
import createColumns from './createColumns';
import './style.less';

const filterFunc = (value, comparator) => {
  return comparator(value.name) || comparator(value.description);
};

function ListCard({ showTitle }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [, hospitals] = useSelector(selectHospitals);

  const state = useSelector(state => state);

  const columns = useMemo(() => {
    const selectHospitalIdForSurvey = survey => {
      const [loading$1, appUser] = selectAppUserByPipUuid(survey.pipUuid)(state);
      const hospital = appUser?.hospital;

      if (loading$1 === 'initial' || loading$1 === 'loading') return null;
      return hospital;
    };

    return createColumns(t, hospitals, selectHospitalIdForSurvey);
  }, [t, hospitals, state]);
  const [loading, surveySubmissions] = useSelector(selectAppUserSurveySubmissionsList);

  useEffect(() => {
    dispatch(fetchAppUsers());
    dispatch(fetchSurveys());
    dispatch(fetchAllSurveySubmissions());
    dispatch(fetchRLPHospitals());
  }, [dispatch]);

  return (
    <SearchableTable
      className="form-list-card"
      columns={columns}
      dataSource={surveySubmissions}
      extra={
        <>
          <Button
            loading={loading}
            type="primary"
            onClick={() => dispatch(fetchAllSurveySubmissions())}
          >
            {t('common:buttons.refresh')}
          </Button>
        </>
      }
      filterFunc={filterFunc}
      hasRoutedPagination
      loading={loading}
      locale={{ emptyText: 'No Submissions' }}
      rowKey="pipUuid"
      title={showTitle ? <h3>{t('cards:SurveyList.title')}</h3> : null}
      onRowClick={({ pipUuid }) => dispatch(navigateToAppUserSurveys(pipUuid))}
    />
  );
}

export default ListCard;
