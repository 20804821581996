import React, { createContext, useCallback, useEffect, useState } from 'react';
import { arrayOf, bool, func, node, oneOfType, shape, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Form, Spin, Modal } from 'antd';
import { usePageActions } from '@hooks';
import WizardMenu from './Menu';
import { antFormType } from '../../propTypes';
import './style.less';

export const WizardContext = createContext(null);

const doExitWizard = (t, history, title) => {
  Modal.confirm({
    title: title || t('common:Wizard.exitConfirmModal.defaultTitle'),
    content: t('common:Wizard.exitConfirmModal.content'),
    okText: t('common:Wizard.exitConfirmModal.okText'),
    cancelText: t('common:Wizard.exitConfirmModal.cancelText'),
    onOk: history.goBack,
  });
};

function Wizard({ children, form, initialData, loading, submitting, onCompleted, exitModalTitle }) {
  const [currentStep, setCurrentStep] = useState(0);
  const [stepKeys, setStepNames] = useState([]);
  const [steps, setSteps] = useState([]);
  const [childrenArray, setChildrenArray] = useState([]);
  const [formData, setFormData] = useState({ ...initialData });

  const { t } = useTranslation();
  const history = useHistory();

  usePageActions({
    showClose: true,
    onClose: () => doExitWizard(t, history, exitModalTitle),
  });

  useEffect(() => {
    setFormData(initialData);
  }, [initialData]);

  useEffect(() => {
    const childrenArray = React.Children.toArray(children);
    const { stepKeys, wizardSteps } = childrenArray.reduce(
      (prev, { props: { children, ...props } }, index) => ({
        stepKeys: [...prev.stepKeys, props.stepKey || index],
        wizardSteps: [...prev.wizardSteps, props],
      }),
      { stepKeys: [], wizardSteps: [] },
    );
    setChildrenArray(childrenArray);
    setStepNames(stepKeys);
    setSteps(wizardSteps);
  }, [children]);

  const onNextStep = useCallback(() => {
    if (currentStep === steps.length - 1) {
      return onCompleted(formData);
    }
    form.validateFieldsAndScroll((err, data) => {
      if (err) return;
      if (Object.entries(data).length !== 0) {
        setFormData({
          ...formData,
          [stepKeys[currentStep]]: { ...formData[stepKeys[currentStep]], ...data },
        });
      }

      setCurrentStep(currentStep + 1);
    });
  }, [currentStep, setCurrentStep, formData, stepKeys, setFormData, form, onCompleted, steps]);

  const onPreviousStep = useCallback(() => {
    const data = { ...form.getFieldsValue() };

    if (Object.entries(data).length !== 0) {
      setFormData({
        ...formData,
        [stepKeys[currentStep]]: { ...formData[stepKeys[currentStep]], ...data },
      });
    }
    setCurrentStep(currentStep - 1);
  }, [currentStep, form, formData, stepKeys]);

  return (
    <WizardContext.Provider
      value={{ currentStepName: stepKeys[currentStep], form, formData, setFormData }}
    >
      <div className="wizard-container">
        <WizardMenu
          currentStep={currentStep}
          loading={loading}
          submitting={submitting}
          steps={steps}
          onCompleted={onCompleted}
          onNextStep={onNextStep}
          onPreviousStep={onPreviousStep}
        />
        <Spin spinning={loading}>
          <div className="wizard-content">{childrenArray[currentStep]}</div>
        </Spin>
      </div>
    </WizardContext.Provider>
  );
}

Wizard.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
  form: antFormType.isRequired,
  initialData: shape({}),
  loading: bool,
  submitting: bool,
  onCompleted: func.isRequired,
  exitModalTitle: string,
};

Wizard.defaultProps = {
  initialData: {},
  loading: false,
  exitModalTitle: undefined,
};

export default Form.create()(Wizard);
