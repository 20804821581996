import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usePageActions } from '@hooks';
import { Column, ColumnGroup } from '@cards/Card';
import Edit from '@rlpHospitals/cards/Edit';
import { createRLPHospital } from '@rlpHospitals/redux/actions';

export default () => {
  const dispatch = useDispatch();

  const isSubmitting = useSelector(state => state.rlpHospitals.creating);

  const onSubmit = useCallback(data => dispatch(createRLPHospital(data)), [dispatch]);

  usePageActions({ title: 'New Hospital' });

  return (
    <ColumnGroup>
      <Column size="half">
        <Edit isSubmitting={isSubmitting} onSubmit={onSubmit} />
      </Column>
    </ColumnGroup>
  );
};
