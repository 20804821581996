import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { string, object } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Empty } from 'antd';
import DetailForm from '@components/DetailForm';
import DetailRow from '@components/DetailRow';
import { fetchAppUsers } from '@redux/appUsers/actions';
import { selectAppUser } from '@redux/appUsers/reducers';
import { Card } from '../../Card';
import { useHasPermissions } from '@authorisation/hooks';
import { Permissions } from '@authorisation/constants';
import './styles.less';
import { fetchRLPHospitals } from '@rlpHospitals/redux/actions';
import { selectHospital } from '@rlpHospitals/redux/reducers';

const AppUserDetailsCard = ({ appUser, title, loading, ...props }) => {
  const { t } = useTranslation();
  const { [Permissions.ViewPatients]: canViewPII } = useHasPermissions(Permissions.ViewPatients);
  const obfuscatePIValue = '******';

  return (
    <Card.Half
      className="app-user-details-card"
      noPadding
      loading={loading}
      title={t(title)}
      {...props}
    >
      {appUser === undefined ? (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      ) : (
        <DetailForm>
          <DetailRow
            label={t('cards:PatientDetails.labels.fullName')}
            value={canViewPII ? appUser.firstName : obfuscatePIValue}
          />

          <DetailRow
            label={t('cards:PatientDetails.labels.email')}
            value={canViewPII ? appUser.email : obfuscatePIValue}
          />

          <DetailRow
            label={t('cards:PatientDetails.labels.mobile')}
            value={canViewPII ? appUser.phone : obfuscatePIValue}
          />

          <DetailRow
            label={t('cards:PatientDetails.labels.age')}
            value={canViewPII ? appUser.age : obfuscatePIValue}
          />

          <DetailRow
            label={t('cards:PatientDetails.labels.hospital')}
            value={canViewPII ? appUser.hospital?.name : obfuscatePIValue}
          />

          <DetailRow
            label={t('cards:PatientDetails.labels.ward')}
            value={canViewPII ? appUser.roomNumber : obfuscatePIValue}
          />
        </DetailForm>
      )}
    </Card.Half>
  );
};

AppUserDetailsCard.propTypes = {
  title: string,
  appUser: object,
};

AppUserDetailsCard.defaultProps = {
  title: 'cards:PatientDetails.title',
};

const AppUserDetailByIdCard = ({ appUserId, ...props }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAppUsers());
    dispatch(fetchRLPHospitals());
  }, [dispatch]);

  const [loading$1, appUser] = useSelector(selectAppUser(appUserId));
  const [loading$2, hospital] = useSelector(selectHospital(appUser?.hospital));

  const loading = loading$1 || loading$2;

  return <AppUserDetailsCard appUser={{ ...appUser, hospital }} loading={loading} {...props} />;
};

export { AppUserDetailsCard };

export default AppUserDetailByIdCard;
