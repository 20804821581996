import { combineReducers } from 'redux';
import {
  ACTIVATE_APP_USER,
  APP_USER_ACTIVATION_FAILED,
  APP_USER_DEACTIVATION_FAILED,
  LOAD_APP_USERS_FORMS,
  APP_USERS_FORMS_LOADED,
  APP_USERS_LOADED,
  DEACTIVATE_APP_USER,
  DELETE_APP_USERS,
  FETCH_APP_USERS_FAILED,
  INVITE_APP_USER,
  INVITE_APP_USER_SUCCESS,
  LOAD_APP_USERS,
  IAppUsersDataState,
  IAppUsersFormsState,
  IAppUser,
  INVITE_APP_USER_FAILED,
  IFormSubmission,
  APP_USER_SUBMIT_FORM_SUCCESS,
  APP_USER_SUBMIT_FORM_FAILED,
  UPDATE_APP_USER,
  UPDATE_APP_USER_SUCCESS,
  UPDATE_APP_USER_FAILED,
  APP_USER_SUBMIT_FORM,
  RESEND_APP_USER_INVITE,
  RESEND_APP_USER_INVITE_FAILED,
  RESEND_APP_USER_INVITE_SUCCESS,
  APP_USER_ACTIVATED,
  APP_USER_DEACTIVATED,
} from './types';
import {
  IInviteAppUser,
  ILoadAppUsers,
  IFetchAppUsersFailed,
  IInviteAppUserSuccess,
  IAppUsersLoaded,
  IDeleteAppUsers,
  IActivateAppUser,
  IAppUserActivationFailed,
  IDeactivateAppUser,
  IAppUserDeactivationFailed,
  IAppUsersFormsLoaded,
  IFetchAppUsersForms,
  IInviteAppUserFailed,
  ISubmitFormAsAppUser,
  ISubmitFormAsAppUserSuccess,
  ISubmitFormAsAppUserFailed,
  ILoadAppUserForms,
  IUpdateAppUser,
  IUpdateAppUserSuccess,
  IUpdateAppUserFailed,
  IResendAppUserInvite,
  IResendAppUserInviteFailed,
  IResendAppUserInviteSuccess,
  IAppUserActivated,
  IAppUserDeactivated,
  IFetchFormsForAppUserResults,
} from './actions';

import { IForm } from '@redux/forms/types';

import { appToken } from 'settings';
import { ITemporaryFormsLoaded } from '@redux/forms/actions';
import { IState } from '@redux/reducer';

const creating = (
  state = false,
  action: IInviteAppUser | IInviteAppUserSuccess | IInviteAppUserFailed,
) => {
  switch (action.type) {
    case INVITE_APP_USER:
      return true;
    case INVITE_APP_USER_FAILED:
    case INVITE_APP_USER_SUCCESS:
      return false;
    default:
      return state;
  }
};

const latestSeenUser = (state = null, action: IAppUsersLoaded): string | null => {
  switch (action.type) {
    case APP_USERS_LOADED:
      // Convert undefined into explicit null.
      return action.payload.latestSeenUser ?? null;
    default:
      return state;
  }
};

const loading = (
  state = false,
  action: ILoadAppUsers | IFetchAppUsersFailed | IAppUsersLoaded,
): boolean => {
  switch (action.type) {
    case LOAD_APP_USERS:
      return true;
    case FETCH_APP_USERS_FAILED:
    case APP_USERS_LOADED:
      return false;
    default:
      return state;
  }
};

const resendingInvite = (
  state = false,
  action: IResendAppUserInvite | IResendAppUserInviteFailed | IResendAppUserInviteSuccess,
): boolean => {
  switch (action.type) {
    case RESEND_APP_USER_INVITE:
      return true;
    case RESEND_APP_USER_INVITE_FAILED:
    case RESEND_APP_USER_INVITE_SUCCESS:
      return false;
    default:
      return state;
  }
};

const togglingAccess = (
  state = false,
  action:
    | IActivateAppUser
    | IAppUserActivated
    | IAppUserActivationFailed
    | IDeactivateAppUser
    | IAppUserDeactivated
    | IAppUserDeactivationFailed,
) => {
  switch (action.type) {
    case ACTIVATE_APP_USER:
    case DEACTIVATE_APP_USER:
      return true;
    case APP_USER_ACTIVATED:
    case APP_USER_ACTIVATION_FAILED:
    case APP_USER_DEACTIVATED:
    case APP_USER_DEACTIVATION_FAILED:
      return false;
    default:
      return state;
  }
};

const updating = (
  state = false,
  action: IUpdateAppUser | IUpdateAppUserSuccess | IUpdateAppUserFailed,
): boolean => {
  switch (action.type) {
    case UPDATE_APP_USER:
      return true;
    case UPDATE_APP_USER_FAILED:
    case UPDATE_APP_USER_SUCCESS:
      return false;
    default:
      return state;
  }
};

const dataInitialState = {
  list: [],
  byId: {},
};

const data = (
  state: IAppUsersDataState = dataInitialState,
  action:
    | IFetchAppUsersFailed
    | IAppUsersLoaded
    | IDeleteAppUsers
    | IInviteAppUserSuccess
    | IActivateAppUser
    | IAppUserActivationFailed
    | IDeactivateAppUser
    | IAppUserDeactivationFailed
    | IUpdateAppUserSuccess,
): IAppUsersDataState => {
  switch (action.type) {
    case FETCH_APP_USERS_FAILED:
      return dataInitialState;
    case APP_USERS_LOADED:
      return {
        ...state,
        list: action.payload.appUsers.map(appUser => appUser.id),
        byId: action.payload.appUsers.reduce(
          (acc, appUser) => ({
            ...acc,
            [appUser.id]: appUser,
          }),
          {},
        ),
      };
    case DELETE_APP_USERS:
      return {
        ...state,
        list: state.list.filter(id => !action.payload.appUserIds.includes(id)),
      };

    case INVITE_APP_USER_SUCCESS:
      return {
        ...state,
        list: [action.payload.id, ...state.list],
        byId: {
          ...state.byId,
          [action.payload.id]: action.payload.appUser,
        },
      };
    case ACTIVATE_APP_USER:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.appUserId]: {
            ...state.byId[action.payload.appUserId],
            isActive: true,
          },
        },
      };
    case APP_USER_ACTIVATION_FAILED:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.appUserId]: {
            ...state.byId[action.payload.appUserId],
            isActive: false,
          },
        },
      };
    case DEACTIVATE_APP_USER:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.appUserId]: {
            ...state.byId[action.payload.appUserId],
            isActive: false,
          },
        },
      };
    case APP_USER_DEACTIVATION_FAILED:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.appUserId]: {
            ...state.byId[action.payload.appUserId],
            isActive: true,
          },
        },
      };
    case UPDATE_APP_USER_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.appUserId]: action.payload.appUser,
        },
      };
    default:
      return state;
  }
};

const forms = (
  state: IAppUsersFormsState = { loading: false, data: {}, submitting: false },
  action:
    | IFetchAppUsersForms
    | IAppUsersFormsLoaded
    | ILoadAppUserForms
    | ISubmitFormAsAppUser
    | ISubmitFormAsAppUserSuccess
    | ISubmitFormAsAppUserFailed,
) => {
  switch (action.type) {
    case LOAD_APP_USERS_FORMS:
      return {
        ...state,
        loading: true,
      };
    case APP_USERS_FORMS_LOADED:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          [action.payload.appUserUISId]: action.payload.formSubmissions,
        },
      };
    case APP_USER_SUBMIT_FORM:
      return {
        ...state,
        submitting: true,
      };
    case APP_USER_SUBMIT_FORM_SUCCESS:
      return {
        ...state,
        submitting: false,
        data: {
          ...state.data,
          [action.payload.appUserUISId]: action.payload.formSubmissions,
        },
      };
    case APP_USER_SUBMIT_FORM_FAILED:
      return {
        ...state,
        submitting: false,
      };
    default:
      return state;
  }
};

function appUserResultFormsLoading(
  state = false,
  action: IFetchFormsForAppUserResults | ITemporaryFormsLoaded,
) {
  switch (action.type) {
    case 'appUsers/fetch-app-user-forms-for-results':
      return true;
    case 'forms/temporary-loaded':
      return false;
    default:
      return state;
  }
}

export default combineReducers({
  appUserResultFormsLoading,
  creating,
  loading,
  latestSeenUser,
  resendingInvite,
  togglingAccess,
  updating,
  data,
  forms,
});

export const selectAppUsers = (state: IState) => [
  state.appUsers.loading,
  state.appUsers.data.list.map((id: string) => state.appUsers.data.byId[id]),
];

export const selectAppUsersByIds = (appUserIds: string[]) => (state: IState) => [
  state.appUsers.loading,
  state.appUsers.data.list.reduce(
    (prev: IAppUser[], id) =>
      appUserIds.includes(state.appUsers.data.byId[id].ids.ubiquity)
        ? [...prev, state.appUsers.data.byId[id]]
        : prev,
    [],
  ),
];

export const selectAppUsersCreating = (state: IState) => state.appUsers.creating;
export const selectAppUsersLoading = (state: IState) => state.appUsers.loading;
export const selectResendingAppUserInvite = (state: IState) => state.appUsers.resendingInvite;
export const selectAppUsersUpdating = (state: IState) => state.appUsers.updating;
export const selectAppUsersTogglingAccess = (state: IState) => state.appUsers.togglingAccess;

export const selectAppUser = (appUserId: string) => (state: IState): [boolean, IAppUser] => {
  return [state.appUsers.loading, state.appUsers.data.byId[appUserId]];
};

export const selectAppUsersById = (idType: string, appUserIds: string[]) => (
  state: IState,
): [boolean, IAppUser[]] => {
  return [
    state.appUsers.loading,
    appUserIds
      .map(id => Object.values(state.appUsers.data.byId).find(user => user.ids[idType] === id))
      .filter(au => au !== undefined) as IAppUser[],
  ];
};

export const selectAppUserFormSubmitting = (state: IState) => state.appUsers.forms.submitting;

export const selectAppUserFormSubmissions = (appUserUISId: string) => (
  state: IState,
): [boolean, IFormSubmission[]?] => [
  state.appUsers.forms.loading,
  state.appUsers.forms.data[appUserUISId],
];

interface ISubmittedForm extends IForm {
  submission: IFormSubmission;
}

export const selectAppUserForms = (appUserUISId: string, forms: IForm[]) => (
  state: IState,
): [boolean, ISubmittedForm[]] => {
  let submissions = state.appUsers.forms.data[appUserUISId] || [];

  let formSubmissions = submissions.reduce(
    (acc: ISubmittedForm[], sub: IFormSubmission, index: number): ISubmittedForm[] => {
      const form = forms.find(form => `${appToken}-form-${form.uuid}` === sub.formObjectType);
      if (form) {
        return [
          ...acc,
          {
            ...form,
            submission: sub,
          },
        ];
      }

      return acc;
    },
    [],
  ) as ISubmittedForm[];

  formSubmissions.sort((a, b) => b.submission.created.localeCompare(a.submission.created));

  return [state.appUsers.forms.loading, formSubmissions];
};

export const selectAppUserResultFormsLoading = (state: IState) =>
  state.appUsers.appUserResultFormsLoading;

export const selectAppUserSurveySubmissions = (appUserId: string) => (state: IState) =>
  state.surveys.appUserSubmissions[appUserId];
