import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import { useHasPermissions } from '@authorisation/hooks';
import { Permissions } from '@authorisation/constants';
import RLPHospitals from '@rlpHospitals/pages';
// import Hospitals from './Hospitals';
// import HAHospitalDetails from './Hospital';
// import HADataRequestList from './DataRequests';
// import CreateDataRequest from './DataRequests/CreateDataRequest';
// import CreateDeletionRequest from './DataRequests/CreateDeletionRequest';
import DashboardUsers from './DashboardUsers';
import RadioStation from './Stations';
import { matchType } from '../../propTypes';

function Administration({ match }) {
  const permissions = useHasPermissions();

  return (
    <Switch>
      <Route
        exact
        path={match.path}
        render={() => <Redirect to={`${match.path}/dashboard-users`} />}
      />
      {permissions[Permissions.ViewDashboardUsers] && [
        <Route
          path={`${match.path}/dashboard-users`}
          key="dashboard-users"
          component={DashboardUsers}
        />,
      ]}
      {/* {permissions[Permissions.ViewDataRequests] && [
        <Route
          exact
          path={`${match.path}/create-data-request`}
          key="data-request"
          component={CreateDataRequest}
        />,
        <Route
          exact
          path={`${match.path}/create-deletion-request`}
          key="deletion-request"
          component={CreateDeletionRequest}
        />,
        <Route
          path={`${match.path}/data-requests`}
          key="data-requests-list"
          component={HADataRequestList}
        />,
      ]} */}
      {/* {permissions[Permissions.AdministerHospital] && [
        <Route path={`${match.path}/hospital`} key="hospital" component={HAHospitalDetails} />,
      ]} */}

      {/* FPA STYLE HOSPITALS, Not used in RLP */}
      {/* {permissions[Permissions.AdministerAllHospitals] && (
        <Route path={`${match.path}/hospitals`} component={Hospitals} />
      )} */}
      <Route path={`${match.path}/hospitals`} key="hospitals" component={RLPHospitals} />
      <Route path={`${match.path}/radio-station`} key="radio-station" component={RadioStation} />
    </Switch>
  );
}

Administration.propTypes = {
  match: matchType.isRequired,
};

export default Administration;
