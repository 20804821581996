export const TEST = 'Hello';

export const envName = 'prod';

export const domain = 'liquidstate.cloud';
export const deploymentSlug = 'sydney-shared';
export const solutionRolePrefix = 'lp-';

// General App config
export const appToken = '74fc6b';
export const appId = '12';
export const ubiquityCompanyId = 12;
// "dev-forpatientapp-staff" Cognito User Pool
export const userPoolId = 'ap-southeast-2_YUnXGNUjU';
export const userPoolAppClient = '4h7cmlb6np2o7pbtsmocjepbg0';

// IDM
export const idmOrganisation = 13;
export const organisationSlug = 'radio-lollipop';
export const idmApiRoot = `https://idm.${domain}/`;
export const organisationUrl = `${idmApiRoot}api/v1/organisations/${idmOrganisation}/`;
export const idmLogin = `https://idm.${domain}/api/login/`;
export const idmLogout = `https://idm.${domain}/logout/`;
// export const idmTokens = `https://idm.${domain}/api/tokens/`;

// UIS
export const uisApiRoot = `https://uis.${domain}/`;

// PIP
export const pipApiRoot = `https://pip.${domain}`;
export const pipAppUrl = `https://pip.${domain}/api/admin/v1/apps/${appToken}/`;

export const ubiquityApiRoot = `https://ubiquity.${domain}/`;
export const pathwaysApiRoot = `https://pathways.${domain}/v1/apps/{{app_ubiquity_token}}/`;

export const messagingApiRoot = `https://ubiquity.${domain}/api/core/v1/apps/${appId}/`;

export const videoCreationEndpoint = `https://XXX.execute-api.eu-central-1.amazonaws.com/dev/create`;
export const videoExportStatusEndpoint = `https://XXX.execute-api.eu-central-1.amazonaws.com/dev/export_status`;
export const videoCEDetails = {
  ce_space_uuid: 'XXX',
  ce_category_slug: 'dev-videos',
  template_name: 'fpa-video-en',
};
export const videoUbiquityExportDetails = {
  theme_slug: 'default-style',
  ubiquity_app_id: appId,
  ubiquity_company_id: ubiquityCompanyId,
  ubiquity_category_ids: ['XX'],
};

// POST to this backend service with:
// Authorization: identity token JWT
// POST data: JSON object with "file_name" property
export const getUploadViewURL =
  'https://qmtf2cmau7.execute-api.ap-southeast-2.amazonaws.com/prod/get_dashboard_view_link';
