import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Input, Table } from 'antd';
import { useSearch } from '@hooks';
import { fetchRequests, forceFetchRequests, deleteRequest } from '@requests/redux/actions';
import { selectRequestsDeleting, selectRequestsList } from '@requests/redux/reducers';
import { Card } from '@cards/Card';
import { fetchAppUsers } from '@redux/appUsers/actions';
import { fetchRLPHospitals } from '@rlpHospitals/redux/actions';
import { selectHospitals } from '@rlpHospitals/redux/reducers';
import { selectAppUserByPipUuid } from '@surveys/redux/reducers';
import createColumns from './createColumns';
import './style.less';

const filterFunc = (value, comparator) => {
  return comparator(value.name) || comparator(value.description);
};

function ListCard({ showTitle }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [, hospitals] = useSelector(selectHospitals);

  const state = useSelector(state => state);

  const columns = useMemo(() => {
    const selectHospitalIdForRequest = request => {
      const [loading$1, appUser] = selectAppUserByPipUuid(request.pipUuid)(state);
      const hospital = appUser?.hospital;

      if (loading$1 === 'loading') return null;
      return hospital;
    };

    return createColumns(t, hospitals, selectHospitalIdForRequest, (appUserId, created) =>
      dispatch(deleteRequest(appUserId, created)),
    );
  }, [dispatch, hospitals, state, t]);
  const [loading, requests] = useSelector(selectRequestsList);
  const deleting = useSelector(selectRequestsDeleting);

  useEffect(() => {
    dispatch(fetchAppUsers());
    dispatch(fetchRequests());
    dispatch(fetchRLPHospitals());
  }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  const [searchResults, searchProps] = useSearch(requests, filterFunc);

  return (
    <>
      <Card.Full className="form-list-card" noPadding>
        <div className="search-container">
          {showTitle ? <h3>{t('cards:RequestsList.title')}</h3> : null}
          <Input.Search placeholder={t('common:search')} {...searchProps}></Input.Search>
          <div className="card-buttons">
            <Button loading={loading} type="primary" onClick={() => dispatch(forceFetchRequests())}>
              {t('common:buttons.refresh')}
            </Button>
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={searchResults}
          loading={loading || deleting}
          pagination={{ position: 'bottom' }}
          rowKey="created"
          scroll={{ x: 1000 }}
          tableLayout="auto"
        />
      </Card.Full>
    </>
  );
}

export default ListCard;
