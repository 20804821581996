import { IReduxAction } from '../types';
import {
  ACTIVATE_APP_USER,
  APP_ACCESS_CODE_GENERATION_FAILED,
  APP_USER_ACTIVATED,
  APP_USER_ACTIVATION_FAILED,
  APP_USER_DEACTIVATED,
  APP_USER_DEACTIVATION_FAILED,
  APP_USERS_FORMS_LOADED,
  APP_USERS_LOADED,
  DEACTIVATE_APP_USER,
  DELETE_APP_USERS,
  FETCH_APP_USERS,
  FETCH_APP_USERS_FORMS,
  LOAD_APP_USERS_FORMS,
  FETCH_APP_USERS_FAILED,
  INVITE_APP_USER,
  INVITE_APP_USER_FAILED,
  INVITE_APP_USER_SUCCESS,
  LOAD_APP_USERS,
  RESEND_APP_USER_INVITE,
  RESEND_APP_USER_INVITE_FAILED,
  RESEND_APP_USER_INVITE_SUCCESS,
  APP_USER_SUBMIT_FORM,
  IAppUser,
  IFormSubmission,
  APP_USER_SUBMIT_FORM_SUCCESS,
  APP_USER_SUBMIT_FORM_FAILED,
  UPDATE_APP_USER,
  UPDATE_APP_USER_SUCCESS,
  UPDATE_APP_USER_FAILED,
  IUISUserProfile,
} from './types';

export interface IFetchAppUsers extends IReduxAction<typeof FETCH_APP_USERS> {}
export const fetchAppUsers = (): IFetchAppUsers => ({
  type: FETCH_APP_USERS,
  payload: {},
});

export interface ILoadAppUsers extends IReduxAction<typeof LOAD_APP_USERS> {}
export const loadAppUsers = (): ILoadAppUsers => ({
  type: LOAD_APP_USERS,
  payload: {},
});

export interface IFetchAppUsersFailed extends IReduxAction<typeof FETCH_APP_USERS_FAILED> {}
export const fetchAppUsersFailed = (): IFetchAppUsersFailed => ({
  type: FETCH_APP_USERS_FAILED,
  payload: {},
});

export interface IUpdateAppUser
  extends IReduxAction<
    typeof UPDATE_APP_USER,
    { appUserId: string; profile: IUISUserProfile; navigate: boolean }
  > {}
export const updateAppUser = (
  appUserId: string,
  profile: IUISUserProfile,
  navigate = true,
): IUpdateAppUser => ({
  type: UPDATE_APP_USER,
  payload: { appUserId, profile, navigate },
});

export interface IUpdateAppUserSuccess
  extends IReduxAction<typeof UPDATE_APP_USER_SUCCESS, { appUserId: string; appUser: IAppUser }> {}
export const updateAppUserSuccess = (
  appUserId: string,
  appUser: IAppUser,
): IUpdateAppUserSuccess => ({
  type: UPDATE_APP_USER_SUCCESS,
  payload: { appUserId, appUser },
});

export interface IUpdateAppUserFailed extends IReduxAction<typeof UPDATE_APP_USER_FAILED, {}> {}
export const updateAppUserFailed = (): IUpdateAppUserFailed => ({
  type: UPDATE_APP_USER_FAILED,
  payload: {},
});

export interface IDeleteAppUsers
  extends IReduxAction<typeof DELETE_APP_USERS, { appUserIds: string[] }> {}
export const deleteAppUsers = (appUserIds: string[]): IDeleteAppUsers => ({
  type: DELETE_APP_USERS,
  payload: { appUserIds },
});

export interface IAppUsersLoaded
  extends IReduxAction<
    typeof APP_USERS_LOADED,
    { appUsers: IAppUser[]; latestSeenUser?: string }
  > {}
export const appUsersLoaded = (appUsers: IAppUser[], latestSeenUser?: string): IAppUsersLoaded => ({
  type: APP_USERS_LOADED,
  payload: {
    appUsers,
    latestSeenUser,
  },
});

export interface IFetchAppUsersForms
  extends IReduxAction<typeof FETCH_APP_USERS_FORMS, { appUserUISId: string }> {}
export const fetchAppUsersForms = (appUserUISId: string): IFetchAppUsersForms => ({
  type: FETCH_APP_USERS_FORMS,
  payload: { appUserUISId },
});

export interface ILoadAppUserForms extends IReduxAction<typeof LOAD_APP_USERS_FORMS> {}
export const loadAppUserForms = (): ILoadAppUserForms => ({
  type: LOAD_APP_USERS_FORMS,
  payload: {},
});

export interface IAppUsersFormsLoaded
  extends IReduxAction<
    typeof APP_USERS_FORMS_LOADED,
    { appUserUISId: string; formSubmissions: IFormSubmission[] }
  > {}
export const appUsersFormsLoaded = (
  appUserUISId: string,
  formSubmissions: IFormSubmission[],
): IAppUsersFormsLoaded => ({
  type: APP_USERS_FORMS_LOADED,
  payload: { appUserUISId, formSubmissions },
});

export interface IInviteAppUser
  extends IReduxAction<typeof INVITE_APP_USER, { appUser: IAppUser; journeys: any }> {}
export const inviteAppUser = (appUser: IAppUser, journeys: any): IInviteAppUser => ({
  type: INVITE_APP_USER,
  payload: {
    appUser,
    journeys,
  },
});

export interface IInviteAppUserSuccess
  extends IReduxAction<
    typeof INVITE_APP_USER_SUCCESS,
    { id: string; appUser: IAppUser; journeys: any }
  > {}
export const inviteAppUserSuccess = (
  id: string,
  appUser: IAppUser,
  journeys: any,
): IInviteAppUserSuccess => ({
  type: INVITE_APP_USER_SUCCESS,
  payload: {
    id,
    appUser,
    journeys,
  },
});

export interface IInviteAppUserFailed extends IReduxAction<typeof INVITE_APP_USER_FAILED, {}> {}
export const inviteAppUserFailed = (): IInviteAppUserFailed => ({
  type: INVITE_APP_USER_FAILED,
  payload: {},
});

export interface IActivateAppUser
  extends IReduxAction<typeof ACTIVATE_APP_USER, { appUserId: string }> {}
export const activateAppUser = (appUserId: string): IActivateAppUser => ({
  type: ACTIVATE_APP_USER,
  payload: { appUserId },
});

export interface IAppUserActivated
  extends IReduxAction<typeof APP_USER_ACTIVATED, { appUserId: string }> {}
export const appUserActivated = (appUserId: string): IAppUserActivated => ({
  type: APP_USER_ACTIVATED,
  payload: { appUserId },
});

export interface IAppUserActivationFailed
  extends IReduxAction<typeof APP_USER_ACTIVATION_FAILED, { appUserId: string }> {}
export const appUserActivationFailed = (appUserId: string): IAppUserActivationFailed => ({
  type: APP_USER_ACTIVATION_FAILED,
  payload: { appUserId },
});

export interface IDeactivateAppUser
  extends IReduxAction<typeof DEACTIVATE_APP_USER, { appUserId: string }> {}
export const deactivateAppUser = (appUserId: string): IDeactivateAppUser => ({
  type: DEACTIVATE_APP_USER,
  payload: { appUserId },
});

export interface IAppUserDeactivated
  extends IReduxAction<typeof APP_USER_DEACTIVATED, { appUserId: string }> {}
export const appUserDeactivated = (appUserId: string): IAppUserDeactivated => ({
  type: APP_USER_DEACTIVATED,
  payload: { appUserId },
});

export interface IAppUserDeactivationFailed
  extends IReduxAction<typeof APP_USER_DEACTIVATION_FAILED, { appUserId: string }> {}
export const appUserDeactivationFailed = (appUserId: string): IAppUserDeactivationFailed => ({
  type: APP_USER_DEACTIVATION_FAILED,
  payload: { appUserId },
});

export interface IAppAccessCodeGenerationFailed
  extends IReduxAction<typeof APP_ACCESS_CODE_GENERATION_FAILED> {}
export const appAccessCodeGenerationFailed = (): IAppAccessCodeGenerationFailed => ({
  type: APP_ACCESS_CODE_GENERATION_FAILED,
  payload: {},
});

export interface IResendAppUserInvite
  extends IReduxAction<typeof RESEND_APP_USER_INVITE, { appUserId: string }> {}
export const resendAppUserInvite = (appUserId: string): IResendAppUserInvite => ({
  type: RESEND_APP_USER_INVITE,
  payload: { appUserId },
});

export interface IResendAppUserInviteFailed
  extends IReduxAction<typeof RESEND_APP_USER_INVITE_FAILED, {}> {}
export const resendAppUserInviteFailed = (): IResendAppUserInviteFailed => ({
  type: RESEND_APP_USER_INVITE_FAILED,
  payload: {},
});

export interface IResendAppUserInviteSuccess
  extends IReduxAction<typeof RESEND_APP_USER_INVITE_SUCCESS, {}> {}
export const resendAppUserInviteSuccess = (): IResendAppUserInviteSuccess => ({
  type: RESEND_APP_USER_INVITE_SUCCESS,
  payload: {},
});

export interface ISubmitFormAsAppUser
  extends IReduxAction<
    typeof APP_USER_SUBMIT_FORM,
    { formId: string; formData: object; appUserUISId: string; type: string }
  > {}
export const submitFormAsAppUser = (
  formId: string,
  formData: object,
  appUserUISId: string,
  type: string,
): ISubmitFormAsAppUser => ({
  type: APP_USER_SUBMIT_FORM,
  payload: { formId, formData, appUserUISId, type },
});

export interface ISubmitFormAsAppUserSuccess
  extends IReduxAction<
    typeof APP_USER_SUBMIT_FORM_SUCCESS,
    { appUserUISId: string; formSubmissions: IFormSubmission[] }
  > {}
export const submitFormAsAppUserSuccess = (
  appUserUISId: string,
  formSubmissions: IFormSubmission[],
): ISubmitFormAsAppUserSuccess => ({
  type: APP_USER_SUBMIT_FORM_SUCCESS,
  payload: { appUserUISId, formSubmissions },
});

export interface ISubmitFormAsAppUserFailed
  extends IReduxAction<typeof APP_USER_SUBMIT_FORM_FAILED> {}
export const submitFormAsAppUserFailed = (): ISubmitFormAsAppUserFailed => ({
  type: APP_USER_SUBMIT_FORM_FAILED,
  payload: {},
});

export type IFetchFormsForAppUserResults = ReturnType<typeof fetchFormsForAppUserResults>;
export const fetchFormsForAppUserResults = (formSubmissions: { formObjectType: string }[]) => ({
  type: 'appUsers/fetch-app-user-forms-for-results' as const,
  payload: { formSubmissions },
});

export type IClearFormsForAppUserResults = ReturnType<typeof clearFormsForAppUserResults>;
export const clearFormsForAppUserResults = (formIdList: string[]) => ({
  type: 'appUsers/clear-app-user-forms-for-results' as const,
  payload: { formIdList },
});
