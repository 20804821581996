import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input } from 'antd';
import { Card } from '@cards/Card';
import useWizard from '@components/Wizard/useWizard';
import { ColumnGroup, Column } from '@cards/Card';
import './style.less';

function StationStreams() {
  const { t } = useTranslation();
  const { currentStepData, form } = useWizard();

  return (
    <ColumnGroup>
      <Column>
        <Card className="station-wizard-card" title={t('stations:Wizard.streams.titles.stream1')}>
          <Form labelCol={{ span: 8 }} layout="vertical" wrapperCol={{ span: 16 }}>
            <Form.Item label={t('stations:Wizard.streams.labels.name')}>
              {form.getFieldDecorator('stream1Title', {
                initialValue: currentStepData.stream1Title,
                rules: [{ required: true, message: t('stations:Wizard.streams.errors.name') }],
              })(<Input placeholder={t('stations:Wizard.streams.placeholders.name')} disabled />)}
            </Form.Item>
            <Form.Item label={t('stations:Wizard.streams.labels.stream')}>
              {form.getFieldDecorator('stream1Url', {
                initialValue: currentStepData.stream1Url,
                rules: [{ required: true, message: t('stations:Wizard.streams.errors.name') }],
              })(<Input placeholder={t('stations:Wizard.streams.placeholders.name')} />)}
            </Form.Item>
          </Form>
        </Card>
      </Column>
      <Column>
        <Card className="station-wizard-card" title={t('stations:Wizard.streams.titles.stream2')}>
          <Form labelCol={{ span: 8 }} layout="vertical" wrapperCol={{ span: 16 }}>
            <Form.Item label={t('stations:Wizard.streams.labels.name')}>
              {form.getFieldDecorator('stream2Title', {
                initialValue: currentStepData.stream2Title,
                rules: [{ required: true, message: t('stations:Wizard.streams.errors.name') }],
              })(<Input placeholder={t('stations:Wizard.streams.placeholders.name')} disabled />)}
            </Form.Item>
            <Form.Item label={t('stations:Wizard.streams.labels.stream')}>
              {form.getFieldDecorator('stream2Url', {
                initialValue: currentStepData.stream2Url,
                rules: [{ required: true, message: t('stations:Wizard.streams.errors.name') }],
              })(<Input placeholder={t('stations:Wizard.streams.placeholders.name')} />)}
            </Form.Item>
          </Form>
        </Card>
      </Column>
    </ColumnGroup>
  );
}

StationStreams.propTypes = {};

StationStreams.defaultProps = {};

export default StationStreams;
