import { IReduxAction } from '@redux/types';
import {
  IStation,
  UPDATE_STATION,
  UPDATE_STATION_SUCCESS,
  UPDATE_STATION_FAILED,
  FETCH_RADIO_STATIONS,
  LOAD_RADIO_STATIONS,
  LOAD_RADIO_STATIONS_SUCCESS,
  LOAD_RADIO_STATIONS_FAILED,
} from './types';

export type IFetchRadioStations = IReduxAction<typeof FETCH_RADIO_STATIONS, {}>;
export const fetchStations = (): IFetchRadioStations => ({
  type: FETCH_RADIO_STATIONS,
  payload: {},
});

export type ILoadRadioStations = IReduxAction<typeof LOAD_RADIO_STATIONS, {}>;
export const loadStations = (): ILoadRadioStations => ({
  type: LOAD_RADIO_STATIONS,
  payload: {},
});

export type ILoadRadioStationsSuccess = IReduxAction<
  typeof LOAD_RADIO_STATIONS_SUCCESS,
  { stations: IStation[] }
>;
export const loadStationsSuccess = (stations: IStation[]): ILoadRadioStationsSuccess => ({
  type: LOAD_RADIO_STATIONS_SUCCESS,
  payload: { stations },
});

export type ILoadRadioStationsFailed = IReduxAction<typeof LOAD_RADIO_STATIONS_FAILED, {}>;
export const loadStationsFailed = (): ILoadRadioStationsFailed => ({
  type: LOAD_RADIO_STATIONS_FAILED,
  payload: {},
});

export type IUpdateStation = IReduxAction<typeof UPDATE_STATION, { station: IStation }>;
export const updateStation = (station: IStation): IUpdateStation => ({
  type: UPDATE_STATION,
  payload: { station },
});

export type IUpdateStationsSuccess = IReduxAction<
  typeof UPDATE_STATION_SUCCESS,
  { stations: IStation[] }
>;
export const updateStationSuccess = (stations: IStation[]): IUpdateStationsSuccess => ({
  type: UPDATE_STATION_SUCCESS,
  payload: { stations },
});

export type IUpdateStationsFailed = IReduxAction<typeof UPDATE_STATION_FAILED, {}>;
export const updateStationFailed = (): IUpdateStationsFailed => ({
  type: UPDATE_STATION_FAILED,
  payload: {},
});
