import React, { useCallback } from 'react';
// import { Menu, Dropdown } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons';
import HeaderButton from '@components/HeaderButton';
// import { getContentTypeValues, messageTypes } from '@utils';
import './style.less';

const BASE_PATH = '/messages/new';

const NewMessageDropdown = ({ ...props }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const onMenuClick = useCallback(
    ({ key }) => {
      history.push({ pathname: BASE_PATH, search: key ? `type=${key}` : '' });
    },
    [history],
  );

  return (
    <HeaderButton
      className="new-message-dropdown"
      icon={<FontAwesomeIcon icon={faAngleDown} />}
      type="primary"
      onClick={onMenuClick}
      {...props}
    >
      {t('common:buttons.send')}
    </HeaderButton>
  );

  // return (
  //   <Dropdown.Button
  //     className="new-message-dropdown"
  //     icon={<FontAwesomeIcon icon={faAngleDown} />}
  //     overlay={
  //       <Menu onClick={onMenuClick}>
  //         {messageTypes.map(type => {
  //           const { label, icon } = getContentTypeValues(type);
  //           return (
  //             <Menu.Item key={type} className="new-content-menu-item">
  //               <div className="menu-item-icon">
  //                 <FontAwesomeIcon icon={icon} />
  //               </div>
  //               <span className="menu-item-label">{t(label)}</span>
  //             </Menu.Item>
  //           );
  //         })}
  //       </Menu>
  //     }
  //     type="primary"
  //     onClick={onMenuClick}
  //     {...props}
  //   >
  //     {t('common:buttons.send')}
  //   </Dropdown.Button>
  // );
};

export default NewMessageDropdown;
