import { call, getContext, put, takeEvery } from 'redux-saga/effects';
import { message } from 'antd';
import createPipClient from '@api/pipClient';
import takeFirst from '@redux/takeFirst';
import doGetAppUsersList from '@utils/doGetAppUsersList';
import { appToken } from 'settings';
import { doFetchAppUsers } from '@redux/appUsers/sagas/fetch';
import {
  loadRequests,
  requestsLoaded,
  loadRequestsFailed,
  deleteAllRequestsFailed,
  deleteAllRequestsSuccess,
  IDeleteRequest,
  deleteRequestSuccess,
  deleteRequestFailed,
} from './actions';
import { getBaseObjectTypeForStudioContact } from './utils';
import i18n from '../../i18n';
import { IRawRequest } from './types';

export default function* root() {
  yield takeFirst('requests/fetch', doFetchRequests);
  yield takeEvery('requests/force-fetch', doFetchRequests);
  yield takeEvery('requests/delete-all', doDeleteAllRequests);
  yield takeEvery('requests/delete', doDeleteRequest);
}

function* doFetchRequests(): any {
  try {
    yield put(loadRequests());
    yield doFetchAppUsers();
    const tokens = yield getContext('tokens');
    const client = yield call(createPipClient, tokens);
    const objectType = yield call(getBaseObjectTypeForStudioContact);
    const latestObjectsForAppUsers = yield call(client.getLatestObjectsForUsers, objectType);

    const requests = latestObjectsForAppUsers
      .flatMap((appUserRequests: { app_user: string; json: IRawRequest[] }) => {
        const extractedAppUserPipId = /[A-Za-z0-9]{8}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{12}/.exec(
          appUserRequests.app_user,
        );

        if (extractedAppUserPipId) {
          const pipUuid = extractedAppUserPipId[0];
          return appUserRequests.json.map(request => ({ ...request, pipUuid }));
        }
      })
      .filter((request: object | undefined) => request !== undefined);

    yield put(requestsLoaded(requests));
  } catch (err) {
    console.error(err);
    message.error(i18n.t('requests:List.failed'));
    yield put(loadRequestsFailed());
  }
}

function* doDeleteAllRequests(): any {
  try {
    const tokens = yield getContext('tokens');
    const client = yield call(createPipClient, tokens);
    const objectType = yield call(getBaseObjectTypeForStudioContact);
    const appUsers = yield call(doGetAppUsersList);

    for (const appUser of appUsers) {
      let appUserResponse: { results: { uuid: string }[] } = yield call(
        client.getAppUser,
        appUser.ids.pip,
      );
      let pipAppUser = appUserResponse.results[0];

      if (appUserResponse.results.length === 0) {
        console.info('User could not be found in PIP, attempting to create it');
        try {
          const appDetails = yield call(client.getApp, appToken);
          pipAppUser = yield call(
            client.createAppUser,
            appDetails.uuid,
            appUser.ids.pip,
            'app-user',
          );
          if (!pipAppUser) throw Error('Empty response');
        } catch {
          console.error('Failed to create User in PIP.');
          return;
        }
      }

      yield call(client.createObject, objectType, [], pipAppUser.uuid);
    }

    yield put(deleteAllRequestsSuccess());
  } catch (err) {
    console.error(err);
    yield put(deleteAllRequestsFailed());
  }
}

function* doDeleteRequest({ payload: { pipAppUserId, created } }: IDeleteRequest): any {
  try {
    const tokens = yield getContext('tokens');
    const client = yield call(createPipClient, tokens);
    const objectType = yield call(getBaseObjectTypeForStudioContact);

    const [latest] = yield call(client.getObjectsForType, objectType, 'latest', pipAppUserId);

    yield call(
      client.createObject,
      objectType,
      latest.json.filter((request: IRawRequest) => request.created !== created),
      pipAppUserId,
    );

    yield put(deleteRequestSuccess(pipAppUserId, created));
  } catch (err) {
    console.error(err);
    yield put(deleteRequestFailed());
  }
}
