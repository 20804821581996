import React from 'react';
import { useTranslation } from 'react-i18next';
import { Column, ColumnGroup } from '@cards/Card';
import HeaderButton from '@components/HeaderButton';
import { usePageActions } from '@hooks';
import ListCard from '../cards/List';

export default ({ match, history }) => {
  const { t } = useTranslation();
  usePageActions({
    title: t('rlp-hospitals:list.title'),
    actions: [
      <HeaderButton
        tooltip={t('rlp-hospitals:list:new')}
        onClick={() => history.push(`${match.path}/new`)}
      >
        {t('rlp-hospitals:list:new')}
      </HeaderButton>,
    ],
  });

  return (
    <ColumnGroup>
      <Column>
        <ListCard match={match} />
      </Column>
    </ColumnGroup>
  );
};
