import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input, Select } from 'antd';
import { Card } from '@cards/Card';
import useWizard from '@components/Wizard/useWizard';
import SelectContentModal from '@components/SelectContentModal';
import { NOTIFICATION_ONLY, OPEN_CONTENT, OPEN_LINK } from '@constants';
import ContentInformation from '@cards/Messages/ContentInformation';
import { documentTypes, formTypes } from '@utils/contentTypes';

const contentTypes = [...documentTypes, ...formTypes];

function DetailsCard() {
  const { currentStepData, form, setCurrentStepData } = useWizard();
  const [isSelectContentVisible, setSelectContentVisible] = useState(false);
  const { t } = useTranslation();

  const onCancelSelectContent = useCallback(() => {
    form.setFieldsValue({ action: NOTIFICATION_ONLY });
    setSelectContentVisible(false);
    const { contentTitle, contentType, ...rest } = currentStepData;
    setCurrentStepData(rest);
  }, [currentStepData, form, setCurrentStepData]);

  const onSubmitSelectContent = useCallback(
    (_, [content]) => {
      setCurrentStepData({
        ...currentStepData,
        contentTitle: content.title || content.name,
        contentType: content.metadata.type,
        contentData: content,
      });
      setSelectContentVisible(false);
    },
    [currentStepData, setCurrentStepData],
  );

  const onChangeContent = useCallback(() => {
    setSelectContentVisible(true);
  }, []);

  const action = form.getFieldValue('action') || null;

  return (
    <>
      <Card title={t('messages:Wizard.details.stepName')}>
        <Form labelCol={{ span: 8 }} layout="vertical" wrapperCol={{ span: 16 }}>
          <Form.Item label={t('messages:Wizard.details.title.label')}>
            {form.getFieldDecorator('title', {
              initialValue: currentStepData.title,
              rules: [{ required: true, message: t('messages:Wizard.details.title.required') }],
            })(<Input placeholder={t('messages:Wizard.details.title.label')} />)}
          </Form.Item>
          <Form.Item label={t('messages:Wizard.details.body.label')}>
            {form.getFieldDecorator('content', {
              initialValue: currentStepData.content,
              rules: [{ required: true, message: t('messages:Wizard.details.body.required') }],
            })(<Input placeholder={t('messages:Wizard.details.body.label')} />)}
          </Form.Item>
          <Form.Item label={t('messages:Wizard.details.actions.label')}>
            {form.getFieldDecorator('action', {
              initialValue: currentStepData.action || NOTIFICATION_ONLY,
              rules: [{ required: true, message: t('messages:Wizard.details.actions.required') }],
            })(
              <Select
                placeholder={t('messages:Wizard.details.actions.placeholder')}
                onChange={value => {
                  if (value === OPEN_CONTENT && !currentStepData.contentType)
                    setSelectContentVisible(true);
                }}
              >
                <Select.Option value={NOTIFICATION_ONLY}>
                  {t('messages:Wizard.details.actions.notification')}
                </Select.Option>
              </Select>,
            )}
          </Form.Item>
          {action === OPEN_LINK ? (
            <Form.Item label={t('messages:Wizard.details.websiteLink.label')}>
              {form.getFieldDecorator('websiteLink', {
                initialValue: currentStepData.websiteLink,
                rules: [
                  { required: true, message: t('messages:Wizard.details.websiteLink.required') },
                  {
                    pattern: /(http:\/\/|https:\/\/).*/,
                    message: t('messages:Wizard.details.websiteLink.pattern'),
                  },
                ],
              })(<Input />)}
            </Form.Item>
          ) : null}
          {action === OPEN_CONTENT && currentStepData.contentType ? (
            <ContentInformation
              title={currentStepData.contentTitle}
              type={currentStepData.contentType.toUpperCase()}
              onChangeContent={onChangeContent}
            />
          ) : null}
        </Form>
      </Card>
      {isSelectContentVisible ? (
        <SelectContentModal
          contentTypes={contentTypes}
          defaultFilteredValue={{
            language: form.getFieldValue('language') ? [form.getFieldValue('language')] : [],
          }}
          visible={isSelectContentVisible}
          onCancel={onCancelSelectContent}
          onSubmit={onSubmitSelectContent}
        />
      ) : null}
    </>
  );
}

export default DetailsCard;
