import { put, takeLatest, select, getContext, call } from 'redux-saga/effects';
import { message } from 'antd';
import { appToken } from 'settings';
import { FETCH_RADIO_STATIONS, UPDATE_STATION, IStation } from './types';
import {
  loadStations,
  loadStationsSuccess,
  loadStationsFailed,
  IUpdateStation,
  updateStationSuccess,
  updateStationFailed,
} from './actions';
import { selectRadioStations } from './reducers';
import takeFirst from '../takeFirst';
import pipClient from '@api/pipClient';

export default function* root() {
  yield takeFirst(FETCH_RADIO_STATIONS, doLoadRadioStations);
  yield takeLatest(UPDATE_STATION, doUpdateRadioStation);
}

export const RADIO_STATIONS_TYPE = `${appToken}-radio-stations`;

function* doLoadRadioStations() {
  try {
    yield put(loadStations());
    const tokens = yield getContext('tokens');
    const pip = yield call(pipClient, tokens);

    const result = (yield call(pip.getLatestObjectsForUsers, RADIO_STATIONS_TYPE))[0];
    yield put(loadStationsSuccess(result.json));
  } catch (err) {
    console.error(err);
    yield put(loadStationsFailed());
  }
}

function* doUpdateRadioStation({ payload: { station } }: IUpdateStation) {
  try {
    const history = yield getContext('history');
    const [, stations] = yield select(selectRadioStations);

    const updatedStations = stations.map((st: IStation) => (st.id === station.id ? station : st));

    const tokens = yield getContext('tokens');
    const pip = yield call(pipClient, tokens);

    yield call(pip.createObject, RADIO_STATIONS_TYPE, updatedStations);

    yield put(updateStationSuccess(updatedStations));
    yield call(history.goBack);
    yield call(message.success, 'Success! Your Radio Station has been updated.');
  } catch (err) {
    console.error(err);
    yield put(updateStationFailed());
    yield call(message.error, 'Something went wrong! We failed to update your Radio Station.');
  }
}
