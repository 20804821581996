import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input } from 'antd';
import { Card } from '@cards/Card';
import useWizard from '@components/Wizard/useWizard';

function HospitalDetails() {
  const { t } = useTranslation();
  const { currentStepData, form } = useWizard();

  return (
    <Card className="station-wizard-card" title={t('stations:Wizard.details.titles.details')}>
      <Form labelCol={{ span: 8 }} layout="vertical" wrapperCol={{ span: 16 }}>
        <Form.Item label={t('stations:Wizard.details.labels.name')}>
          {form.getFieldDecorator('name', {
            initialValue: currentStepData.name,
            rules: [{ required: true, message: t('stations:Wizard.details.errors.name') }],
          })(<Input placeholder={t('stations:Wizard.details.placeholders.name')} />)}
        </Form.Item>
        <Form.Item label={t('stations:Wizard.details.labels.address')}>
          {form.getFieldDecorator('address', {
            initialValue: currentStepData.address,
            rules: [{ required: true, message: t('stations:Wizard.details.errors.address') }],
          })(<Input placeholder={t('stations:Wizard.details.placeholders.address')} />)}
        </Form.Item>
      </Form>
    </Card>
  );
}

HospitalDetails.propTypes = {};

HospitalDetails.defaultProps = {};

export default HospitalDetails;
