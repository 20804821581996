import React, { forwardRef } from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectLanguages } from '@redux/core/reducers';
import './style.less';

const { Option } = Select;

// for use with getFieldDecorator
function LangaugeSelect({ disabled, value, onChange }, ref) {
  const { t } = useTranslation();
  const languages = useSelector(selectLanguages);

  return (
    <Select
      className="language-select-option"
      disabled={disabled}
      placeholder={t('common:LanguageSelect.placeholder')}
      ref={ref}
      value={value}
      onChange={onChange}
    >
      {Object.values(languages).map(({ icon: Icon, key, name }) => (
        <Option className="language-select-option" key={key} value={key}>
          <Icon /> {t(name)}
        </Option>
      ))}
    </Select>
  );
}

export default forwardRef(LangaugeSelect);
