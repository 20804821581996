import React from 'react';
import Menu from './Menu';
import Header from './Header';

import { PageActionsProvider } from '../../hooks';

import './style.less';

export default ({ children, title, showClose }) => {
  return (
    <div className="container">
      <Menu />
      <div className="content-page">
        <PageActionsProvider>
          <Header title={title} showClose={showClose} />
          <div className="content">{children}</div>
        </PageActionsProvider>
      </div>
    </div>
  );
};
