import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Card, Button, Input, Form, Icon, Alert } from 'antd';
import OnboardingContainer from '@components/OnboardingContainer';
import { isLoading, hasError as error } from '@redux/login/reducer';
import { loginSubmitted } from '@redux/login/actions';
import './style.less';

const Password = Input.Password;

const Login = ({
  form: { getFieldDecorator, validateFieldsAndScroll, getFieldError, getFieldValue },
}) => {
  const { t } = useTranslation();
  const hasError = useSelector(error);
  const loading = useSelector(isLoading);
  const dispatch = useDispatch();
  let history = useHistory();

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();
      validateFieldsAndScroll((err, values) => {
        if (!err) {
          dispatch(loginSubmitted(values));
        }
      });
    },
    [dispatch, validateFieldsAndScroll],
  );

  const disabledSubmit =
    typeof getFieldError('email') !== 'undefined' ||
    !getFieldValue('email') ||
    typeof getFieldError('password') !== 'undefined' ||
    !getFieldValue('password');
  return (
    <OnboardingContainer>
      <Form layout="vertical" onSubmit={handleSubmit}>
        <Card
          title={<h1>{t('login:title')}</h1>}
          actions={[
            <div className="custom-alignment">
              <Button onClick={() => history.push('/auth/registration')}>
                {t('login:Main.registration')}
              </Button>
              <Button
                type="primary"
                onClick={handleSubmit}
                htmlType="submit"
                disabled={disabledSubmit}
                loading={loading}
              >
                {t('common:buttons.login')}
              </Button>
            </div>,
          ]}
          className="login-card three-btn"
        >
          {hasError && (
            <Alert
              message={t('login:Main.alert.message')}
              description={t('login:Main.alert.description')}
              type="error"
              showIcon
            />
          )}

          <Form.Item hasFeedback label={t('common:email')} required={false}>
            {getFieldDecorator('email', {
              rules: [
                {
                  required: true,
                  message: t('common:validation.Email.emptyError'),
                },
                {
                  type: 'email',
                  message: t('common:validation.Email.invalidError'),
                },
              ],
            })(
              <Input
                type="email"
                placeholder={t('common:placeholder.email')}
                prefix={<Icon type="mail" />}
              />,
            )}
          </Form.Item>
          <Button type="link" className="lost-pwd" onClick={() => history.push('/auth/recovery')}>
            {t('login:Main.lostPassword')}
          </Button>
          <Form.Item hasFeedback label={t('common:password')} required={false}>
            {getFieldDecorator('password', {
              rules: [
                {
                  required: true,
                  message: t('common:validation.Password.emptyError'),
                },
              ],
            })(
              <Password
                placeholder={t('common:placeholder.password')}
                prefix={<Icon type="lock" />}
              />,
            )}
          </Form.Item>
        </Card>
      </Form>
    </OnboardingContainer>
  );
};

Login.propTypes = {
  form: PropTypes.object.isRequired,
};

const WrappedLogin = Form.create()(Login);

export { WrappedLogin };
export default WrappedLogin;
